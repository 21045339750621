import { Box, Grid, TextField, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { IDetailFaktur } from "../../constants/types";
import toRupiah from "../../utils/toRupiah";
import { useTheme } from "@mui/material/styles";

interface IDetailFakturProps {
    id: number;
    nama: string;
    idx: number;
    stok: number;
    hargaBeli: number;
}

const DetailFaktur = ({
    id,
    nama,
    idx,
    stok,
    hargaBeli,
}: IDetailFakturProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const { control } = useFormContext<IDetailFaktur>();

    return (
        <Box
            display="flex"
            flexDirection={isPhoneScreen ? "column" : "row"}
            justifyContent={isPhoneScreen ? undefined : "space-between"}
            alignItems={isPhoneScreen ? undefined : "flex-start"}
            width={isPhoneScreen ? undefined : "80%"}
            key={String(id)}
        >
            <Grid container alignItems="center">
                <Grid item xs={12}>
                    <Typography fontWeight={600}>{`${
                        idx + 1
                    }. ${nama} `}</Typography>
                    <Typography variant="body2">{`Harga beli: ${toRupiah(
                        hargaBeli,
                    )}`}</Typography>
                    <Typography>
                        Jumlah stok pada faktur: <b>{stok} buah</b>
                    </Typography>
                </Grid>
            </Grid>
            <Controller
                name={`detail.${idx}.jumlahStok`}
                control={control}
                rules={{
                    required: "Kolom wajib diisi",
                    min: {
                        value: 1,
                        message: "Minimal stok 1 buah", // JS only: <p>error message</p> TS only support string
                    },
                    max: {
                        value: stok,
                        message: `Stok maksimal`, // JS only: <p>error message</p> TS only support string
                    },
                }}
                render={({ field, fieldState }) => (
                    <TextField
                        type="number"
                        variant="outlined"
                        placeholder="Jumlah Stok"
                        size="small"
                        id={`detail.${idx}.jumlahStok`}
                        onWheel={(e) => {
                            const target =
                                e.target as HTMLButtonElement;
                            if (target) {
                                target.blur();
                                e.stopPropagation();
                                setTimeout(
                                    () => {
                                        target.focus();
                                    },
                                    0,
                                );
                            }
                        }}
                        onKeyDown={(evt) =>
                            [
                                "e",
                                "E",
                                "+",
                                "-",
                            ].includes(
                                evt.key,
                            ) &&
                            evt.preventDefault()
                        }
                        error={!!fieldState?.error}
                        helperText={
                            fieldState?.error && fieldState?.error.message
                        }
                        {...field}
                        // onChange={(e) =>
                        //     field.onChange(
                        //         Number(e.target.value),
                        //     )
                        // }
                    />
                )}
            />
        </Box>
    );
};

export default DetailFaktur;
