import {
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem,
    TextField,
    SelectChangeEvent,
    Grid,
    Autocomplete,
    InputAdornment,
    Button,
    Stack,
} from "@mui/material";
import {
    Control,
    Controller,
    useFormContext,
    UseFormRegister,
    UseFormSetValue,
    useWatch,
    useFieldArray,
} from "react-hook-form";
import {
    ContentUkm,
    IOptions,
    MutasiValues,
    ParamsGetItem,
} from "../../../constants/types";
import NumberFormat from "react-number-format";
import useItem from "../../../services/queries/useItem";
import { useAuth } from "../../../context/authContext";
import useUkm from "../../../services/queries/useUkm";
import { useMemo, useState } from "react";
import { Add, Delete } from "@mui/icons-material";
import toRupiah from "../../../utils/toRupiah";
import { textPrimary } from "../../../constants/colors";

interface IBarangTerpilihProps {
    id: number;
    nama: string;
    sku: string;
    idx: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    totalStok: number;
}

const BarangTerpilih = ({
    id,
    nama,
    sku,
    idx,
    hpp,
    totalStok,
}: IBarangTerpilihProps) => {
    const { ukmIdUser } = useAuth();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const ukmData = useMemo(() => {
        if (ukmIdUser) {
            return ukm?.content.find((li) => li.id === ukmIdUser[0]);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmIdUser]);

    const { control, register, setValue } = useFormContext<MutasiValues>();
    const { fields, append, remove } = useFieldArray({
        control,
        name: `detail.${idx}.nestedDetail`,
    });

    const addDetail = () => {
        const newDetail = {
            itemId: id,
            jumlahStok: "",
            keterangan: "",
            isMasuk: "masuk",
            faktur: undefined,
            stokRepack: "",
            itemRepack: null,
            hargaBeliRepack: null,
        };
        append(newDetail);
    };

    return (
        <Box alignItems="end" key={String(id)}>
            <Typography color={textPrimary.title} fontWeight="bold">{`${
                idx + 1
            }. ${nama} (${sku})`}</Typography>
            <Typography color={textPrimary.body} mb={1}>
                Jumlah stok saat ini: auu{" "}
                <b color={textPrimary.title}>{totalStok} buah</b>
            </Typography>
            {fields.map((field, indexNested) => (
                <DetailComponent
                    key={field.id}
                    control={control}
                    indexDetail={idx}
                    indexNested={indexNested}
                    remove={remove}
                    hpp={hpp}
                    id={id}
                    register={register}
                    setValue={setValue}
                    ukmData={ukmData}
                    addDetail={addDetail}
                />
            ))}
            <Button
                disabled={fields.length === hpp.length}
                variant="contained"
                onClick={() => addDetail()}
                size="small"
                color="buttongreen"
                startIcon={<Add />}
                sx={{ mt: 2 }}
            >
                Tambah Faktur
            </Button>
        </Box>
    );
};

interface IDetailComponent {
    ukmData?: ContentUkm;
    indexDetail: number;
    indexNested: number;
    control: Control<MutasiValues, object>;
    id: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    setValue: UseFormSetValue<MutasiValues>;
    register: UseFormRegister<MutasiValues>;
    addDetail: () => void;
    remove: (index?: number | number[] | undefined) => void;
}

const DetailComponent = (props: IDetailComponent) => {
    const watchIsMasuk = useWatch({
        control: props.control,
        name: `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.isMasuk`,
    });
    const faktur = useWatch({
        control: props.control,
        name: `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.faktur`,
    });
    const stokRepack = useWatch({
        control: props.control,
        name: `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.stokRepack`,
    });
    const jumlahStok = useWatch({
        control: props.control,
        name: `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.jumlahStok`,
    });

    const { ukmIdUser } = useAuth();
    const initialParamsStok: ParamsGetItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        isShow: 0,
        kategori: "",
        size: 10,
        page: 1,
    };
    const [paramsStok, setParamsStok] = useState(initialParamsStok);
    const { data: stok, isLoading: isLoadingStok } = useItem(paramsStok);
    const mappedStok = stok?.content
        .filter((st) => st.detailBundling.length === 0)
        .map((li) => ({
            id: li.id,
            nama: li.namaBarang,
        }));

    return (
        <Box p={2} border={`1px solid ${textPrimary.thin}`} borderRadius="8px">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                spacing={1}
            >
                {props.ukmData?.isPro === 1 && (
                    <Typography fontWeight="bold" mb={2}>
                        Faktur {props.indexNested + 1}
                    </Typography>
                )}
                {props.indexNested === 0 ? null : (
                    <Button
                        variant="contained"
                        onClick={() => props.remove(props.indexNested)}
                        size="small"
                        color="buttonred"
                        sx={{
                            minWidth: "unset",
                            padding: "8px",
                            width: "32px",
                            height: "32px",
                        }}
                    >
                        <Delete sx={{ width: "16px", height: "16px" }} />
                    </Button>
                )}
            </Stack>
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    md={4}
                    display={props.ukmData?.isPro !== 1 ? "none" : undefined}
                >
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Pilih faktur
                    </Typography>
                    <Controller
                        name={`detail.${props.indexDetail}.nestedDetail.${props.indexNested}.faktur`}
                        control={props.control}
                        rules={{ required: "Kolom wajib diisi" }}
                        render={({ field, fieldState }) => (
                            <>
                            <Autocomplete
                                placeholder="Pilih salah satu faktur"
                                disablePortal
                                disableClearable
                                options={props.hpp ?? []}
                                getOptionLabel={(option) => option.faktur}
                                isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                }
                                // getOptionDisabled={(option) =>
                                //     option.jumlah <= 0
                                // }
                                size="small"
                                value={field.value}
                                onChange={(
                                    _event,
                                    newFaktur: {
                                        id: number;
                                        faktur: string;
                                        hargaBeli: number;
                                        jumlah: number;
                                    } | null,
                                ) => {
                                    field.onChange(newFaktur);
                                }}
                                fullWidth
                                renderOption={(props, option) => (
                                    <Box
                                        component="li"
                                        {...props}
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        <Typography>{option.faktur}</Typography>
                                        {/* <Typography variant="caption">
                                            Harga beli:{" "}
                                            {toRupiah(option.hargaBeli)} | Stok:{" "}
                                            {option.jumlah}
                                        </Typography> */}
                                    </Box>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="-- Pilih Faktur --"
                                        // error={
                                        //     !!fieldState?.error ||
                                        //     field.value?.jumlah === 0
                                        // }
                                        error={!!fieldState?.error}
                                        helperText={
                                            fieldState?.error
                                                ? "Pilih satu faktur"
                                                : ""
                                        }
                                    />
                                )}
                            />
                            {field.value && (
                                <Typography mt={2} color="red">
                                    Harga Beli: {toRupiah(field.value.hargaBeli)} |
                                    Stok: {field.value.jumlah}
                                </Typography>
                            )}
                            </>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Pilih mutasi
                    </Typography>
                    <Controller
                        name={`detail.${props.indexDetail}.nestedDetail.${props.indexNested}.isMasuk`}
                        control={props.control}
                        render={({ field }) => (
                            <FormControl size="small" fullWidth>
                                <Select
                                    placeholder="Pilih Stok"
                                    margin="dense"
                                    onChange={(event: SelectChangeEvent) => {
                                        field.onChange(
                                            event.target.value as string,
                                        );
                                    }}
                                    value={field.value}
                                >
                                    <MenuItem value="masuk">
                                        Stok Masuk
                                    </MenuItem>
                                    <MenuItem value="keluar">
                                        Stok Keluar
                                    </MenuItem>
                                    {props.ukmData?.isPro === 1 && (
                                        <MenuItem value="repack">
                                            Repack
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item xs={6} md={4}>
                    <Typography color={textPrimary.body} fontWeight={500}>
                        Stok dimutasi
                    </Typography>
                    <Controller
                        name={`detail.${props.indexDetail}.nestedDetail.${props.indexNested}.jumlahStok`}
                        control={props.control}
                        rules={{
                            required: "Kolom wajib diisi",
                            min: {
                                value: 1,
                                message: "Minimal stok mutasi adalah 1 buah", // JS only: <p>error message</p> TS only support string
                            },
                            max: {
                                value:
                                    watchIsMasuk === "masuk"
                                        ? 999999
                                        : faktur
                                        ? faktur?.jumlah
                                        : 0,
                                message:
                                    watchIsMasuk === "masuk"
                                        ? "Stok maksimal"
                                        : `Stok maksimal`, // JS only: <p>error message</p> TS only support string
                            },
                        }}
                        render={({ field, fieldState }) => (
                            <TextField
                                type="number"
                                variant="outlined"
                                size="small"
                                onWheel={(e) => {
                                    const target =
                                        e.target as HTMLButtonElement;
                                    if (target) {
                                        target.blur();
                                        e.stopPropagation();
                                        setTimeout(
                                            () => {
                                                target.focus();
                                            },
                                            0,
                                        );
                                    }
                                }}
                                onKeyDown={(evt) =>
                                    [
                                        "e",
                                        "E",
                                        "+",
                                        "-",
                                    ].includes(
                                        evt.key,
                                    ) &&
                                    evt.preventDefault()
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            stok
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    inputMode: "numeric",
                                    style: {
                                        textAlign: "right",
                                    },
                                }}
                                fullWidth
                                error={!!fieldState?.error}
                                helperText={
                                    fieldState?.error &&
                                    fieldState?.error.message
                                }
                                {...field}
                                onChange={(event) => {
                                    if (watchIsMasuk === "repack") {
                                        field.onChange(
                                            Number(event.target.value),
                                        );
                                        const hargaBeli = Math.ceil(
                                            (Number(faktur?.hargaBeli) *
                                                Number(event.target.value)) /
                                                Number(stokRepack),
                                        );
                                        props.setValue(
                                            `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.hargaBeliRepack`,
                                            hargaBeli,
                                        );
                                    } else {
                                        field.onChange(
                                            Number(event.target.value),
                                        );
                                    }
                                }}
                            />
                        )}
                    />
                    <input
                        type="hidden"
                        {...props.register(
                            `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.itemId`,
                        )}
                        value={props.id}
                    />
                </Grid>
            </Grid>
            {watchIsMasuk === "repack" && (
                <Grid
                    container
                    spacing={1}
                    marginTop={1}
                    alignItems="flex-start"
                >
                    <Grid item xs={6} md={4}>
                        <Typography color={textPrimary.body} fontWeight={500}>
                            Repack menjadi
                        </Typography>
                        <Controller
                            name={`detail.${props.indexDetail}.nestedDetail.${props.indexNested}.stokRepack`}
                            control={props.control}
                            rules={{
                                required: "Kolom wajib diisi",
                                min: {
                                    value: 1,
                                    message: "Minimal stok 1 buah", // JS only: <p>error message</p> TS only support string
                                },
                            }}
                            render={({ field, fieldState }) => (
                                <TextField
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onWheel={(e) => {
                                        const target =
                                            e.target as HTMLButtonElement;
                                        if (target) {
                                            target.blur();
                                            e.stopPropagation();
                                            setTimeout(
                                                () => {
                                                    target.focus();
                                                },
                                                0,
                                            );
                                        }
                                    }}
                                    onKeyDown={(evt) =>
                                        [
                                            "e",
                                            "E",
                                            "+",
                                            "-",
                                        ].includes(
                                            evt.key,
                                        ) &&
                                        evt.preventDefault()
                                    }
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                stok
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        inputMode: "numeric",
                                        style: {
                                            textAlign: "right",
                                        },
                                    }}
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    {...field}
                                    onChange={(event) => {
                                        props.setValue(
                                            `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.stokRepack`,
                                            event.target.value,
                                        );
                                        console.log(
                                            "harga beli",
                                            faktur?.hargaBeli,
                                        );
                                        const hargaBeli = Math.ceil(
                                            (Number(faktur?.hargaBeli) *
                                                Number(jumlahStok)) /
                                                Number(event.target.value),
                                        );
                                        props.setValue(
                                            `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.hargaBeliRepack`,
                                            hargaBeli,
                                        );
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color={textPrimary.body} fontWeight={500}>
                            Untuk barang
                        </Typography>
                        <Controller
                            name={`detail.${props.indexDetail}.nestedDetail.${props.indexNested}.itemRepack`}
                            control={props.control}
                            rules={{ required: "Kolom wajib diisi" }}
                            render={({ field, fieldState }) => (
                                <Autocomplete
                                    disablePortal
                                    loading={isLoadingStok}
                                    options={mappedStok ?? []}
                                    getOptionLabel={(option) => option.nama}
                                    isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                    }
                                    getOptionDisabled={(option) =>
                                        option.id === props.id
                                    }
                                    size="small"
                                    value={field.value}
                                    onInputChange={(e) => {
                                        const element =
                                            e.target as HTMLInputElement;
                                        setParamsStok((prev) => ({
                                            ...prev,
                                            search: element.value,
                                        }));
                                    }}
                                    onChange={(
                                        _event,
                                        newKategori: IOptions | null,
                                    ) => {
                                        field.onChange(newKategori);
                                    }}
                                    fullWidth
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Pilih barang"
                                            error={!!fieldState?.error}
                                            helperText={
                                                fieldState?.error &&
                                                "Pilih satu barang"
                                            }
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography color={textPrimary.body} fontWeight={500}>
                            Harga beli satuan
                        </Typography>
                        <Controller
                            name={`detail.${props.indexDetail}.nestedDetail.${props.indexNested}.hargaBeliRepack`}
                            control={props.control}
                            rules={{ required: "Kolom wajib diisi" }}
                            render={({ field, fieldState }) => (
                                <NumberFormat
                                    disabled
                                    customInput={TextField}
                                    size="small"
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    fullWidth
                                    variant="outlined"
                                    onValueChange={(values) => {
                                        const { value } = values;
                                        const valueAkhir = value.replace(
                                            ".",
                                            "",
                                        );
                                        console.log(valueAkhir);
                                        props.setValue(
                                            `detail.${props.indexDetail}.nestedDetail.${props.indexNested}.hargaBeliRepack`,
                                            Number(valueAkhir),
                                        );
                                    }}
                                    error={!!fieldState?.error}
                                    helperText={
                                        fieldState?.error &&
                                        fieldState?.error.message
                                    }
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Typography>Rp.</Typography>
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        "& .MuiOutlinedInput-input.Mui-disabled":
                                            {
                                                color: "rgba(0, 0, 0, 0.87)",
                                                WebkitTextFillColor:
                                                    "rgba(0, 0, 0, 0.87)",
                                            },
                                    }}
                                    {...field}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            )}
            <Box mt={1.5} color={textPrimary.body} fontWeight={500}>
                <Typography color={textPrimary.body} fontWeight={500}>
                    Keterangan
                </Typography>
                <Controller
                    name={`detail.${props.indexDetail}.nestedDetail.${props.indexNested}.keterangan`}
                    control={props.control}
                    render={({ field, fieldState }) => (
                        <TextField
                            variant="outlined"
                            placeholder="Masukkan detail dan keterangan untuk faktur"
                            size="small"
                            id="keterangan"
                            fullWidth
                            multiline
                            rows={3}
                            error={!!fieldState?.error}
                            helperText={
                                fieldState?.error && fieldState?.error.message
                            }
                            {...field}
                        />
                    )}
                />
            </Box>
        </Box>
    );
};

export default BarangTerpilih;
