import React from "react";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { GetLaporanLabaRugiResponse } from "../../constants/types";
import toRibuan from "../../utils/toRibuan";

Font.register({
    family: "Open-Sans",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
});

Font.register({
    family: "Open-Sans-SemiBold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsgH1y4nY1M2xLER.ttf",
});

Font.register({
    family: "Open-Sans-Bold",
    src: "https://fonts.gstatic.com/s/opensans/v34/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1y4nY1M2xLER.ttf",
});

Font.registerHyphenationCallback((word) => {
    const middle = Math.floor(word.length / 2);
    const parts =
        word.length === 1
            ? [word]
            : [word.substr(0, middle), word.substr(middle)];

    // Check console to see words parts
    // console.log(word, parts);

    return parts;
});

const styles = StyleSheet.create({
    page: {
        backgroundColor: "#FFF",
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 10,
        fontFamily: "Open-Sans-Bold",
        textAlign: "center",
    },
    subtitle: {
        fontSize: 9,
        fontFamily: "Open-Sans-Bold",
        textAlign: "center",
    },
    subsubtitle: {
        fontSize: 8,
        fontFamily: "Open-Sans-Bold",
        textAlign: "center",
    },
    tableContainer: {
        flexDirection: "row",
        flexWrap: "wrap",
        marginTop: 24,
    },
    tableHeaderContainer: {
        flexDirection: "row",
        backgroundColor: "#45A779",
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        fontSize: 9,
        textOverflow: "ellipsis",
        flexGrow: 1,
        height: "20px",
    },
    tableHeaderTitle: {
        width: "50%",
        paddingHorizontal: 8,
        color: "#fff",
    },
    tableHeaderTanggal: {
        width: "50%",
        paddingHorizontal: 8,
        color: "#fff",
        textAlign: "right",
    },
    tableTitleContainer: {
        flexDirection: "row",
        backgroundColor: "rgb(248,248,248)",
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        fontSize: 9,
        textOverflow: "ellipsis",
        flexGrow: 1,
        height: 20,
    },
    tableTitleText: {
        width: "100%",
        paddingHorizontal: 8,
        color: "#000",
        textAlign: "left",
    },
    tableRowTitleContainer: {
        paddingLeft: 4,
        flexDirection: "row",
        alignItems: "center",
        fontFamily: "Open-Sans-SemiBold",
        fontSize: 9,
        height: 20,
    },
    tableRowTitleNoNama: {
        width: "70%",
        paddingHorizontal: 8,
    },
    tableRowTitleSaldo: {
        width: "30%",
        textAlign: "right",
        paddingHorizontal: 8,
    },
    tableRowContentContainer: {
        paddingLeft: 8,
        flexDirection: "row",
        alignItems: "flex-start",
        fontFamily: "Open-Sans",
        fontSize: 9,
    },
    tableRowContentSubContainer: {
        paddingLeft: 16,
        flexDirection: "row",
        alignItems: "flex-start",
        fontFamily: "Open-Sans",
        fontSize: 9,
    },
    tableRowContentTotalContainer: {
        paddingLeft: 8,
        flexDirection: "row",
        borderTopColor: "#000",
        borderTopWidth: 1,
        alignItems: "center",
        fontFamily: "Open-Sans",
        fontSize: 9,
        height: 20,
    },
    tableRowContentGrandTotalContainer: {
        flexDirection: "row",
        borderTopColor: "#000",
        borderTopWidth: 1,
        alignItems: "center",
        fontFamily: "Open-Sans",
        fontSize: 9,
        height: 20,
    },
    tableRowContentNamaValue: {
        width: "25%",
        paddingHorizontal: 8,
        paddingBottom: 4,
    },
    tableRowContentSaldo: {
        width: "50%",
        paddingHorizontal: 8,
        paddingBottom: 4,
        textAlign: "right",
    },
    tableRowContentTotalPendapatan: {
        width: "50%",
        textAlign: "right",
        paddingHorizontal: 8,
        paddingBottom: 4,
    },
    tableRowContentTotal: {
        width: "50%",
        paddingHorizontal: 8,
        paddingBottom: 4,
    },
    tableRowContentGrandTotal: {
        width: "50%",
        paddingHorizontal: 8,
        paddingBottom: 4,
        fontFamily: "Open-Sans-Bold",
    },
    tableRowContentTotalSaldo: {
        width: "50%",
        textAlign: "right",
        paddingBottom: 4,
        paddingHorizontal: 8,
        fontFamily: "Open-Sans-Bold",
    },
    tableTotalContainer: {
        flexDirection: "row",
        backgroundColor: "#fff1f0",
        borderTopColor: "#000",
        borderTopWidth: 1,
        borderBottomColor: "#000",
        borderBottomWidth: 1,
        alignItems: "center",
        fontFamily: "Open-Sans-Bold",
        fontSize: 9,
        textOverflow: "ellipsis",
        flexGrow: 1,
        height: 20,
    },
    tableRowContentPengeluaranTanggal: {
        width: "19%",
        paddingHorizontal: 8,
        paddingBottom: 4,
    },
    tableRowContentPengeluaranKode: {
        width: "51%",
        paddingHorizontal: 8,
        paddingBottom: 4,
    },
    tableRowContentPengeluaranUkm: {
        display: "flex",
        flexDirection: "column",
        width: "20%",
        paddingHorizontal: 8,
        paddingBottom: 4,
    },
    tableRowContentPengeluaranSaldo: {
        width: "30%",
        textAlign: "right",
        paddingHorizontal: 8,
        paddingBottom: 4,
    },
    summary: {
        display: "flex",
        flexDirection: "row",
        border: "1px solid #99e9c3",
        backgroundColor: "#99e9c3",
        borderRadius: 4,
        marginTop: 24,
    },
    summaryTitle: {
        fontFamily: "Open-Sans-Bold",
        fontSize: 10,
        color: "#45A779",
    },
    summaryContent: {
        fontFamily: "Open-Sans-Bold",
        fontSize: 12,
        color: "#226646",
        marginTop: 16,
    },
});

interface IPrintLabaRugiProps {
    laporanLabaRugi?: GetLaporanLabaRugiResponse["data"];
    tglAwal: string;
    tglAkhir: string;
    ukm: string;
}

const PrintLabaRugi = ({
    laporanLabaRugi,
    tglAwal,
    tglAkhir,
    ukm,
}: IPrintLabaRugiProps) => {
    const isSemuaUKM = ukm === "Semua UKM";

    const groupArrayByName = (
        array: GetLaporanLabaRugiResponse["data"]["content"]["Operational Expense"],
    ) => {
        const groups: {
            [
                key: string
            ]: GetLaporanLabaRugiResponse["data"]["content"]["Operational Expense"];
        } = {};

        // array.forEach((item) => {
        //     const { namaUkm } = item;
        //     if (!groups[namaUkm]) {
        //         groups[namaUkm] = [];
        //     }
        //     groups[namaUkm].push(item);
        // });

        return Object.entries(groups).map(([name, items]) => ({ name, items }));
    };

    const operationalExpenseGrouped = groupArrayByName(
        laporanLabaRugi?.content["Operational Expense"] ?? [],
    );

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Text style={styles.title}>LAPORAN LABA RUGI</Text>
                    <Text style={styles.title}>{ukm.toUpperCase()}</Text>
                    <Text style={styles.subtitle}>
                        {tglAwal} - {tglAkhir}
                    </Text>
                    <Text style={styles.subsubtitle}>(dalam IDR)</Text>
                </View>
                <View style={styles.summary}>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 8,
                            borderRight: "1px solid #45A779",
                            width: "33%",
                        }}
                    >
                        <Text style={styles.summaryTitle}>Laba Kotor</Text>
                        <Text style={styles.summaryContent}>
                            {laporanLabaRugi
                                ? laporanLabaRugi?.content["Gross Profit"] >= 0
                                    ? toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Gross Profit"
                                              ],
                                          ),
                                      )
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Gross Profit"
                                              ] * -1,
                                          ),
                                      )} )`
                                : "-"}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 8,
                            borderRight: "1px solid #45A779",
                            width: "33%",
                        }}
                    >
                        <Text style={styles.summaryTitle}>
                            Total Pengeluaran
                        </Text>
                        <Text style={styles.summaryContent}>
                            {laporanLabaRugi
                                ? laporanLabaRugi?.content[
                                      "Total dari Operational Expense"
                                  ] >= 0
                                    ? toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Operational Expense"
                                              ],
                                          ),
                                      )
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Operational Expense"
                                              ] * -1,
                                          ),
                                      )} )`
                                : "-"}
                        </Text>
                    </View>
                    <View
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: 8,
                            width: "33%",
                        }}
                    >
                        <Text style={styles.summaryTitle}>
                            Laba Bersih (Rugi)
                        </Text>
                        <Text style={styles.summaryContent}>
                            {laporanLabaRugi
                                ? laporanLabaRugi?.content["Profit (Loss)"] >= 0
                                    ? toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Profit (Loss)"
                                              ],
                                          ),
                                      )
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Profit (Loss)"
                                              ] * -1,
                                          ),
                                      )} )`
                                : "-"}
                        </Text>
                    </View>
                </View>
                {laporanLabaRugi && (
                    <View style={styles.tableContainer}>
                        <View style={styles.tableHeaderContainer}>
                            <Text style={styles.tableHeaderTitle}>Tanggal</Text>
                            <Text style={styles.tableHeaderTanggal}>
                                {tglAwal} - {tglAkhir}
                            </Text>
                        </View>
                        <View style={styles.tableTitleContainer}>
                            <Text style={styles.tableTitleText}>
                                Pendapatan
                            </Text>
                        </View>
                        {laporanLabaRugi.content.Revenue.map((rev) => (
                            <View
                                key={rev.idAkun}
                                style={styles.tableRowContentContainer}
                            >
                                <Text style={styles.tableRowContentNamaValue}>
                                    {rev.noAkun}
                                </Text>
                                <Text style={styles.tableRowContentNamaValue}>
                                    {rev.namaAkun}
                                </Text>
                                
                                <Text style={styles.tableRowContentSaldo}>
                                    {rev.saldo >= 0
                                    ? `Rp. ${toRibuan(
                                            String(rev.saldo),
                                        ) }`
                                    : `( Rp. ${toRibuan(
                                            String(
                                                rev.saldo * -1,
                                            ),
                                        )} )`}
                                </Text>
                            </View>
                        ))}
                        <View style={styles.tableRowContentTotalContainer}>
                            <Text style={styles.tableRowContentTotal}>
                                Total dari Pendapatan:
                            </Text>
                            
                            <Text style={styles.tableRowContentTotalSaldo}>
                                {laporanLabaRugi?.content[
                                    "Total dari Revenue"
                                ] >= 0
                                    ? `Rp. ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Revenue"
                                              ],
                                          ),
                                      )}`
                                    : `( Rp. ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Revenue"
                                              ] * -1,
                                          ),
                                      )} )`}
                            </Text>
                        </View>
                        <View style={styles.tableTitleContainer}>
                            <Text style={styles.tableTitleText}>
                                Biaya Pembelian
                            </Text>
                        </View>
                        {laporanLabaRugi.content["Cost of Sales"].map(
                            (cos) => (
                                <View
                                    key={cos.idAkun}
                                    style={styles.tableRowContentContainer}
                                >
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {cos.noAkun}
                                    </Text>
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {cos.namaAkun}
                                    </Text>
                                    
                                    <Text style={styles.tableRowContentSaldo}>
                                        {cos.saldo >= 0
                                        ? `Rp. ${toRibuan(
                                                String(cos.saldo),
                                            ) }`
                                        : `( Rp. ${toRibuan(
                                                String(
                                                    cos.saldo * -1,
                                                ),
                                            )} )`}
                                    </Text>
                                </View>
                            ),
                        )}
                        <View style={styles.tableRowContentTotalContainer}>
                            <Text style={styles.tableRowContentTotal}>
                                Total dari Biaya Pembelian:
                            </Text>
                            <Text style={styles.tableRowContentTotalSaldo}>
                                {laporanLabaRugi?.content[
                                    "Total dari Cost of Sales"
                                ] >= 0
                                    ? `Rp. ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Cost of Sales"
                                              ],
                                          ),
                                      )}`
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Cost of Sales"
                                              ] * -1,
                                          ),
                                      )} )`}
                            </Text>
                        </View>
                        <View style={styles.tableRowContentGrandTotalContainer}>
                            <Text style={styles.tableRowContentGrandTotal}>
                                Laba Kotor:
                            </Text>
                            <Text style={styles.tableRowContentTotalSaldo}>
                                {laporanLabaRugi?.content["Gross Profit"] >= 0
                                    ? `Rp. ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Gross Profit"
                                              ],
                                          ),
                                      )}`
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Gross Profit"
                                              ] * -1,
                                          ),
                                      )} )`}
                            </Text>
                        </View>

                        <View style={styles.tableTitleContainer}>
                            <Text style={styles.tableTitleText}>
                                Pengeluaran Operasional
                            </Text>
                        </View>

                        {laporanLabaRugi.content["Operational Expense"].map(
                            (oe) => (
                                <View
                                    key={oe.idAkun}
                                    style={styles.tableRowContentContainer}
                                >
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {oe.noAkun}
                                    </Text>
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {oe.namaAkun}
                                    </Text>
                                    
                                    <Text style={styles.tableRowContentSaldo}>
                                        {oe.saldo >= 0
                                        ? `Rp. ${toRibuan(
                                                String(oe.saldo),
                                            ) }`
                                        : `( Rp. ${toRibuan(
                                                String(
                                                    oe.saldo * -1,
                                                ),
                                            )} )`}
                                    </Text>
                                </View>
                            ),
                        )}
                        
                        <View style={styles.tableRowContentTotalContainer}>
                            <Text style={styles.tableRowContentTotal}>
                                Total dari Pengeluaran Operasional:
                            </Text>
                            <Text style={styles.tableRowContentTotalSaldo}>
                                {laporanLabaRugi?.content[
                                    "Total dari Operational Expense"
                                ] >= 0
                                    ? `Rp. ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Operational Expense"
                                              ],
                                          ),
                                      )}`
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Total dari Operational Expense"
                                              ] * -1,
                                          ),
                                      )} )`}
                            </Text>
                        </View>

                        <View style={styles.tableRowContentGrandTotalContainer}>
                            <Text style={styles.tableRowContentGrandTotal}>
                                Laba Usaha:
                            </Text>
                            <Text style={styles.tableRowContentTotalSaldo}>
                                {laporanLabaRugi?.content["Operating Profit"] >= 0
                                    ? `Rp. ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Operating Profit"
                                              ],
                                          ),
                                      )}`
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Operating Profit"
                                              ] * -1,
                                          ),
                                      )} )`}
                            </Text>
                        </View>

                        <View style={styles.tableTitleContainer}>
                            <Text style={styles.tableTitleText}>
                                Pendapatan Lain (Pengeluaran)
                            </Text>
                        </View>

                        <View style={styles.tableRowContentTotalContainer}>
                            <Text style={styles.tableRowContentTotal}>
                                Pendapatan Lain
                            </Text>
                            <Text style={styles.tableRowContentTotal}>
                                
                            </Text>
                        </View>
                        
                        {laporanLabaRugi.content["Other Income (Expense)"]["Other Income"].map(
                            (oi) => (
                                <View
                                    key={oi.idAkun}
                                    style={styles.tableRowContentContainer}
                                >
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {oi.noAkun}
                                    </Text>
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {oi.namaAkun}
                                    </Text>
                                    
                                    <Text style={styles.tableRowContentSaldo}>
                                        {oi.saldo >= 0
                                        ? `Rp. ${toRibuan(
                                                String(oi.saldo),
                                            ) }`
                                        : `( Rp. ${toRibuan(
                                                String(
                                                    oi.saldo * -1,
                                                ),
                                            )} )`}
                                    </Text>
                                </View>
                            ),
                        )}

                        <View style={styles.tableRowContentTotalContainer}>
                            <Text style={styles.tableRowContentTotal}>
                                Pengeluaran Lain
                            </Text>
                            <Text style={styles.tableRowContentTotal}>
                                
                            </Text>
                        </View>
                        
                        {laporanLabaRugi.content["Other Income (Expense)"]["Other Expense"].map(
                            (oe) => (
                                <View
                                    key={oe.idAkun}
                                    style={styles.tableRowContentContainer}
                                >
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {oe.noAkun}
                                    </Text>
                                    <Text style={styles.tableRowContentNamaValue}>
                                        {oe.namaAkun}
                                    </Text>
                                    
                                    <Text style={styles.tableRowContentSaldo}>
                                        {oe.saldo >= 0
                                        ? `Rp. ${toRibuan(
                                                String(oe.saldo),
                                            ) }`
                                        : `( Rp. ${toRibuan(
                                                String(
                                                    oe.saldo * -1,
                                                ),
                                            )} )`}
                                    </Text>
                                </View>
                            ),
                        )}

                        <View style={styles.tableRowContentGrandTotalContainer}>
                            <Text style={styles.tableRowContentGrandTotal}>
                                Laba Bersih (Rugi):
                            </Text>
                            <Text style={styles.tableRowContentTotalSaldo}>
                                {laporanLabaRugi?.content["Profit (Loss)"] >= 0
                                    ? `Rp. ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Profit (Loss)"
                                              ],
                                          ),
                                      )}`
                                    : `( ${toRibuan(
                                          String(
                                              laporanLabaRugi?.content[
                                                  "Profit (Loss)"
                                              ] * -1,
                                          ),
                                      )} )`}
                            </Text>
                        </View>
                    </View>
                )}
            </Page>
        </Document>
    );
};

export default PrintLabaRugi;
