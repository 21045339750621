import { useQuery } from "react-query";
import {
    GetUkmResponse,
    ParamsGetUkmIsDueExpired,
} from "../../constants/types";
import axios from "../axios";

const queryString = require("query-string");

const handleRequest = async (params: ParamsGetUkmIsDueExpired) => {
    const apiGetUkm = queryString.stringifyUrl({
        url: "api/ukm",
        query: {
            search: `${params.search}`,
            size: params.size,
            page: params.page,
            isDueExpired: params.isDueExpired,
            isNaikKelas: 1,
        },
    });
    const { data } = await axios.get<GetUkmResponse>(apiGetUkm);
    return data.data;
};

export default function useUkmIsDueExpired(params: ParamsGetUkmIsDueExpired) {
    return useQuery<GetUkmResponse["data"]>(["ukmIsDueExpired", params], () =>
        handleRequest(params),
    );
}
