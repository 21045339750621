import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContent from "@mui/material/DialogContent";
import { BlobProvider } from "@react-pdf/renderer";
import React, { useState } from "react";
import { useAuth } from "../../context/authContext";
import {
    DaftarItemListInvoice,
    DaftarKategoriListInvoice,
    ParamsGetKategoriListInvoice,
    TipeStruk,
    ContentGetListInvoice,
    ParamsGetListInvoice,
} from "../../constants/types";
import moment from "moment";
import "moment/locale/id";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import toRupiah from "../../utils/toRupiah";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import PrintStrukPdf from "../PrintStruk/PrintStrukPdf";
import useFindCustomStruk from "../../services/queries/useFindCustomStruk";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PrintStrukA4 from "../PrintStrukA4/PrintStrukA4";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Tooltip } from "@mui/material";
import truncate from "../../utils/truncate";
import { Close } from "@mui/icons-material";
import toRibuan from "../../utils/toRibuan";
import { textPrimary } from "../../constants/colors";
import { useTransaksi } from "../../context/transaksiContext";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useListInvoice from "../../services/queries/useListInvoice";
import {
    InputLabel,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

interface IModalDetailInvoiceProps {
    id: number;
    fullScreen: boolean;
    isOpenModalDetailInvoice: boolean;
    statusDetailInvoice: "idle" | "error" | "loading" | "success";
    closeModalDetailInvoice: () => void;
    tanggalTransaksi: string;
    nomorInvoice: string;
    daftarItem: DaftarItemListInvoice[];
    daftarKategori: DaftarKategoriListInvoice[];
    totalHarga: number;
    diskon: number;
    pajak: number;
    pajakPersen: number;
    totalPembayaran: number;
    uangYangDibayar: number;
    kembalian: number;
    kasir: string;
    sales: string | null;
    namaMetode: string | null;
    keterangan: string | null;
    encrypt: string | null;
    namaPemegang: string | null;
    nomorTujuan: string | null;
    qr?: string | null;
    transaksiKe: string;
    paramsKategori: ParamsGetKategoriListInvoice;
    setParamsKategori: (params: ParamsGetKategoriListInvoice) => void;
    refetchDetailInvoice: () => void;
    isOpenBill?: number;
    statusOpenBill?: number;
    isPiutang?: number;
    riwayatPembayaran?: {
        id: number;
        keterangan: string;
        metode: string;
        nominal: number;
        tanggal: string;
    }[];
}

const ModalDetailInvoice = ({
    id,
    fullScreen,
    isOpenModalDetailInvoice,
    statusDetailInvoice,
    closeModalDetailInvoice,
    tanggalTransaksi,
    nomorInvoice,
    daftarItem,
    daftarKategori,
    totalHarga,
    diskon,
    pajak,
    pajakPersen,
    totalPembayaran,
    uangYangDibayar,
    kembalian,
    kasir,
    sales,
    namaMetode,
    keterangan,
    encrypt,
    namaPemegang,
    nomorTujuan,
    qr,
    transaksiKe,
    paramsKategori,
    setParamsKategori,
    refetchDetailInvoice,
    isOpenBill,
    statusOpenBill,
    isPiutang,
    riwayatPembayaran,
}: IModalDetailInvoiceProps) => {
    const { data: user, status: loadingUser } = useUser();
    let now = moment().format("YYYY/MM/DD");

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { ukmIdUser } = useAuth();
    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const {
        setFetchingItems,
    } = useTransaksi();

    const newCart = daftarItem.map((items, index) => ({
        id: index,
        namaBarang: String(items.namaItem),
        qty: Number(items.jumlahItem),
        harga: Number(items.hargaSatuan),
        namaKategori: String(items.namaKategori),
    }));

    const { data: findCustomStruk } = useFindCustomStruk(Number(ukmId));
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStrukLaporan") ?? TipeStruk.Mobile,
    );

    const initialParamsListInvoice = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 10,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
        kasir: "",
        sales: "",
        metodeId: "",
        voidInvoice: "",
    };

    const [paramsListInvoice, setParamsListInvoice] =
        useState<ParamsGetListInvoice>(initialParamsListInvoice);

    const {
        data: listInvoice,
        refetch: refecthListInvoice,
        isLoading: isLoadingListInvoice,
        isError: isErrorListInvoice,
    } = useListInvoice(paramsListInvoice);

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStrukLaporan", newTipeStruk);
        }
    };

    const groupByKategori = false;

    const [isButtonLoading, setIsButtonLoading] = useState(false);
    
    const handlingCancelOpenBill = async () => {
        setIsButtonLoading(true);
        
        const body = {
            transaksiId : id,
        };
    
        try {
            const { data } = await axios.post(
                `/api/openbill-hapus`,
                body,
            );

            if (data.code === 200) {    
                Swal.fire({
                    title: "Open Bill berhasil dihapus!",
                    text: "Open Bill berhasil dihapus",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: { container: "my-swal" },
                });

                setFetchingItems();

                refecthListInvoice();
                closeModalDetailInvoice();
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
        } finally {
            setIsButtonLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth="md"
            fullScreen={fullScreen}
            fullWidth={true}
            open={isOpenModalDetailInvoice && statusDetailInvoice === "success"}
            scroll="body"
            onClose={() => {
                setKirimEmail(false);
                setEmail("");
                closeModalDetailInvoice();
            }}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                >
                    <Typography fontSize={32} fontWeight="bold">
                        Detail Invoice
                    </Typography>
                    <IconButton
                        onClick={() => {
                            setKirimEmail(false);
                            setEmail("");
                            closeModalDetailInvoice();
                        }}
                    >
                        <Close fontSize="small" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} md={4}>
                        <Typography color={textPrimary.light}>
                            Tanggal
                        </Typography>
                        <Typography color={textPrimary.body}>
                            {moment(tanggalTransaksi).format("DD MMM YYYY HH:mm")}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography color={textPrimary.light}>
                            No. Invoice
                        </Typography>
                        <Typography color={textPrimary.body}>
                            {nomorInvoice}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography color={textPrimary.light}>
                            Jenis Transaksi
                        </Typography>
                        <Typography color={textPrimary.body}>
                            {isOpenBill === 1 ? "Open Bill" : namaMetode ?? "Tunai"}
                        </Typography>
                    </Grid>

                    {/* {isOpenBill === 1 && (
                        <Grid item xs={6} md={3}>
                            <Typography color={textPrimary.light}>
                                Status Transaksi
                            </Typography>
                            <Typography color={textPrimary.body} fontWeight={600}>
                                Open Bill
                            </Typography>
                        </Grid>
                    )} */}
                </Grid>
                <Grid container spacing={2} mt={1}>
                    <Grid item xs={6} md={4}>
                        <Typography color={textPrimary.light}>
                            Metode Pembayaran
                        </Typography>
                        <Typography color={textPrimary.body}>
                            {namaMetode ?? "Tunai"}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                        <Typography color={textPrimary.light}>
                            Nama Kasir
                        </Typography>
                        <Typography color={textPrimary.body}>
                            {kasir}
                        </Typography>
                    </Grid>
                    {sales && (
                        <Grid item xs={6} md={4}>
                            <Typography color={textPrimary.light}>
                                Nama Sales
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {sales}
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <Box my={2}>
                    <Divider sx={{ borderStyle: "dashed" }} />
                </Box>

                <Stack spacing={2}>
                    {daftarItem.map((items, index) => (
                        <Stack direction="column" key={index}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <Tooltip title={items.namaItem}>
                                        <Typography color={textPrimary.body}>
                                            {truncate(items.namaItem, 20)}
                                        </Typography>
                                    </Tooltip>
                                    <Typography color={textPrimary.light}>
                                        @{toRupiah(items.hargaSatuan)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        {items.jumlahItem}x
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={4}
                                    display="flex"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                        textAlign="end"
                                    >
                                        {toRupiah(
                                            items.hargaSatuan *
                                                items.jumlahItem,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Stack direction="column" mt={1}>
                                <Typography color={textPrimary.body}>
                                    Keterangan:
                                </Typography>
                                <Typography whiteSpace="pre-line" color={textPrimary.body}>
                                    {items.keteranganItem ?? "-"}
                                </Typography>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
                <Stack
                    spacing={1}
                    p={2}
                    mt={2}
                    border={`1px solid ${textPrimary.thin}`}
                    borderRadius="8px"
                >
                    <Typography
                        color={textPrimary.title}
                        fontSize={20}
                        fontWeight={500}
                    >
                        Ringkasan Pembayaran
                    </Typography>
                    {isPiutang === 1 && (
                        <Box mt={2} mb={4}>
                            <Typography
                                fontSize={18}
                                fontWeight={400}
                            >
                                Riwayat Transaksi
                            </Typography>
                            
                            <>
                                <TableContainer
                                    component={Paper}
                                    sx={{
                                        boxShadow: "none",
                                    }}
                                >
                                    <Table>
                                        <TableHead
                                            sx={{
                                                borderLeft:
                                                    "1px solid white",
                                            }}
                                        >
                                            <TableRow>
                                                <TableCell>
                                                    Tanggal Pembayaran
                                                </TableCell>
                                                <TableCell>
                                                    Keterangan
                                                </TableCell>
                                                <TableCell align="right">
                                                    Nominal
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {riwayatPembayaran?.map(
                                                (item) => (
                                                    <TableRow
                                                        key={item.id}
                                                    >
                                                        <TableCell>
                                                            {
                                                                item.tanggal
                                                            }
                                                        </TableCell>
                                                        <TableCell>
                                                            {item.keterangan
                                                                ? item.keterangan
                                                                : "-"}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {toRupiah(
                                                                item.nominal,
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ),
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        </Box>
                    )}
                    <Grid container justifyContent="space-between">
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color={textPrimary.body}>
                                Subtotal Harga
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography
                                    color={textPrimary.light}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color={textPrimary.light}
                                    fontWeight={500}
                                >
                                    {toRibuan(totalHarga ?? 0)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={4} md={5} lg={5}>
                            <Typography color={textPrimary.body}>
                                {pajakPersen
                                    ? `Pajak (${pajakPersen}%)`
                                    : `Pajak`}
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                bgcolor="#e9f2f0"
                                paddingX={1}
                            >
                                <Typography
                                    color={textPrimary.light}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color={textPrimary.light}
                                    fontWeight={500}
                                >
                                    {toRibuan(pajak ?? 0) }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={4} md={5} lg={5}>
                            <Typography color={textPrimary.body}>
                                Diskon
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography color="#ed4521" fontWeight={500}>
                                    Rp.
                                </Typography>
                                <Typography color="#ed4521" fontWeight={500}>
                                    {toRibuan(diskon ?? 0)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderStyle: "dashed", paddingTop: 1 }} />
                    <Grid
                        container
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color={textPrimary.body}>
                                Total Tagihan
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography
                                    color={textPrimary.title}
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color={textPrimary.title}
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    {toRibuan(totalHarga ?? 0)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color={textPrimary.body}>
                                Uang yang Dibayar
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                bgcolor="#e9f2f0"
                                paddingX={1}
                            >
                                <Typography
                                    color={textPrimary.title}
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color="#0a5bbb"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    {toRibuan(uangYangDibayar ?? 0)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        marginTop={1}
                        justifyContent="space-between"
                    >
                        <Grid item xs={6} sm={3} md={4} lg={4}>
                            <Typography color={textPrimary.body}>
                                Kembalian
                            </Typography>
                        </Grid>
                        <Grid item lg={3} xs={5}>
                            <Box
                                display="flex"
                                justifyContent="space-between"
                                paddingX={1}
                            >
                                <Typography
                                    color={textPrimary.title}
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    Rp.
                                </Typography>
                                <Typography
                                    color="#119c5b"
                                    fontSize={20}
                                    fontWeight={500}
                                >
                                    {toRibuan(kembalian ?? 0)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {keterangan && (
                        <>
                            <Divider
                                sx={{ borderStyle: "dashed", marginTop: 1 }}
                            />
                            <Box mt={1}>
                                <Typography color={textPrimary.body}>
                                    Keterangan:
                                </Typography>
                                <Typography color={textPrimary.body}>
                                    {keterangan}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Stack>
                {isOpenBill === 0 && (
                    <Box>
                        <Stack direction="row" spacing={2} mt={2} alignItems="center">
                            <Typography>Pilih Tipe Struk</Typography>
                            <ToggleButtonGroup
                                value={tipeStruk}
                                exclusive
                                size="small"
                                onChange={handleTipeStruk}
                            >
                                <ToggleButton
                                    value={TipeStruk.Mobile}
                                    sx={{
                                        display: "flex",
                                        paddingX: 2,
                                    }}
                                >
                                    <b>A8</b>
                                </ToggleButton>
                                <ToggleButton
                                    value={TipeStruk.PC}
                                    sx={{
                                        display: "flex",
                                        paddingX: 2,
                                    }}
                                >
                                    <b>A4</b>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                        <Box display="flex">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={kirimEmail}
                                    onChange={(_, checked) => {
                                        handleKirimEmail(checked);
                                    }}
                                />
                            }
                            label="Kirim struk"
                            sx={{
                                height: "fit-content",
                            }}
                        />
                        </Box>
                    </Box>
                )}
                {kirimEmail && (
                    <Grid
                        container
                        alignItems="center"
                        spacing={1}
                        marginBottom={2}
                    >
                        <Grid item xs={12} md={6}>
                            <TextField
                                value={email}
                                size="small"
                                type="email"
                                fullWidth
                                placeholder="8316352725"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            +62
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => setEmail(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button
                                disabled={email.length <= 0}
                                variant="outlined"
                                onClick={() => {
                                    setKirimEmail(false);
                                    setEmail("");
                                    closeModalDetailInvoice();
                                }}
                                href={`https://wa.me/62${email}?text=Struk%20pembayaran%20ULO%20${nomorInvoice}%20${window.location.origin}/struk/${encrypt}`}
                                target="_blank"
                                rel="noreferrer"
                                fullWidth
                            >
                                Kirim struk ke WhatsApp
                            </Button>
                        </Grid>
                    </Grid>
                )}
                <Stack 
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    alignItems="center"
                    mt={1}
                >
                    {statusOpenBill === 1 && (
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: "red",
                                color: "white",
                            }}
                            onClick={() => {
                                handlingCancelOpenBill();
                            }}
                        >
                            Batalkan Pesanan
                        </Button>
                    )}

                    <Stack direction="row" justifyContent="flex-end" gap={1} mt={1} flex={1}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setKirimEmail(false);
                                setEmail("");
                                closeModalDetailInvoice();
                            }}
                        >
                            Kembali
                        </Button>
                        <Box>
                            {ukmData &&
                                (tipeStruk === TipeStruk.Mobile ? (
                                    <BlobProvider
                                        document={
                                            <PrintStrukPdf
                                                namaToko={ukmData.namaToko}
                                                alamatToko={ukmData.alamatToko}
                                                telpToko={ukmData.noTelp}
                                                gambarToko={
                                                    ukmData?.gambarTokoBW === null
                                                        ? undefined
                                                        : ukmData?.gambarTokoBW
                                                }
                                                tanggalTransaksi={tanggalTransaksi}
                                                transaksiKe={transaksiKe}
                                                kasir={kasir}
                                                sales={sales}
                                                total={totalPembayaran}
                                                cart={newCart}
                                                groupByKategori={groupByKategori}
                                                category={daftarKategori}
                                                diskon={diskon}
                                                pajak={pajak}
                                                pajakPersen={pajakPersen}
                                                tunai={uangYangDibayar}
                                                kembalian={kembalian}
                                                isPro={ukmData.isPro}
                                                namaMetode={namaMetode ?? "Tunai"}
                                                html={
                                                    findCustomStruk &&
                                                    findCustomStruk.html !== null
                                                        ? findCustomStruk?.html
                                                        : undefined
                                                }
                                                keterangan={keterangan}
                                                noMetode={nomorTujuan}
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="center"
                                                    variant="contained"
                                                    href={url ?? ""}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    sx={{ height: "100%" }}
                                                >
                                                    Print Ulang Struk
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                ) : (
                                    <BlobProvider
                                        document={
                                            <PrintStrukA4
                                                ukmData={ukmData}
                                                tanggalTransaksi={tanggalTransaksi}
                                                transaksiKe={transaksiKe}
                                                kasir={kasir}
                                                sales={sales}
                                                total={totalPembayaran}
                                                cart={newCart}
                                                category={daftarKategori}
                                                groupByKategori={groupByKategori}
                                                diskon={diskon}
                                                pajak={pajak}
                                                pajakPersen={pajakPersen}
                                                tunai={uangYangDibayar}
                                                kembalian={kembalian}
                                                namaMetode={namaMetode ?? "Tunai"}
                                                html={
                                                    findCustomStruk &&
                                                    findCustomStruk.html !== null
                                                        ? findCustomStruk?.html
                                                        : undefined
                                                }
                                                keterangan={keterangan}
                                                namaPemegang={namaPemegang}
                                                nomorTujuan={nomorTujuan}
                                                qr={qr}
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    loading={loading}
                                                    loadingPosition="center"
                                                    variant="contained"
                                                    href={url ?? ""}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    sx={{ height: "100%" }}
                                                >
                                                    Print Ulang Struk
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                ))}
                        </Box>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default ModalDetailInvoice;
