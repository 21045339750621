import React, { useCallback, useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
// import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import toRupiah from "../../utils/toRupiah";
import useDetailInvoice from "../../services/queries/useDetailInvoice";
import ModalDetailInvoice from "../ModalDetailInvoice/ModalDetailInvoice";
import ModalRevertInvoice from "../ModalRevertInvoice/ModalRevertInvoice";
import Button from "@mui/material/Button";
import { textPrimary } from "../../constants/colors";
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import useListInvoice from "../../services/queries/useListInvoice";
import { DetailBundling } from "../../constants/types";
import { useTransaksi } from "../../context/transaksiContext";
import { dataInvoice } from "../../constants/mock";
import moment from "moment";
import "moment/locale/id";

interface ICardInvoiceProps {
    detailInvoiceId: number;
    noInvoice: string;
    tanggalInvoice: string;
    hargaPenjualan: number;
    isReverted: number;
    metode: string;
    refetchListInvoice: () => void;
    handleAddToCart: (invoice: any) => void;
    handleAddToCartEndOpenBill: (invoice: any) => void;
    ketBeli: string | null;
    daftarItem: {
        id: number;
        namaBarang: string;
        namaKategori: string;
        sku: string;
        harga: number;
        url: string;
        qty: number;
        jumlahStok: number | undefined;
        detailBundling: DetailBundling[] | [];
        hpp: {
            id: number;
            faktur: string;
            hargaBeli: number;
            jumlah: number;
        }[];
        multipleHarga: {
            id: number;
            harga: number;
            keterangan: string | null;
        }[];
    }[];
}

const CardInvoice = ({
    detailInvoiceId,
    noInvoice,
    tanggalInvoice,
    hargaPenjualan,
    isReverted,
    metode,
    refetchListInvoice,
    handleAddToCart,
    handleAddToCartEndOpenBill,
    daftarItem,
    ketBeli,
}: ICardInvoiceProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const [isOpenModalRevertInvoice, setIsOpenModalRevertInvoice] =
        useState(false);
    const [isOpenModalDetailInvoice, setIsOpenModalDetailInvoice] =
        useState(false);
    // const { data: detailInvoice, status: statusDetailInvoice } =
    //     useDetailInvoice(detailInvoiceId);
    
    const {
        openModalBayar,
        openBill,
        setOpenBill,
        transaksiOpenBillId,
        setTransaksiOpenBillId,
        totalTagihanOpenBill,
        setTotalTagihanOpenBill,
        cart,
        addToCart,
        ketBeliOpenBill,
        setKetBeliOpenBill,
    } = useTransaksi();

    type InvoiceItem = {
        id: number;
        namaBarang: string;
        namaKategori: string;
        sku: string | number;
        harga: number;
        stok: number;
        url: string;
        jumlahItem: number;
        detailBundling: DetailBundling[] | [];
        hpp: {
            id: number;
            faktur: string;
            hargaBeli: number;
            jumlah: number;
        }[];
        hargaMultiple: {
            id: number;
            harga: number;
            keterangan: string | null;
        }[];
    };

    const handleClickEndOpenBill = () => {
        openModalBayar();
        setOpenBill(1);
        setTransaksiOpenBillId(detailInvoiceId);
        // setTotalTagihanOpenBill(detailInvoice?.totalHarga || 0);
        setTotalTagihanOpenBill(detailInvoice?.totalHarga ?? 0);
        setKetBeliOpenBill(ketBeli);

        if (openBill !== 1) {
            setTimeout(() => {
                handleAddToCartEndOpenBill({
                    id: detailInvoiceId,
                    nomorInvoice: noInvoice,
                    daftarItem,
                });
            }, 0);
        }
    };

    useEffect(() => {
        if (openBill === 1) {
            handleAddToCartEndOpenBill({
                id: detailInvoiceId,
                nomorInvoice: noInvoice,
                daftarItem,
            });
        }
    }, [daftarItem, detailInvoiceId, handleAddToCartEndOpenBill, noInvoice, openBill]);

    const closeModalDetailInvoice = () => {
        setIsOpenModalDetailInvoice(false);
    };

    const [paramsKategori, setParamsKategori] = useState<{ grupProduk: 0 | 1 }>(
        { grupProduk: 0 },
    );

    const {
        data: detailInvoice,
        status: statusDetailInvoice,
        refetch: refetchDetailInvoice,
    } = useDetailInvoice(detailInvoiceId, paramsKategori);

    console.log(detailInvoice);    

    return (
        <React.Fragment>
            <Card
                variant="outlined"
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 1,
                    padding: isTabletScreen ? 1 : 2,
                }}
            >
                <Box>
                    <Typography fontWeight={400} color={textPrimary.light}>
                        {moment(tanggalInvoice).format("DD MMM YYYY HH:mm")}
                    </Typography>
                    <Typography fontSize={20} fontWeight={500}>
                        {noInvoice}
                    </Typography>
                    <Typography marginTop={1}
                        fontWeight={500}
                        color={textPrimary.body}
                    >
                        {detailInvoice?.keterangan}
                    </Typography>
                    <Typography
                        // marginTop={1}
                        fontWeight={500}
                        color={textPrimary.body}
                    >
                        {isReverted
                            ? "Pembayaran dibatalkan"
                            : hargaPenjualan < 0
                            ? "Laporan pembatalan"
                            : `${metode}: ${toRupiah(hargaPenjualan)}`}
                    </Typography>
                </Box>
                <Stack direction="row" spacing={1}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsOpenModalDetailInvoice(true);
                        }}
                        size="small"
                        color="buttonblue"
                        disableElevation
                        sx={{
                            minWidth: "unset",
                            padding: "8px",
                            width: "32px",
                            height: "32px",
                        }}
                    >
                        <VisibilityIcon
                            sx={{ width: "16px", height: "16px" }}
                        />
                    </Button>

                    {detailInvoice?.statusOpenBill === 1 && (
                        <Button
                            variant="contained"
                            size="small"
                            // onClick={() => handleAddToCart(daftarItem)}
                            onClick={() =>
                                handleAddToCart({
                                    id: detailInvoiceId,
                                    nomorInvoice: noInvoice,
                                    daftarItem,
                                })
                            }
                            color="buttonyellow"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <AddIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                    )}

                    {detailInvoice?.statusOpenBill === 1 && (
                        <Button
                            variant="contained"
                            size="small"
                            onClick={handleClickEndOpenBill}
                            color="buttongreen"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <CheckIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                    )}
                    {/* {hargaPenjualan > 0 && isReverted === 0 && (
                        <IconButton
                            onClick={() => {
                                setIsOpenModalRevertInvoice(true);
                            }}
                        >
                            <DeleteIcon />
                        </IconButton>
                    )} */}
                </Stack>
            </Card>
            {detailInvoice && (
                <ModalDetailInvoice
                    id={detailInvoiceId}
                    fullScreen={fullScreen}
                    isOpenModalDetailInvoice={isOpenModalDetailInvoice}
                    statusDetailInvoice={statusDetailInvoice}
                    closeModalDetailInvoice={closeModalDetailInvoice}
                    tanggalTransaksi={String(detailInvoice.tanggalTransaksi)}
                    nomorInvoice={String(detailInvoice.nomorInvoice)}
                    daftarItem={detailInvoice.daftarItem}
                    daftarKategori={detailInvoice.daftarKategori}
                    totalHarga={Number(detailInvoice.totalHarga)}
                    diskon={Number(detailInvoice.diskon)}
                    totalPembayaran={Number(detailInvoice.totalPembayaran)}
                    uangYangDibayar={Number(detailInvoice.uangYangDibayar)}
                    kembalian={Number(detailInvoice.kembalian)}
                    kasir={detailInvoice.kasir}
                    sales={detailInvoice.sales}
                    pajak={detailInvoice.pajak}
                    pajakPersen={detailInvoice.pajakPersen}
                    namaMetode={detailInvoice.metode}
                    keterangan={detailInvoice.keterangan}
                    encrypt={detailInvoice.encrypt}
                    namaPemegang={detailInvoice.metodeArr.namaPemegang}
                    nomorTujuan={detailInvoice.metodeArr.noTujuan}
                    qr={detailInvoice.metodeArr.gambar}
                    transaksiKe={detailInvoice.transaksiKe}
                    paramsKategori={paramsKategori}
                    setParamsKategori={setParamsKategori}
                    refetchDetailInvoice={refetchDetailInvoice}
                    isOpenBill={detailInvoice.isOpenBill}
                    statusOpenBill={detailInvoice.statusOpenBill}
                />
            )}
            <ModalRevertInvoice
                diskon={Number(detailInvoice?.diskon)}
                totalHarga={Number(detailInvoice?.totalHarga)}
                totalTagihan={Number(detailInvoice?.totalPembayaran)}
                uangDibayar={Number(detailInvoice?.uangYangDibayar)}
                kembalian={Number(detailInvoice?.kembalian)}
                pajak={detailInvoice?.pajak ?? 0}
                refetchLaporan={() => {}}
                refetchListInvoice={refetchListInvoice}
                refetchProdukTerjual={() => {}}
                refetchMutasiStok={() => {}}
                refetchGrafikPenjualan={() => {}}
                refetchLaporanLabaRugi={() => {}}
                item={detailInvoice?.daftarItem}
                statusItem={statusDetailInvoice}
                invoiceId={detailInvoiceId}
                isOpenModalRevertInvoice={isOpenModalRevertInvoice}
                closeModalRevertInvoice={() =>
                    setIsOpenModalRevertInvoice(false)
                }
            />
        </React.Fragment>
    );
};

export default CardInvoice;
