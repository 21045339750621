import { useQuery } from "react-query";
import {
    ParamsGetLaporanLabaRugi,
    GetLaporanLabaRugiResponse,
} from "../../constants/types";
import axios from "../axios";
import qs from "query-string";

const handleRequest = async (params: ParamsGetLaporanLabaRugi) => {
    const endpoint = qs.stringifyUrl({
        // url: "api/laporanlabarugi",
        url: "api/profitloss",
        query: {
            ukmId: params.ukmId,
            tglAwal: params.tglAwal,
            tglAkhir: params.tglAkhir,
        },
    });
    const { data } = await axios.get<GetLaporanLabaRugiResponse>(endpoint);
    return data.data;
};

export default function useLaporanLabaRugi(params: ParamsGetLaporanLabaRugi) {
    return useQuery<GetLaporanLabaRugiResponse["data"]>(
        ["laporanLabaRugi", params],
        () => handleRequest(params),
        {
            enabled: !!params?.ukmId,
        },
    );
}
