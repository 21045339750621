// export const apiUrl =
//     process.env.REACT_APP_API_URL ?? "https://ulo-nk.noretest.com/";
export const apiUrl =
    process.env.REACT_APP_API_URL ?? "https://admin.ukm.noretest.com/";
export const tokenKey = "@token_key";
export const accessTokenKey = "@access_token_key";
export const captchaSiteKey =
    process.env.REACT_APP_CAPTCHA_SITE_KEY ??
    "6LccfyYgAAAAAJUkNlK5YI0htwVmSfnkVblaAwKZ";
export const captchaSiteKey2 =
    process.env.REACT_APP_CAPTCHA_SITE_KEY ??
    "6LdDAMUhAAAAAC4Vsf6jUSmM8MV_PbFOVBdxOrV0";

export const logoNore = "https://i.imgur.com/pmiSFwv.png";
export const imgPlaceholder = "https://i.imgur.com/Wu662Ir.jpg";

export const PEMILIK_TOKO = 10;
export const PEGAWAI_TOKO = 20;

export const font = "'Open Sans', sans-serif";
export const defaultColor = "#2B7351";

export const drawerWidth = 340;
