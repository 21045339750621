import { ArrowBackIosNew, Print } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import defaultAxios, { AxiosError } from "axios";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { IconContext } from "react-icons";
import { GiMoneyStack, GiWallet } from "react-icons/gi";
import Swal from "sweetalert2";
import {
    InputPembayaranResponse,
    IOptions,
    ParamsGetUkm,
    TipeStruk,
} from "../../constants/types";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import axios from "../../services/axios";
import useFindMetodePembayaran from "../../services/queries/useFindMetodePembayaran";
import useMetodePembayaran from "../../services/queries/useMetodePembayaran";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import toRibuan from "../../utils/toRibuan";

import { format } from "date-fns";
import id from "date-fns/locale/id";
import { useQueryClient } from "react-query";
import { textInverted, textPrimary } from "../../constants/colors";
import { useDebt } from "../../context/DebtContext";
import { BlobProvider } from "@react-pdf/renderer";
import StrukPiutangPdf from "../StrukPiutang/StrukPiutangPdf";
import useRiwayatPiutang from "../../services/queries/useRiwayatPiutang";

enum ModePembayaran {
    TUNAI = "tunai",
    TRANSFER = "transfer",
}

type ToggleKirimStruk = "email" | "whatsapp";

export default function ConfirmDebtPayment() {
    const {
        setPaymentMode,
        keteranganBeli,
        handleReset,
        handlingDonePembayaran,
        setSelectedDebt,
    } = useTransaksi();
    const {
        transaction,
        paymentAmount,
        amountPaid,
        paymentDate,
        extendDueDate,
        setConfirmDebtPayment,
        resetDebt,
    } = useDebt();

    const queryClient = useQueryClient();
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    let now = moment().format("DD MMM YYYY HH:mm");

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] = useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm } = useUkm(paramsUkm);

    const [printStruk, setPrintStruk] = useState(false);
    const [kirimEmail, setKirimEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [toggleModePembayaran, setToggleModePembayaran] = useState(
        ModePembayaran.TUNAI,
    );
    const [selectedMetode, setSelectedMetode] = useState<IOptions | null>(null);
    const [isOpenModalAlertPro, setIsOpenModalAlertPro] = useState(false);
    const [toggleKirimStruk, setToggleKirimStruk] =
        useState<ToggleKirimStruk>("whatsapp");
    const [tipeStruk, setTipeStruk] = useState<string | null>(
        localStorage.getItem("tipeStrukBayar") ?? TipeStruk.Mobile,
    );
    const [tipeStrukTagihan, setTipeStrukTagihan] = useState<string | null>(
        localStorage.getItem("tipeStrukInvoice") ?? TipeStruk.Mobile,
    );

    const handleTipeStruk = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStruk(newTipeStruk);
            localStorage.setItem("tipeStrukBayar", newTipeStruk);
        }
    };

    const handleTipeStrukTagihan = (
        _event: React.MouseEvent<HTMLElement>,
        newTipeStruk: TipeStruk | null,
    ) => {
        if (newTipeStruk !== null) {
            setTipeStrukTagihan(newTipeStruk);
            localStorage.setItem("tipeStrukInvoice", newTipeStruk);
        }
    };

    const pilihWhatsapp = () => {
        setToggleKirimStruk("whatsapp");
        setEmail("");
    };

    const pilihEmail = () => {
        setToggleKirimStruk("email");
        setEmail("");
    };

    const ukmId = useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const { data: metodePembayaran, isLoading: isLoadingMetodePembayaran } =
        useMetodePembayaran(ukmId);

    const { data: findMetodePembayaran } = useFindMetodePembayaran(
        Number(selectedMetode?.id),
    );

    const mappedMetodePembayaran = metodePembayaran?.map((li) => ({
        id: li.id,
        nama: li.namaMetode,
    }));

    useEffect(
        () => console.log("metodePembayaran", metodePembayaran),
        [metodePembayaran],
    );

    const pilihTunai = () => {
        setToggleModePembayaran(ModePembayaran.TUNAI);
    };

    const pilihTransfer = () => {
        setToggleModePembayaran(ModePembayaran.TRANSFER);
    };

    const current = new Date();
    current.getSeconds();

    const handlePrintStruk = (checked: boolean) => {
        if (checked) {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    };

    const handleKirimEmail = (checked: boolean) => {
        if (checked) {
            setEmail("");
            setKirimEmail(true);
        } else {
            setKirimEmail(false);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("printStruk") === "true") {
            setPrintStruk(true);
        } else {
            setPrintStruk(false);
        }
    }, []);

    const { data: debtHistory } = useRiwayatPiutang({
        ukmId: ukmIdUser && ukmIdUser[0],
        transaksiId: transaction?.id || 0,
    });

    const debtHistoryCount = debtHistory?.length || 0;

    const adjustDateToUTC = (date: Date | null | undefined): string | null => {
        if (!date) return null;
        const adjustedDate = new Date(date);
        adjustedDate.setHours(0, -adjustedDate.getTimezoneOffset(), 0, 0);
        return adjustedDate.toISOString();
    };

    const bayarPiutang = async () => {
        setIsButtonLoading(true);
        const body = {
            transaksiId: transaction?.id,
            nominal: paymentAmount,
            dibayar: amountPaid,
            kembalian: amountPaid - paymentAmount,
            metodeId: selectedMetode?.id,
            keterangan: `Pembayaran Piutang ke-${debtHistoryCount + 1}`,
            tanggal: paymentDate?.toISOString(),
            // jatuhTempo: extendDueDate?.toISOString(),
            jatuhTempo: adjustDateToUTC(extendDueDate),
            ukmId: ukmIdUser && ukmIdUser[0],
        };

        try {
            const { data } = await axios.post<InputPembayaranResponse>(
                "/api/pembayaran-transaksis",
                body,
            );

            if (data.code === 200) {
                if (kirimEmail && toggleKirimStruk === "whatsapp") {
                    window.open(
                        `https://wa.me/62${email}?text=Struk%20pembayaran%20ULO%20${data.data.nomorInvoice}%20${window.location.origin}/struk/${data.data.encrypt}`,
                        "_blank",
                    );
                }

                Swal.fire({
                    title: "Bayar piutang berhasil!",
                    text: "Pembayaran piutang telah berhasil",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });

                if (printStruk) {
                    localStorage.setItem("printStruk", "true");
                } else {
                    localStorage.removeItem("printStruk");
                }

                handleReset();
                handlingDonePembayaran();
                resetDebt();
                setPaymentMode(null);
                setIsButtonLoading(false);
                setSelectedDebt(null);
                queryClient.invalidateQueries("piutang");
            }
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    setConfirmDebtPayment(false);
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.message}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setPaymentMode(null);
                } else {
                    setConfirmDebtPayment(false);
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setPaymentMode(null);
                }
            }
        },
        [setConfirmDebtPayment, setPaymentMode],
    );

    useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    return (
        <>
            <Grid
                item
                pr={2}
                xs={12}
                md={6}
                sx={{
                    overflowY: "scroll",
                }}
            >
                <Stack direction="row" py={1} gap={1}>
                    <Button
                        size="small"
                        sx={{ padding: "0.25rem" }}
                        onClick={() => setConfirmDebtPayment(false)}
                    >
                        <ArrowBackIosNew />
                    </Button>
                    <Typography
                        py={1}
                        color={textPrimary.body}
                        fontSize={24}
                        fontWeight={600}
                    >
                        Detail Transaksi
                    </Typography>
                </Stack>
                <Box>
                    <Typography
                        color={textPrimary.title}
                        fontSize={24}
                        fontWeight={600}
                        mb={2}
                    >
                        Metode Pembayaran
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Button
                                fullWidth
                                variant={
                                    toggleModePembayaran ===
                                    ModePembayaran.TUNAI
                                        ? "contained"
                                        : "outlined"
                                }
                                onClick={pilihTunai}
                                sx={{ borderRadius: 4 }}
                            >
                                <Stack alignItems="center" p={3}>
                                    <IconContext.Provider
                                        value={{
                                            size: "56px",
                                        }}
                                    >
                                        <GiMoneyStack />
                                    </IconContext.Provider>
                                    Tunai
                                </Stack>
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack
                                component={ukmData?.isPro === 0 ? Link : Stack}
                                underline="none"
                                onClick={() => {
                                    if (ukmData?.isPro === 1) {
                                        return;
                                    } else {
                                        setIsOpenModalAlertPro(true);
                                    }
                                }}
                                sx={{
                                    cursor:
                                        ukmData?.isPro === 0
                                            ? "pointer"
                                            : undefined,
                                }}
                            >
                                <Button
                                    fullWidth
                                    variant={
                                        toggleModePembayaran ===
                                        ModePembayaran.TRANSFER
                                            ? "contained"
                                            : "outlined"
                                    }
                                    onClick={() => {
                                        if (ukmData?.isPro === 1) {
                                            pilihTransfer();
                                        }
                                    }}
                                    sx={{ borderRadius: 4 }}
                                >
                                    <Stack alignItems="center" p={3}>
                                        <IconContext.Provider
                                            value={{
                                                size: "56px",
                                            }}
                                        >
                                            <GiWallet />
                                        </IconContext.Provider>
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            gap={1}
                                        >
                                            Cashless{" "}
                                            {ukmData?.isPro === 1 ? null : (
                                                <Chip
                                                    label="PRO"
                                                    size="small"
                                                />
                                            )}
                                        </Box>
                                    </Stack>
                                </Button>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
                {toggleModePembayaran === ModePembayaran.TRANSFER &&
                metodePembayaran?.length !== 0 ? (
                    <Box mt={2}>
                        <Autocomplete
                            loading={isLoadingMetodePembayaran}
                            options={mappedMetodePembayaran ?? []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Pilih metode"
                                    variant="outlined"
                                />
                            )}
                            getOptionLabel={(option) => option.nama}
                            isOptionEqualToValue={(option, value) =>
                                String(option.id) === String(value.id)
                            }
                            size="small"
                            fullWidth
                            value={selectedMetode}
                            onChange={(_event, newMetode: IOptions | null) => {
                                setSelectedMetode(newMetode);
                            }}
                            sx={{ marginBottom: 2 }}
                        />
                        {findMetodePembayaran && (
                            <Stack alignItems="center">
                                {findMetodePembayaran.gambar && (
                                    <img
                                        src={findMetodePembayaran.gambar}
                                        alt="qrcode"
                                        width={250}
                                        height={250}
                                    />
                                )}
                                <Typography>
                                    a/n {findMetodePembayaran.namaPemegang}
                                </Typography>
                                <Typography fontWeight="bold">
                                    {findMetodePembayaran.noTujuan}
                                </Typography>
                                <Stack
                                    marginTop={2}
                                    direction="row"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Typography>Pilih tipe struk</Typography>
                                    <ToggleButtonGroup
                                        value={tipeStrukTagihan}
                                        exclusive
                                        size="small"
                                        onChange={handleTipeStrukTagihan}
                                    >
                                        <ToggleButton
                                            value={TipeStruk.Mobile}
                                            sx={{
                                                display: "flex",
                                                paddingX: 2,
                                            }}
                                        >
                                            <b>A8</b>
                                        </ToggleButton>
                                        <ToggleButton
                                            value={TipeStruk.PC}
                                            sx={{
                                                display: "flex",
                                                paddingX: 2,
                                            }}
                                        >
                                            <b>A4</b>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                {tipeStrukTagihan && (
                                    <BlobProvider
                                        document={
                                            <StrukPiutangPdf
                                                namaToko={String(
                                                    ukmData?.namaToko,
                                                )}
                                                alamatToko={String(
                                                    ukmData?.alamatToko,
                                                )}
                                                gambarToko={
                                                    ukmData?.gambarTokoBW ===
                                                    null
                                                        ? undefined
                                                        : ukmData?.gambarTokoBW
                                                }
                                                telpToko={String(
                                                    ukmData?.noTelp,
                                                )}
                                                tanggalTransaksi={now}
                                                kasir={transaction?.kasir || ""}
                                                sales={
                                                    transaction?.sales || null
                                                }
                                                // total={
                                                //     transaction?.totalHarga || 0
                                                // }
                                                total={paymentAmount}
                                                totalPiutang={
                                                    transaction?.totalPiutang ||
                                                    0
                                                }
                                                sisaPiutang={(transaction?.sisapiutang || 0) - paymentAmount}
                                                sudahBayar={(transaction?.totalPiutang || 0) - (transaction?.sisapiutang || 0)}
                                                // belumLunas={true}
                                                belumLunas={(transaction?.sisapiutang || 0) - paymentAmount !== 0}
                                                qr={findMetodePembayaran.gambar}
                                                isPro={Number(ukmData?.isPro)}
                                                namaMetode={
                                                    selectedMetode &&
                                                    toggleModePembayaran ===
                                                        ModePembayaran.TRANSFER
                                                        ? selectedMetode.nama
                                                        : "Tunai"
                                                }
                                                keterangan={keteranganBeli}
                                                noMetode={
                                                    findMetodePembayaran.noTujuan
                                                }
                                            />
                                        }
                                    >
                                        {({ blob, url, loading, error }) => {
                                            return (
                                                <LoadingButton
                                                    variant="outlined"
                                                    loading={loading}
                                                    loadingPosition="center"
                                                    startIcon={<Print />}
                                                    href={url ?? ""}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    sx={{
                                                        marginTop: 2,
                                                    }}
                                                >
                                                    Cetak Tagihan
                                                </LoadingButton>
                                            );
                                        }}
                                    </BlobProvider>
                                )}
                            </Stack>
                        )}
                    </Box>
                ) : (
                    toggleModePembayaran === ModePembayaran.TRANSFER &&
                    metodePembayaran?.length === 0 && (
                        <Box
                            display="flex"
                            flexDirection="column"
                            justifyContent="flex-end"
                            height="50%"
                            paddingX={isPhoneScreen ? undefined : 2}
                        >
                            <Typography textAlign="center">
                                Tambahkan Bank Transfer, e-Money, QRIS, dan
                                lain-lain sebagai metode alternatif transaksi
                                UKM Anda.
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={4}
                            >
                                <Typography variant="body2" textAlign="center">
                                    Metode lain dapat diatur di
                                    juragan.ukmlokal.com
                                </Typography>
                            </Box>
                            <Box
                                display="flex"
                                justifyContent="center"
                                marginTop={2}
                            >
                                <Button
                                    variant="outlined"
                                    href={
                                        process.env.NODE_ENV === "production"
                                            ? `https://juragan.ukmlokal.com/ukm/metode-pembayaran`
                                            : `http://localhost:3000/ukm/metode-pembayaran`
                                    }
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Ke Juragan
                                </Button>
                            </Box>
                        </Box>
                    )
                )}
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    overflowY: "scroll",
                }}
            >
                <Box
                    display="grid"
                    gridTemplateRows="auto 1fr auto"
                    minHeight="100dvh"
                >
                    <Stack
                        direction="row"
                        paddingLeft={3}
                        paddingRight={4}
                        paddingY={2}
                        borderBottom="1px dashed #d4d4d4"
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Typography color={textPrimary.light}>
                                    Tanggal
                                </Typography>
                                <Typography color={textPrimary.body}>
                                    {format(
                                        paymentDate ?? new Date(),
                                        "dd MMMM yyyy",
                                        {
                                            locale: id,
                                        },
                                    )}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography color={textPrimary.light}>
                                    Nama Kasir
                                </Typography>
                                <Typography color={textPrimary.body}>
                                    {transaction?.kasir ?? "-"}
                                </Typography>
                            </Grid>

                            <Grid item xs={4}>
                                <Typography color={textPrimary.light}>
                                    Nama Sales
                                </Typography>
                                <Typography color={textPrimary.body}>
                                    {transaction?.sales ?? "-"}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>

                    <DialogContent
                        sx={{
                            paddingBottom: 2,
                        }}
                    >
                        <Box
                            marginY={2}
                            padding={2}
                            border={1}
                            borderColor="#d4d4d4"
                            borderRadius={2}
                        >
                            <Typography
                                marginBottom={1}
                                color={textPrimary.title}
                                fontSize={20}
                                fontWeight={500}
                            >
                                Ringkasan Pembayaran
                            </Typography>
                            <Stack gap={1}>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography color={textPrimary.body}>
                                        Nominal Pembayaran Piutang
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns="3ch 1fr"
                                        px={1}
                                        minWidth="18ch"
                                    >
                                        <Typography
                                            color={textPrimary.title}
                                            fontSize={20}
                                            fontWeight={500}
                                        >
                                            Rp.
                                        </Typography>
                                        <Typography
                                            color={textPrimary.title}
                                            fontSize={20}
                                            fontWeight={500}
                                            textAlign="right"
                                        >
                                            {toRibuan(paymentAmount)}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography color={textPrimary.body}>
                                        Uang yang Dibayar
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns="3ch 1fr"
                                        minWidth="18ch"
                                        px={1}
                                        bgcolor={textInverted.body}
                                    >
                                        <Typography
                                            color={textPrimary.title}
                                            fontSize={20}
                                            fontWeight={500}
                                        >
                                            Rp.
                                        </Typography>
                                        <Typography
                                            color="#0a5bbb"
                                            fontSize={20}
                                            fontWeight={500}
                                            textAlign="right"
                                        >
                                            {toRibuan(amountPaid)}
                                        </Typography>
                                    </Box>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                >
                                    <Typography color={textPrimary.body}>
                                        Kembalian
                                    </Typography>
                                    <Box
                                        display="grid"
                                        gridTemplateColumns="3ch 1fr"
                                        px={1}
                                        minWidth="18ch"
                                    >
                                        <Typography
                                            color={textPrimary.title}
                                            fontSize={20}
                                            fontWeight={500}
                                        >
                                            Rp.
                                        </Typography>
                                        <Typography
                                            color="#119c5b"
                                            fontSize={20}
                                            fontWeight={500}
                                            textAlign="right"
                                        >
                                            {toRibuan(
                                                amountPaid - paymentAmount,
                                            )}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Stack>
                            <Divider
                                sx={{ borderStyle: "dashed", paddingTop: 1 }}
                            />
                            <Box mt={1}>
                                <Typography color={textPrimary.body}>
                                    Keterangan:
                                </Typography>
                                <Typography color={textPrimary.body}>
                                    {keteranganBeli ?? "-"}
                                </Typography>
                            </Box>
                        </Box>
                    </DialogContent>
                    <Grid
                        container
                        justifyContent="flex-end"
                        display={fullScreen ? "none" : undefined}
                    >
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <Box
                                paddingTop={3}
                                paddingLeft={3}
                                display="flex"
                                justifyContent="flex-start"
                                borderTop={1}
                                borderColor="#d4d4d4"
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={printStruk}
                                            size={
                                                isTabletScreen
                                                    ? "small"
                                                    : undefined
                                            }
                                            onChange={(_, checked) => {
                                                handlePrintStruk(checked);
                                            }}
                                        />
                                    }
                                    label="Print struk"
                                    componentsProps={{
                                        typography: {
                                            fontSize: isTabletScreen
                                                ? "12px"
                                                : undefined,
                                        },
                                    }}
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                                {printStruk && (
                                    <ToggleButtonGroup
                                        value={tipeStruk}
                                        exclusive
                                        size="small"
                                        onChange={handleTipeStruk}
                                    >
                                        <ToggleButton
                                            value={TipeStruk.Mobile}
                                            sx={{
                                                display: "flex",
                                                paddingX: 2,
                                            }}
                                        >
                                            <b>A8</b>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                )}
                            </Box>
                            <Box paddingLeft={3} display="flex">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={kirimEmail}
                                            size={
                                                isTabletScreen
                                                    ? "small"
                                                    : undefined
                                            }
                                            onChange={(_, checked) => {
                                                handleKirimEmail(checked);
                                            }}
                                        />
                                    }
                                    label="Kirim struk"
                                    componentsProps={{
                                        typography: {
                                            fontSize: isTabletScreen
                                                ? "12px"
                                                : undefined,
                                        },
                                    }}
                                    sx={{
                                        height: "fit-content",
                                    }}
                                />
                            </Box>
                            {kirimEmail && (
                                <Box paddingX={3}>
                                    <Grid container>
                                        <Grid item xs={12} sm={6}>
                                            <Stack
                                                display="flex"
                                                direction="row"
                                                spacing={1}
                                                height="100%"
                                                sx={{
                                                    marginRight: 2,
                                                }}
                                            >
                                                <Button
                                                    disabled={
                                                        user &&
                                                        !user.akses.transaksi.includes(
                                                            12,
                                                        )
                                                    }
                                                    size={
                                                        isTabletScreen
                                                            ? "small"
                                                            : "medium"
                                                    }
                                                    fullWidth
                                                    variant={
                                                        toggleKirimStruk ===
                                                        "whatsapp"
                                                            ? "contained"
                                                            : "outlined"
                                                    }
                                                    onClick={() =>
                                                        pilihWhatsapp()
                                                    }
                                                >
                                                    Whatsapp
                                                </Button>
                                                <Button
                                                    disabled={
                                                        user &&
                                                        !user.akses.transaksi.includes(
                                                            12,
                                                        )
                                                    }
                                                    size={
                                                        isTabletScreen
                                                            ? "small"
                                                            : "medium"
                                                    }
                                                    fullWidth
                                                    variant={
                                                        toggleKirimStruk ===
                                                        "email"
                                                            ? "contained"
                                                            : "outlined"
                                                    }
                                                    onClick={() => pilihEmail()}
                                                >
                                                    Email
                                                </Button>
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                value={email}
                                                type="email"
                                                fullWidth
                                                sx={{
                                                    maxWidth: 300,
                                                }}
                                                placeholder={
                                                    toggleKirimStruk === "email"
                                                        ? "Contoh: budi93@gmail.com"
                                                        : "8316352725"
                                                }
                                                InputProps={{
                                                    startAdornment:
                                                        toggleKirimStruk ===
                                                            "whatsapp" && (
                                                            <InputAdornment position="start">
                                                                +62
                                                            </InputAdornment>
                                                        ),
                                                }}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) =>
                                                    setEmail(event.target.value)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                            <Box
                                marginTop={isTabletScreen ? 1 : 2}
                                marginLeft={3}
                                marginRight={3}
                                marginBottom={isTabletScreen ? 2 : 4}
                            >
                                <Stack
                                    direction="column"
                                    display="flex"
                                    justifyContent="space-between"
                                    marginBottom={1}
                                    spacing={1}
                                >
                                    {printStruk ? (
                                        <BlobProvider
                                            document={
                                                <StrukPiutangPdf
                                                    namaToko={String(
                                                        ukmData?.namaToko,
                                                    )}
                                                    alamatToko={String(
                                                        ukmData?.alamatToko,
                                                    )}
                                                    gambarToko={
                                                        ukmData?.gambarTokoBW ===
                                                        null
                                                            ? undefined
                                                            : ukmData?.gambarTokoBW
                                                    }
                                                    telpToko={String(
                                                        ukmData?.noTelp,
                                                    )}
                                                    tanggalTransaksi={now}
                                                    kasir={
                                                        transaction?.kasir || ""
                                                    }
                                                    sales={
                                                        transaction?.sales ||
                                                        null
                                                    }
                                                    totalPiutang={
                                                        transaction?.totalPiutang ||
                                                        0
                                                    }
                                                    sisaPiutang={(transaction?.sisapiutang || 0) - paymentAmount}
                                                    belumLunas={(transaction?.sisapiutang || 0) - paymentAmount !== 0}
                                                    sudahBayar={(transaction?.totalPiutang || 0) - (transaction?.sisapiutang || 0)}
                                                    total={paymentAmount}
                                                    tunai={Number(amountPaid)}
                                                    kembalian={
                                                        amountPaid -
                                                        paymentAmount
                                                    }
                                                    isPro={Number(
                                                        ukmData?.isPro,
                                                    )}
                                                    namaMetode={
                                                        selectedMetode &&
                                                        toggleModePembayaran ===
                                                            ModePembayaran.TRANSFER
                                                            ? selectedMetode.nama
                                                            : "Tunai"
                                                    }
                                                    keterangan={keteranganBeli}
                                                    noMetode={
                                                        findMetodePembayaran?.noTujuan ??
                                                        null
                                                    }
                                                />
                                            }
                                        >
                                            {({
                                                blob,
                                                url,
                                                loading,
                                                error,
                                            }) => {
                                                return (
                                                    <LoadingButton
                                                        disabled={
                                                            toggleModePembayaran ===
                                                                ModePembayaran.TRANSFER &&
                                                            selectedMetode ===
                                                                null
                                                        }
                                                        loading={
                                                            loading ||
                                                            isButtonLoading
                                                        }
                                                        loadingPosition="center"
                                                        variant="contained"
                                                        fullWidth
                                                        size={
                                                            isTabletScreen
                                                                ? "medium"
                                                                : "large"
                                                        }
                                                        onClick={() => {
                                                            if (blob) {
                                                                const blobFile =
                                                                    new File(
                                                                        [blob],
                                                                        `Invoice_${ukmData?.namaToko}_${now}.pdf`,
                                                                        {
                                                                            type: "application/pdf",
                                                                        },
                                                                    );
                                                                const invoiceUrl =
                                                                    window.URL.createObjectURL(
                                                                        blobFile,
                                                                    );
                                                                window.open(
                                                                    invoiceUrl,
                                                                );
                                                                bayarPiutang();
                                                            }
                                                        }}
                                                    >
                                                        Bayar
                                                    </LoadingButton>
                                                );
                                            }}
                                        </BlobProvider>
                                    ) : (
                                        <LoadingButton
                                            disabled={
                                                toggleModePembayaran ===
                                                    ModePembayaran.TRANSFER &&
                                                selectedMetode === null
                                            }
                                            loading={isButtonLoading}
                                            loadingPosition="center"
                                            variant="contained"
                                            fullWidth
                                            size={
                                                isTabletScreen
                                                    ? "medium"
                                                    : "large"
                                            }
                                            onClick={bayarPiutang}
                                        >
                                            Bayar
                                        </LoadingButton>
                                    )}
                                </Stack>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    );
}
