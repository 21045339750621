import {
    Card,
    CardContent,
    CardActionArea,
    Box,
    Avatar,
    Typography,
    Badge,
} from "@mui/material";
import React from "react";
import { DetailBundling } from "../../constants/types";
import avatarAlt from "../../utils/avatarAlt";
import toRupiah from "../../utils/toRupiah";
import StarIcon from "@mui/icons-material/Star";
import { useTransaksi } from "../../context/transaksiContext";
import toRibuan from "../../utils/toRibuan";
import truncate from "../../utils/truncate";
import { textPrimary } from "../../constants/colors";

interface IMenuBarangProps {
    id: number;
    namaBarang: string;
    namaKategori: string;
    deskripsi?: string;
    harga: number;
    url: string;
    sku: string;
    jumlahStok: number;
    detailBundling: DetailBundling[] | [];
    favorite: number;
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
    keteranganItem: string | null;
}

const MenuBarang = ({
    id,
    namaBarang,
    namaKategori,
    deskripsi,
    harga,
    url,
    sku,
    jumlahStok,
    detailBundling,
    favorite,
    hpp,
    multipleHarga,
    keteranganItem,
}: IMenuBarangProps) => {
    const { addToCart, cart } = useTransaksi();

    return (
        <Card
            variant="outlined"
            sx={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                backgroundColor:
                    (jumlahStok <= 0 && jumlahStok !== null) ||
                    !detailBundling.every(
                        (item) => item.stok === null || item.stok > 0,
                    )
                        ? "#e9f2f0"
                        : undefined,
                color: textPrimary.body,
                borderColor:
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok < 0 && jumlahStok !== null)
                        ? "primary.main"
                        : undefined,
                borderWidth:
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok < 0 && jumlahStok !== null)
                        ? "medium"
                        : undefined,
            }}
        >
            <CardActionArea
                disabled={
                    cart.findIndex((c) => c.id === id) >= 0 ||
                    (jumlahStok <= 0 && jumlahStok !== null) ||
                    !detailBundling.every(
                        (item) => item.stok === null || item.stok > 0,
                    )
                }
                onClick={() => {
                    addToCart({
                        id,
                        namaBarang,
                        namaKategori,
                        harga,
                        url,
                        qty: 1,
                        sku,
                        jumlahStok,
                        detailBundling,
                        hpp,
                        faktur: [],
                        multipleHarga,
                        keteranganItem,
                    });
                }}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    gap: "1rem",
                    height: "100%",
                }}
            >
                <CardContent sx={{ paddingBottom: 0, width: "100%" }}>
                    <Box display="flex" justifyContent="center">
                        <Badge
                            overlap="circular"
                            badgeContent={
                                favorite > 0 ? (
                                    <Box
                                        sx={{
                                            width: 32,
                                            height: 32,
                                            position: "relative",
                                            backgroundColor: "#fff",
                                            borderRadius: "50%",
                                        }}
                                    >
                                        <Box>
                                            <StarIcon
                                                htmlColor="#fbbf24"
                                                sx={{
                                                    fontSize: "1.3rem",
                                                    position: "absolute",
                                                    top: "50%",
                                                    left: "50%",
                                                    transform:
                                                        "translate(-50%, -50%)",
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                ) : undefined
                            }
                            sx={{ marginY: 3 }}
                        >
                            <Avatar
                                alt={namaBarang}
                                src={url === null ? "" : url}
                                sx={{
                                    bgcolor: "primary.main",
                                    width: 70,
                                    height: 70,
                                }}
                            >
                                {avatarAlt(namaBarang)}
                            </Avatar>
                        </Badge>
                    </Box>
                    <Typography
                        fontSize={12}
                        color={
                            (jumlahStok < 5 && jumlahStok !== null) ||
                            !detailBundling.every(
                                (item) => item.stok === null || item.stok > 0,
                            )
                                ? "#ef4444"
                                : textPrimary.light
                        }
                    >
                        {detailBundling.length > 0
                            ? detailBundling.every(
                                  (item) => item.stok === null || item.stok > 0,
                              )
                                ? "Otomatis"
                                : "Ada barang yang habis"
                            : jumlahStok === null
                            ? "Tanpa stok"
                            : jumlahStok > 5
                            ? "Tersedia " + toRibuan(jumlahStok) + " barang"
                            : jumlahStok <= 5 && jumlahStok > 0
                            ? "Barang tersisa " + toRibuan(jumlahStok)
                            : "Barang telah habis"}
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" fontSize="16px">
                        {namaBarang}
                    </Typography>
                    {deskripsi ? (
                        <Typography
                            variant="body2"
                            fontSize="12px"
                            color="#76747C"
                        >
                            {truncate(deskripsi, 50)}
                        </Typography>
                    ) : undefined}
                </CardContent>
                <Box display="flex" flexDirection="column" marginBottom={2}>
                    {multipleHarga.length > 0 ? (
                        <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            gap={0.5}
                        >
                            <Typography
                                variant="h6"
                                fontWeight="bold"
                                fontSize="18px"
                                textAlign="center"
                            >
                                {toRupiah(Number(multipleHarga[0].harga))}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            fontSize="18px"
                            textAlign="center"
                            sx={{
                                marginBottom: 2,
                            }}
                        >
                            {toRupiah(harga)}
                        </Typography>
                    )}
                </Box>
            </CardActionArea>
        </Card>
    );
};

export default MenuBarang;
