import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import PrintBarcode from "../PrintBarcode/PrintBarcode";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { pdf } from "@react-pdf/renderer";
import { LoadingButton } from "@mui/lab";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { PrintMode } from "../../constants/types";

interface IModalPrintBarcodeProps {
    isOpenModalPrintBarcode: boolean;
    sku: string;
    closeModalPrintBarcode: () => void;
    namaBarang: string;
    hargaBarang: string;
}

const ModalPrintBarcode = ({
    isOpenModalPrintBarcode,
    sku,
    closeModalPrintBarcode,
    namaBarang,
    hargaBarang,
}: IModalPrintBarcodeProps) => {
    var Barcode = require("react-barcode");
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const [jumlahCetak, setJumlahCetak] = useState<string>("1");
    const [printMode, setPrintMode] = useState<PrintMode>(PrintMode.A4);
    const [isLoadingButton, setIsLoadingButton] = useState<boolean>(false);

    const handlingPrintBarcode = () => {
        setIsLoadingButton(true);
        var obj = pdf(
            <PrintBarcode
                jumlahCetak={
                    printMode === PrintMode.LABEL33X15 ? 3 : Number(jumlahCetak)
                }
                valueBarcode={sku ?? ""}
                namaBarang={String(namaBarang)}
                hargaBarang={String(hargaBarang)}
                sku={String(sku)}
                printMode={printMode}
            />,
        ).toBlob();
        obj.then(function (blob) {
            var url = URL.createObjectURL(blob);
            window.open(url, "_blank");
            setIsLoadingButton(false);
            closeModalPrintBarcode();
            setJumlahCetak("1");
        });
    };

    return (
        <>
            <Dialog
                maxWidth="sm"
                fullScreen={isPhoneScreen}
                fullWidth={true}
                open={isOpenModalPrintBarcode}
                onClose={() => {
                    closeModalPrintBarcode();
                    setJumlahCetak("1");
                }}
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <IconButton
                            onClick={() => {
                                closeModalPrintBarcode();
                                setJumlahCetak("1");
                            }}
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h6" fontWeight="bold">
                            Cetak Barcode
                        </Typography>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        alignItems="center"
                        marginBottom={2}
                        paddingY={1}
                    >
                        <Typography>Mode Print Barcode</Typography>
                        <FormControl fullWidth sx={{ marginTop: "10px" }}>
                            <Select
                                labelId="print-mode-select-label"
                                id="print-mode-select"
                                value={printMode}
                                onChange={(e) =>
                                    setPrintMode(e.target.value as PrintMode)
                                }
                            >
                                <MenuItem value={PrintMode.A4}>
                                    Kertas A4
                                </MenuItem>
                                <MenuItem value={PrintMode.LABEL109}>
                                    Label no. 109
                                </MenuItem>
                                <MenuItem value={PrintMode.LABEL33X15}>
                                    Label 33mm x 15mm 3LN
                                </MenuItem>
                                <MenuItem value={PrintMode.LABEL50X20}>
                                    Kertas 50mm x 20mm
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Typography variant="body2" color="#737373">
                        Anda dapat melakukan cetak barcode berdasarkan SKU
                        barang.
                    </Typography>
                    <Stack direction="column" spacing={0.4} marginBottom={2}>
                        <Typography variant="body2" color="#737373">
                            Pastikan{" "}
                            {printMode === PrintMode.A4 ||
                            printMode === PrintMode.LABEL50X20
                                ? "kertas"
                                : "label"}{" "}
                            yang digunakan untuk mencetak adalah{" "}
                            <b>
                                {printMode === PrintMode.LABEL109
                                    ? "Label no. 109"
                                    : printMode === PrintMode.LABEL50X20
                                    ? "Kertas 50mm x 20mm"
                                    : printMode === PrintMode.LABEL33X15
                                    ? "Label 33mm x 15mm 3LN"
                                    : "Kertas A4"}
                            </b>
                            .
                        </Typography>
                    </Stack>
                    {printMode === PrintMode.LABEL109 ? (
                        <Card
                            variant="outlined"
                            sx={{ padding: 1, width: "fit-content" }}
                        >
                            <Stack
                                direction="column"
                                spacing={0}
                                alignItems="center"
                            >
                                <Barcode
                                    value={sku ?? ""}
                                    height={70}
                                    fontSize={16}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                marginTop="-4px"
                                paddingX={1}
                            >
                                <Typography fontFamily="monospace">
                                    {namaBarang?.toUpperCase()}
                                </Typography>
                                <Typography fontFamily="monospace">
                                    {hargaBarang}
                                </Typography>
                            </Stack>
                        </Card>
                    ) : printMode === PrintMode.LABEL33X15 ? (
                        <Card
                            variant="outlined"
                            sx={{ padding: 1, width: "fit-content" }}
                        >
                            <Stack
                                direction="column"
                                spacing={0}
                                alignItems="center"
                            >
                                <Barcode
                                    value={sku ?? ""}
                                    height={70}
                                    fontSize={16}
                                />
                            </Stack>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                marginTop="-4px"
                                paddingX={1}
                            >
                                <Typography fontFamily="monospace">
                                    {namaBarang?.toUpperCase()}
                                </Typography>
                                <Typography fontFamily="monospace">
                                    {hargaBarang}
                                </Typography>
                            </Stack>
                        </Card>
                    ) : printMode === PrintMode.LABEL50X20 ? (
                        <Card
                            variant="outlined"
                            sx={{
                                padding: 1,
                                width: "fit-content",
                                transform: "rotate(90deg)",
                                margin: "100px -40px",
                            }}
                        >
                            <Stack
                                direction="column"
                                spacing={0}
                                alignItems="center"
                            >
                                <Barcode
                                    value={sku ?? ""}
                                    width={2}
                                    height={50}
                                    fontSize={12}
                                    displayValue={false}
                                />
                                <Typography
                                    fontSize="12px"
                                    fontWeight="bold"
                                    fontFamily="monospace"
                                    marginTop="2px"
                                >
                                    {namaBarang?.toUpperCase()}
                                </Typography>

                                <Typography fontFamily="monospace">
                                    {hargaBarang}
                                </Typography>
                            </Stack>
                        </Card>
                    ) : (
                        <Card
                            variant="outlined"
                            sx={{ padding: 1, width: "fit-content" }}
                        >
                            <Stack
                                direction="column"
                                spacing={0}
                                alignItems="center"
                            >
                                <Barcode
                                    value={sku ?? ""}
                                    height={70}
                                    fontSize={16}
                                />
                                <Typography
                                    fontSize="25px"
                                    fontWeight="bold"
                                    fontFamily="monospace"
                                    marginTop="-10px"
                                >
                                    {hargaBarang}
                                </Typography>
                                <Typography fontFamily="monospace">
                                    {namaBarang?.toUpperCase()}
                                </Typography>
                            </Stack>
                        </Card>
                    )}
                    {printMode === PrintMode.LABEL33X15 ? null : (
                        <Grid item marginTop={2} lg={9}>
                            <Typography variant="body2" color="#737373">
                                Jumlah yang ingin dicetak (maks.{" "}
                                {printMode === PrintMode.LABEL109
                                    ? "55"
                                    : "150"}
                                )
                            </Typography>
                            <TextField
                                placeholder="Masukkan jumlah yang ingin dicetak"
                                fullWidth
                                value={jumlahCetak}
                                type="number"
                                onWheel={(e) => {
                                    const target =
                                        e.target as HTMLButtonElement;
                                    if (target) {
                                        target.blur();
                                        e.stopPropagation();
                                        setTimeout(() => {
                                            target.focus();
                                        }, 0);
                                    }
                                }}
                                onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                }
                                onChange={(e) => setJumlahCetak(e.target.value)}
                            />
                            {printMode === PrintMode.LABEL109 ? (
                                Number(jumlahCetak) > 55 ? (
                                    <Typography
                                        marginTop={1}
                                        variant="body2"
                                        color="#dc2626"
                                        fontWeight={600}
                                    >
                                        Jumlah cetak melebihi maksimal
                                    </Typography>
                                ) : null
                            ) : (
                                Number(jumlahCetak) > 150 && (
                                    <Typography
                                        marginTop={1}
                                        variant="body2"
                                        color="#dc2626"
                                        fontWeight={600}
                                    >
                                        Jumlah cetak melebihi maksimal
                                    </Typography>
                                )
                            )}
                        </Grid>
                    )}
                    <LoadingButton
                        loading={isLoadingButton}
                        loadingPosition="center"
                        variant="contained"
                        fullWidth={isPhoneScreen}
                        onClick={() => handlingPrintBarcode()}
                        disabled={
                            (printMode === PrintMode.LABEL109
                                ? Number(jumlahCetak) > 55
                                : printMode === PrintMode.LABEL33X15
                                ? Number(jumlahCetak) > 3
                                : Number(jumlahCetak) > 150) ||
                            Number(jumlahCetak) <= 0
                        }
                        sx={{ marginTop: 6 }}
                    >
                        Cetak
                    </LoadingButton>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ModalPrintBarcode;
