import {
    Dialog,
    DialogTitle,
    Stack,
    IconButton,
    Typography,
    DialogContent,
    Box,
    Grid,
    TextField,
    useMediaQuery,
    Button,
    InputAdornment,
    FormControl,
    FormHelperText,
    MenuItem,
    Select,
    Autocomplete,
    Divider,
    AppBar,
    Toolbar,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "../../services/axios";
import { LoadingButton } from "@mui/lab";
import { useUkmModal } from "../../context/ukmModalContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { EditUkmResponse, ModalUkmField } from "../../constants/types";
import { SketchPicker } from "react-color";
import { defaultColor } from "../../constants/common";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import useProvinsi from "../../services/queries/useProvinsi";
import useKota from "../../services/queries/useKota";
import { Close, Delete, Menu } from "@mui/icons-material";
import { GiMoneyStack, GiWallet } from "react-icons/gi";
import { IconContext } from "react-icons";
import PayIcon from "../PayIcon";
import { textPrimary } from "../../constants/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { CheckBox } from "@mui/icons-material";
import Icon from "@mdi/react";
import { mdiDelete } from "@mdi/js";
import { mdiCashRegister } from "@mdi/js";

interface IModalUkmProps {
    refetchUkm: () => void;
    refetchUser: () => void;
}

const schema = yup.object({
    namaToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Nama terlalu panjang"),
    noTelp: yup
        .string()
        .required("Kolom wajib diisi")
        .matches(/^8[1-9][0-9]/, "No. Telepon tidak valid, contoh: 831xxxx")
        .min(9, "No. Telepon terlalu pendek")
        .max(13, "No. Telepon terlalu panjang"),
    alamatToko: yup
        .string()
        .required("Kolom wajib diisi")
        .max(500, "Alamat terlalu panjang"),
    gambarToko: yup
        .mixed()
        .test("fileType", "Format gambar tidak valid (hanya JPG, JPEG, PNG)", (value) => {
            if (!value) return true;
            return ["image/jpg", "image/jpeg", "image/png"].includes(value.type);
        })
        .test("fileSize", "Ukuran gambar harus kurang dari 2MB", (value) => {
            if (!value) return true;
            return value.size <= 2097152;
        }),
    deskripsi: yup.string().max(100, "Deskripsi terlalu panjang"),
    besarUsaha: yup.string().required("Kolom wajib diisi"),
    jenisUsaha: yup
        .string()
        .required("Kolom wajib diisi")
        .max(50, "Jenis usaha terlalu panjang"),
    omset: yup.string().required("Kolom wajib diisi"),
    provinsi: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            nama: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
    kota: yup
        .object()
        .shape({
            id: yup.string().required("Kolom wajib diisi"),
            nama: yup.string().required("Kolom wajib diisi"),
            provinsiId: yup.string().required("Kolom wajib diisi"),
            provinsiNama: yup.string().required("Kolom wajib diisi"),
        })
        .required("Kolom wajib diisi"),
});

const ModalUkm = ({ refetchUkm, refetchUser }: IModalUkmProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.up("md"));
    const { isOpenModalUkm, closeModalUkm, dataUkm } = useUkmModal();
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [cashlessPreview, setCashlessPreview] = useState(true);

    const { data: provinsiOptions, isLoading: isLoadingProvinsi } =
        useProvinsi();

    const [paramsKota, setParamsKota] = useState<number>();
    const { data: kotaOptions, isLoading: isLoadingKota } = useKota(paramsKota);

    const initialValues: ModalUkmField = useMemo(
        () => ({
            id: undefined,
            namaToko: "",
            noTelp: "",
            alamatToko: "",
            warna: defaultColor,
            gambarToko: null,
            deskripsi: "",
            besarUsaha: "",
            jenisUsaha: "",
            omset: "",
        }),
        [],
    );

    const {
        handleSubmit,
        control,
        formState: { errors },
        setValue,
        reset,
        setError,
        clearErrors,
        watch,
    } = useForm<ModalUkmField>({
        resolver: yupResolver(schema),
        defaultValues: initialValues,
    });

    const watchNamaToko = watch("namaToko");
    const watchAlamat = watch("alamatToko");
    const watchJenisUsaha = watch("jenisUsaha");
    const watchBesarUsaha = watch("besarUsaha");
    const watchOmset = watch("omset");

    const onSubmit = async (values: ModalUkmField) => {
        setIsButtonLoading(true);
        const formattedOmzet = values.omset.replace(/\./g, "");
        const formattedNoTelp = "62" + values.noTelp;
        if (dataUkm) {
            try {
                const formData = new FormData();
                formData.append("namaToko", values.namaToko);
                formData.append("alamat", values.alamatToko);
                formData.append("no_Telp", formattedNoTelp);
                formData.append("warna", values.warna);
                formData.append("deskripsi", values.deskripsi ?? "");
                formData.append("omset", formattedOmzet);
                formData.append("besarUsaha", values.besarUsaha);
                formData.append("jenisUsaha", values.jenisUsaha);
                if (values.gambarToko) {
                    formData.append(
                        "gambar",
                        values.gambarToko ? (values.gambarToko as any) : "",
                    );
                }
                if (values.provinsi?.id) {
                    formData.append("provinsi", String(values.provinsi.id));
                }
                if (values.kota?.id) {
                    formData.append("kota", String(values.kota.id));
                }
                formData.append("_method", "put");

                const { data } = await axios.post<EditUkmResponse>(
                    `/api/ukm/${values.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );
                if (data.code === 200) {
                    Swal.fire({
                        title: data.message ?? "Informasi ukm telah diubah",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    window.location.reload();
                    refetchUkm();
                    refetchUser();
                }
                setIsButtonLoading(false);
            } catch (error) {
                setIsButtonLoading(false);
                console.error(error);
            }
        } else {
            try {
                const { data } = await axios.post(`/api/item`, {});
                if (data.code === 200) {
                    Swal.fire({
                        title: "UKM " + values.namaToko + " telah ditambahkan",
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    closeModalUkm();
                }
                setIsButtonLoading(false);
                console.log(data);
            } catch (error) {
                setIsButtonLoading(false);
                console.error(error);
            }
        }
    };

    useEffect(() => {
        if (isOpenModalUkm) {
            if (dataUkm) {
                reset({
                    ...dataUkm,
                    alamatToko:
                        dataUkm.alamatToko === "-" || !!!dataUkm.alamatToko
                            ? ""
                            : dataUkm.alamatToko,
                    besarUsaha: dataUkm.besarUsaha ?? "",
                    jenisUsaha: dataUkm.jenisUsaha ?? "",
                    omset:
                        dataUkm.omset === "null" || !!!dataUkm.omset
                            ? ""
                            : dataUkm.omset,
                });
                console.log(dataUkm);
            } else {
                reset(initialValues);
            }
        }
    }, [initialValues, isOpenModalUkm, dataUkm, reset]);

    return (
        <Dialog
            maxWidth="md"
            fullScreen={isPhoneScreen}
            fullWidth
            open={isOpenModalUkm}
            onClose={closeModalUkm}
            scroll="body"
            PaperProps={{
                sx: {
                    borderRadius: 1,
                },
            }}
        >
            <DialogTitle>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    gap={1}
                >
                    <Typography fontSize={32} fontWeight="bold">
                        {dataUkm ? "Ubah Detail UKM" : "Tambah UKM"}
                    </Typography>
                    <IconButton onClick={closeModalUkm}>
                        <Close fontSize="small" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Fragment>
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Typography
                                variant="body2"
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Nama UKM
                            </Typography>
                            <Controller
                                name="namaToko"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        id="namaToko"
                                        autoFocus
                                        placeholder="Contoh: UKM Budi Jaya"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaToko)}
                                        helperText={
                                            errors.namaToko
                                                ? errors.namaToko.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                variant="body2"
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                No. Telepon
                            </Typography>
                            <Controller
                                name="noTelp"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="noTelp"
                                        placeholder="831xxxxxx"
                                        type="number"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.noTelp)}
                                        onWheel={(e) => {
                                            const target =
                                                e.target as HTMLButtonElement;
                                            if (target) {
                                                target.blur();
                                                e.stopPropagation();
                                                setTimeout(
                                                    () => {
                                                        target.focus();
                                                    },
                                                    0,
                                                );
                                            }
                                        }}
                                        onKeyDown={(evt) =>
                                            [
                                                "e",
                                                "E",
                                                "+",
                                                "-",
                                            ].includes(
                                                evt.key,
                                            ) &&
                                            evt.preventDefault()
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{
                                                        marginTop: "-2px",
                                                        color: "#000",
                                                    }}
                                                >
                                                    <Typography>+62</Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                        helperText={
                                            errors.noTelp
                                                ? errors.noTelp.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                variant="body2"
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Provinsi
                            </Typography>
                            <Controller
                                name="provinsi"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        loading={isLoadingProvinsi}
                                        options={provinsiOptions?.content ?? []}
                                        getOptionLabel={(option) => option.nama}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        value={field.value}
                                        onChange={(
                                            _event,
                                            newProvinsi: {
                                                id: number;
                                                nama: string;
                                            } | null,
                                        ) => {
                                            field.onChange(newProvinsi);
                                            if (newProvinsi) {
                                                setParamsKota(newProvinsi.id);
                                            } else {
                                                return;
                                            }
                                            setValue("kota", {
                                                id: 0,
                                                nama: "",
                                                provinsiId: 0,
                                                provinsiNama: "",
                                            });
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Pilih Provinsi"
                                                error={!!fieldState?.error}
                                                helperText={
                                                    fieldState?.error
                                                        ? "Pilih satu provinsi"
                                                        : ""
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                variant="body2"
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Kota
                            </Typography>
                            <Controller
                                name="kota"
                                control={control}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        disableClearable
                                        loading={isLoadingKota}
                                        options={kotaOptions?.content ?? []}
                                        getOptionLabel={(option) => option.nama}
                                        isOptionEqualToValue={(option, value) =>
                                            option.id === value.id
                                        }
                                        // inputValue={field.value?.nama ?? ""}
                                        value={field.value}
                                        onChange={(
                                            _event,
                                            newkota: {
                                                id: number;
                                                nama: string;
                                                provinsiId: number;
                                                provinsiNama: string;
                                            } | null,
                                        ) => {
                                            field.onChange(newkota);
                                        }}
                                        fullWidth
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Pilih Kota"
                                                error={!!fieldState?.error}
                                                helperText={
                                                    fieldState?.error
                                                        ? "Pilih satu kota"
                                                        : ""
                                                }
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    variant="body2"
                                    color={textPrimary.body}
                                    fontWeight={500}
                                >
                                    Alamat
                                </Typography>
                                {!!!watchAlamat && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="alamatToko"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="alamatToko"
                                        placeholder="Contoh: Jalan Maju Jaya no. 93 Semarang"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={Boolean(errors.alamatToko)}
                                        helperText={
                                            errors.alamatToko
                                                ? errors.alamatToko.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                variant="body2"
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Deskripsi{" "}
                                <span style={{ color: textPrimary.light }}>
                                    (opsional)
                                </span>
                            </Typography>
                            <Controller
                                name="deskripsi"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="deskripsi"
                                        placeholder="Contoh: UKM ini merupakan ukm yang menjual barang grosir, dan lain-lain"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={Boolean(errors.deskripsi)}
                                        helperText={
                                            errors.deskripsi
                                                ? errors.deskripsi.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    variant="body2"
                                    color={textPrimary.body}
                                    fontWeight={500}
                                >
                                    Jenis Usaha
                                </Typography>
                                {!!!watchJenisUsaha && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="jenisUsaha"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        // margin="dense"
                                        id="jenisUsaha"
                                        placeholder="Contoh: Kuliner, Jasa, Perdagangan, Mebel, dan lain-lain"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.jenisUsaha)}
                                        helperText={
                                            errors.jenisUsaha
                                                ? errors.jenisUsaha.message
                                                : ""
                                        }
                                        {...field}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    variant="body2"
                                    color={textPrimary.body}
                                    fontWeight={500}
                                >
                                    Besar Usaha
                                </Typography>
                                {!!!watchBesarUsaha && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="besarUsaha"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControl fullWidth>
                                        {/* <InputLabel>
                                                Pilih Besar Usaha
                                            </InputLabel> */}
                                        <Select
                                            margin="dense"
                                            onChange={onChange}
                                            value={value}
                                            // onChange={(
                                            //     event: SelectChangeEvent,
                                            // ) =>
                                            //     setBesarUsaha(
                                            //         event.target.value,
                                            //     )
                                            // }
                                            // value={besarUsaha}
                                            displayEmpty
                                            error={Boolean(errors.besarUsaha)}
                                        >
                                            <MenuItem value="">
                                                <em>-- Pilih Besar Usaha --</em>
                                            </MenuItem>
                                            <MenuItem value="Mikro">
                                                Mikro
                                            </MenuItem>
                                            <MenuItem value="Kecil">
                                                Kecil
                                            </MenuItem>
                                            <MenuItem value="Sedang">
                                                Sedang
                                            </MenuItem>
                                            <MenuItem value="Besar">
                                                Besar
                                            </MenuItem>
                                            <MenuItem value="Lainnya">
                                                Lainnya
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText
                                            error={Boolean(errors.besarUsaha)}
                                        >
                                            {errors.besarUsaha
                                                ? errors.besarUsaha.message
                                                : ""}
                                        </FormHelperText>
                                    </FormControl>
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                spacing={1}
                            >
                                <Typography
                                    variant="body2"
                                    color={textPrimary.body}
                                    fontWeight={500}
                                >
                                    Omzet Per Tahun
                                </Typography>
                                {!!!watchOmset && (
                                    <Box
                                        sx={{
                                            width: "8px",
                                            height: "8px",
                                            borderRadius: "50%",
                                            backgroundColor: "#fa4c46",
                                            color: "#fa4c46",
                                            boxShadow: `0 0 0 2px #fff`,
                                            "&::after": {
                                                position: "absolute",
                                                width: "8px",
                                                height: "8px",
                                                borderRadius: "50%",
                                                animation:
                                                    "ripple 1.2s infinite ease-in-out",
                                                border: "1px solid currentColor",
                                                content: '""',
                                            },

                                            "@keyframes ripple": {
                                                "0%": {
                                                    transform: "scale(.8)",
                                                    opacity: 1,
                                                },
                                                "100%": {
                                                    transform: "scale(2.4)",
                                                    opacity: 0,
                                                },
                                            },
                                        }}
                                    />
                                )}
                            </Stack>
                            <Controller
                                name="omset"
                                control={control}
                                render={({ field }) => (
                                    <NumberFormat
                                        customInput={TextField}
                                        id="omset"
                                        placeholder="24.000.000"
                                        // margin="dense"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        fullWidth
                                        variant="outlined"
                                        onValueChange={(values) => {
                                            const { value } = values;
                                            const valueAkhir = value.replace(
                                                ".",
                                                "",
                                            );
                                            console.log(valueAkhir);
                                            setValue("omset", valueAkhir);
                                        }}
                                        error={Boolean(errors.omset)}
                                        helperText={
                                            errors.omset
                                                ? errors.omset.message
                                                : ""
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography>Rp.</Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...field}
                                    />
                                )}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                variant="body2"
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Warna Tema
                            </Typography>
                        </Stack>
                        <Controller
                            name="warna"
                            control={control}
                            render={({ field }) => (
                                <React.Fragment>
                                    <Grid item xs={12} paddingBottom={1}>
                                        <Stack
                                            position="relative"
                                            direction="row"
                                            spacing={0}
                                        >
                                            <Stack
                                                paddingY={2}
                                                paddingLeft={2}
                                                paddingRight={1}
                                                flexDirection={"row"}
                                                border={"1px solid #A8B4AF"}
                                                borderRadius={2}
                                            >
                                                <Box
                                                    sx={{
                                                        width: "36px",
                                                        height: "36px",
                                                        borderLeft:
                                                            "1px solid rgba(0, 0, 0, 0.23)",
                                                        borderTop:
                                                            "1px solid rgba(0, 0, 0, 0.23)",
                                                        borderBottom:
                                                            "1px solid rgba(0, 0, 0, 0.23)",
                                                        background: `${field.value}`,
                                                    }}
                                                />
                                                <Box
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    onClick={() =>
                                                        setDisplayColorPicker(
                                                            (prev) => !prev,
                                                        )
                                                    }
                                                    sx={{
                                                        cursor: "pointer",
                                                        marginLeft: 1,
                                                    }}
                                                >
                                                    <ExpandMoreIcon
                                                        sx={{
                                                            color: "#A8B4AF",
                                                            width: "24px",
                                                        }}
                                                    />
                                                </Box>
                                            </Stack>
                                            {displayColorPicker ? (
                                                <Box
                                                    sx={{
                                                        position:
                                                            "absolute",
                                                        top: "-310px",
                                                        // left: "90px",
                                                        zIndex: 1,
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            position:
                                                                "fixed",
                                                            top: "0px",
                                                            right: "0px",
                                                            bottom: "0px",
                                                            left: "0px",
                                                        }}
                                                        onClick={() =>
                                                            setDisplayColorPicker(
                                                                false,
                                                            )
                                                        }
                                                    />
                                                    <SketchPicker
                                                        color={field.value}
                                                        onChange={(color) =>
                                                            field.onChange(
                                                                color.hex,
                                                            )
                                                        }
                                                    />
                                                </Box>
                                            ) : null}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} paddingBottom={2}>
                                        <Typography
                                            fontWeight={300}
                                            color="#7C8883"
                                            fontSize="12px"
                                            component="div"
                                        >
                                            *Pilih warna yang cukup kontras
                                            dengan teks berwarna putih
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} paddingBottom={2}>
                                        <Stack
                                            paddingY={2.5}
                                            paddingX={2}
                                            border={"1px solid #A8B4AF"}
                                            borderRadius={2}
                                            gap={2}
                                        >
                                            <Typography
                                                color="#7C8883"
                                                component="div"
                                            >
                                                Tampilan dengan warna tema
                                                yang telah dipilih akan
                                                terlihat seperti berikut:
                                            </Typography>
                                            <Stack
                                                flexDirection={"row"}
                                                paddingY={2}
                                                paddingX={3}
                                                gap={2.5}
                                                boxShadow={1}
                                                bgcolor={`${field.value}`}
                                                alignItems={"center"}
                                            >
                                                <MenuIcon
                                                    sx={{ color: "white" }}
                                                />
                                                <Typography
                                                    color="white"
                                                    fontSize={"17.5px"}
                                                    fontWeight={500}
                                                    component="div"
                                                >
                                                    {dataUkm?.namaToko ??
                                                        "Sas Store"}
                                                </Typography>
                                            </Stack>
                                            <Stack
                                                flexDirection={"row"}
                                                gap={2}
                                                alignItems={"center"}
                                                justifyContent={
                                                    "space-between"
                                                }
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <Stack
                                                            borderColor={
                                                                field.value
                                                            }
                                                            border={1}
                                                            paddingY={1.5}
                                                            sx={{
                                                                borderRadius: 2,
                                                                color: `${field.value}`,
                                                            }}
                                                        >
                                                            <Stack
                                                                alignItems="center"
                                                                gap={1.5}
                                                            >
                                                                <IconContext.Provider
                                                                    value={{
                                                                        size: "48px",
                                                                    }}
                                                                >
                                                                    <GiMoneyStack />
                                                                </IconContext.Provider>
                                                                <Typography
                                                                    fontSize={
                                                                        "17.5px"
                                                                    }
                                                                    fontWeight={
                                                                        500
                                                                    }
                                                                >
                                                                    Tunai
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <Stack>
                                                            <Stack
                                                                borderColor={
                                                                    field.value
                                                                }
                                                                border={1}
                                                                borderRadius={
                                                                    2
                                                                }
                                                                paddingY={
                                                                    1.5
                                                                }
                                                                sx={{
                                                                    color: `white`,
                                                                    backgroundColor: `${field.value}`,
                                                                }}
                                                            >
                                                                <Stack
                                                                    alignItems="center"
                                                                    gap={
                                                                        1.5
                                                                    }
                                                                >
                                                                    <IconContext.Provider
                                                                        value={{
                                                                            size: "48px",
                                                                        }}
                                                                    >
                                                                        <GiWallet />
                                                                    </IconContext.Provider>
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="center"
                                                                        gap={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            fontSize={
                                                                                "17.5px"
                                                                            }
                                                                            fontWeight={
                                                                                500
                                                                            }
                                                                        >
                                                                            Metode
                                                                            Lain
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                            <Stack
                                                paddingY={2}
                                                paddingX={3}
                                                borderTop={1}
                                                borderColor={"#A8B4AF"}
                                            >
                                                <Grid
                                                    container
                                                    alignItems={"center"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    marginBottom={1.5}
                                                >
                                                    <Grid item xs={"auto"}>
                                                        <Typography
                                                            color={
                                                                "#464E4B"
                                                            }
                                                            fontSize={
                                                                "14px"
                                                            }
                                                        >
                                                            Subtotal Harga
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={"auto"}>
                                                        <Typography
                                                            color={
                                                                "#2D312F"
                                                            }
                                                            fontSize={
                                                                "17.5px"
                                                            }
                                                        >
                                                            Rp. 75.400
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    alignItems={"center"}
                                                    spacing={1.5}
                                                    marginBottom={3}
                                                >
                                                    <Grid
                                                        item
                                                        xs={"auto"}
                                                        alignItems={
                                                            "center"
                                                        }
                                                    >
                                                        <CheckBox
                                                            sx={{
                                                                color: field.value,
                                                                marginLeft:
                                                                    -0.5,
                                                                marginBottom:
                                                                    -0.85,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={"auto"}
                                                        alignItems={
                                                            "center"
                                                        }
                                                    >
                                                        <Typography
                                                            fontSize={
                                                                "14px"
                                                            }
                                                            color={
                                                                "#464E4B"
                                                            }
                                                        >
                                                            Tambah
                                                            Keterangan
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <Stack
                                                            borderColor={
                                                                field.value
                                                            }
                                                            border={1}
                                                            paddingY={1.5}
                                                            sx={{
                                                                borderRadius: 2,
                                                                color: `${field.value}`,
                                                            }}
                                                        >
                                                            <Stack
                                                                justifyContent="center"
                                                                alignItems={
                                                                    "center"
                                                                }
                                                                gap={1}
                                                                direction={
                                                                    "row"
                                                                }
                                                            >
                                                                <Icon
                                                                    path={
                                                                        mdiDelete
                                                                    }
                                                                    size={1}
                                                                />
                                                                <Typography
                                                                    fontWeight={
                                                                        500
                                                                    }
                                                                    fontSize={
                                                                        "14px"
                                                                    }
                                                                >
                                                                    Kosongkan
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        md={6}
                                                    >
                                                        <Stack>
                                                            <Stack
                                                                borderColor={
                                                                    field.value
                                                                }
                                                                border={1}
                                                                borderRadius={
                                                                    2
                                                                }
                                                                paddingY={
                                                                    1.5
                                                                }
                                                                sx={{
                                                                    color: `white`,
                                                                    backgroundColor: `${field.value}`,
                                                                }}
                                                            >
                                                                <Stack
                                                                    justifyContent="center"
                                                                    alignItems={
                                                                        "center"
                                                                    }
                                                                    direction={
                                                                        "row"
                                                                    }
                                                                    gap={1}
                                                                >
                                                                    <IconContext.Provider
                                                                        value={{
                                                                            size: "48px",
                                                                        }}
                                                                    >
                                                                        <Icon
                                                                            path={
                                                                                mdiCashRegister
                                                                            }
                                                                            size={
                                                                                1
                                                                            }
                                                                        />
                                                                    </IconContext.Provider>
                                                                    <Box
                                                                        display="flex"
                                                                        justifyContent="center"
                                                                        gap={
                                                                            1
                                                                        }
                                                                    >
                                                                        <Typography
                                                                            fontWeight={
                                                                                500
                                                                            }
                                                                            fontSize={
                                                                                "14px"
                                                                            }
                                                                        >
                                                                            Pembayaran
                                                                        </Typography>
                                                                    </Box>
                                                                </Stack>
                                                            </Stack>
                                                        </Stack>
                                                    </Grid>
                                                </Grid>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </React.Fragment>
                            )}
                        />
                        
                        <Stack spacing={1}>
                            <Typography
                                variant="body2"
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Logo{" "}
                                <span style={{ color: textPrimary.light }}>
                                    (opsional)
                                </span>
                            </Typography>
                            <Box>
                                <Controller
                                    name="gambarToko"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            id="gambarToko"
                                            fullWidth
                                            type="file"
                                            variant="outlined"
                                            inputProps={{
                                                accept: "image/*",
                                            }}
                                            error={Boolean(errors.gambarToko)}
                                            helperText={
                                                errors.gambarToko
                                                    ? errors.gambarToko.message
                                                    : ""
                                            }
                                            {...field}
                                            onChange={(event: any) => {
                                                if (
                                                    event.target.files[0].size <
                                                    2097152
                                                ) {
                                                    console.log(
                                                        event.target.files[0],
                                                    );
                                                    setValue(
                                                        "gambarToko",
                                                        event.target.files[0],
                                                    );
                                                    clearErrors("gambarToko");
                                                } else {
                                                    event.target.value = "";
                                                    setError("gambarToko", {
                                                        type: "manual",
                                                        message:
                                                            "Ukuran gambar harus kurang dari 2MB",
                                                    });
                                                }
                                            }}
                                            value={undefined}
                                        />
                                    )}
                                />
                            </Box>
                            <Stack>
                                <Typography variant="caption" color="#6D6D6D">
                                    *Resolusi optimal: 300 x 300
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color="#6D6D6D"
                                    marginBottom={2}
                                >
                                    *Ukuran maksimal: 2MB
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            variant="outlined"
                            onClick={() => closeModalUkm()}
                        >
                            <Typography>Kembali</Typography>
                        </Button>
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="center"
                            variant="contained"
                            onClick={handleSubmit(onSubmit)}
                            type="submit"
                            fullWidth={isTabletScreen ? false : true}
                        >
                            Simpan Perubahan
                        </LoadingButton>
                    </Stack>
                </Fragment>
            </DialogContent>
        </Dialog>
    );
};

export default ModalUkm;
