import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "../../services/axios";
import useUser from "../../services/queries/useUser";
import useItem from "../../services/queries/useItem";
import toRibuan from "../../utils/toRibuan";
import defaultAxios, { AxiosError } from "axios";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Typography,
    DialogContent,
    useMediaQuery,
    Divider,
    Tooltip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
    DaftarItemListInvoice,
    InputPembayaranBody,
    InputPembayaranResponse,
    ParamsGetItem,
} from "../../constants/types";
import { LoadingButton } from "@mui/lab";
import { Close } from "@mui/icons-material";
import toRupiah from "../../utils/toRupiah";
import truncate from "../../utils/truncate";
import { textPrimary } from "../../constants/colors";

interface IModalRevertInvoiceProps {
    refetchLaporan: () => void;
    refetchListInvoice: () => void;
    refetchProdukTerjual: () => void;
    refetchMutasiStok: () => void;
    refetchGrafikPenjualan: () => void;
    refetchLaporanLabaRugi?: () => void;
    diskon?: number;
    totalHarga: number;
    totalTagihan: number;
    uangDibayar: number;
    kembalian: number;
    item?: DaftarItemListInvoice[];
    statusItem: "idle" | "error" | "loading" | "success";
    invoiceId: number;
    closeModalRevertInvoice: () => void;
    isOpenModalRevertInvoice: boolean;
    noInvoice?: string;
    namaMetode?: string | null;
    kasir?: string;
    sales?: string;
    pajak: number;
    pajakPersen?: number;
    keterangan?: string | null;
    isOpenBill?: number | undefined;
}

export const ModalRevertInvoice = (props: IModalRevertInvoiceProps) => {
    const theme = useTheme();
    const moment = require("moment");
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
    let now = moment().format("DD MMM YYYY HH:mm");

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        isShow: 0,
        search: "",
        kategori: "",
    };

    const [paramsItem, setParamsItem] = useState<ParamsGetItem>(initialParams);
    const [totalHargaSeluruhItem, setTotalHargaSeluruhItem] = useState(0);
    const { refetch: refetchItem } = useItem(paramsItem);
    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const {
        handleReset,
        isPrintStruk,
        resetPrint,
        setFetchingItems,
        handlingDonePembayaran,
        // diskon,
        // grandTotal,
        // bayar,
        // kembalian,
    } = useTransaksi();

    const current = new Date();
    current.getSeconds();

    const handleHitungTotalHargaSeluruhItem = useCallback(() => {
        let total = 0;
        if (props.item) {
            props.item.forEach((list) => {
                total +=
                    list.hargaSatuan * (list.jumlahItem - list.jumlahItem * 2);
            });
            setTotalHargaSeluruhItem(total);
            return;
        }
    }, [props.item]);

    useEffect(() => {
        handleHitungTotalHargaSeluruhItem();
    }, [props.item, handleHitungTotalHargaSeluruhItem]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const inputPembayaran = async () => {
        setIsButtonLoading(true);
        try {
            const { data } = await axios.post<InputPembayaranResponse>(
                "/api/pembayaran",
                {
                    ukmId: ukmIdUser && ukmIdUser[0],
                    jenisItem:
                        props.item &&
                        props.item.map((li) => ({
                            itemId: li.idItem,
                            namaItem: li.namaItem,
                            skuItem: li.skuItem,
                            jumlahItem: li.jumlahItem - li.jumlahItem * 2,
                            hargaSatuan: li.hargaSatuan,
                            hargaTotal: li.hargaTotal * -1,
                            kodeFaktur: li.faktur.map((fa) => ({
                                idFaktur: fa.idFaktur,
                                kodeFaktur: null,
                                jumlahItem: fa.jumlah * -1,
                            })),
                        })),
                    userInput: `${user?.userId}`,
                    sales: null,
                    totalHarga: totalHargaSeluruhItem,
                    diskon: Number(props.diskon) * -1,
                    totalPembayaran:
                        totalHargaSeluruhItem - Number(props.diskon) * -1,
                    uangYangDibayar: 0,
                    kembalian: 0,
                    isReverted: 1,
                    invoiceId: props.invoiceId,
                    metodeId: 0,
                } as InputPembayaranBody,
            );
            if (data.code === 200) {
                props.refetchLaporan();
                props.refetchListInvoice();
                props.refetchProdukTerjual();
                props.refetchMutasiStok();
                props.refetchGrafikPenjualan();
                if (props.refetchLaporanLabaRugi) {
                    props.refetchLaporanLabaRugi();
                }
                refetchItem();
                props.closeModalRevertInvoice();
                Swal.fire({
                    title: "Pembatalan berhasil!",
                    text: "Pembayaran telah berhasil dibatalkan",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });

                setFetchingItems();
                handleReset();
                setIsButtonLoading(false);
            }
        } catch (error) {
            setIsButtonLoading(false);
            handleErrorResponse(error);
        }
    };

    const handleErrorResponse = useCallback(
        (error) => {
            if (defaultAxios.isAxiosError(error)) {
                const serverError = error as AxiosError<any>;
                if (serverError && serverError.response) {
                    props.closeModalRevertInvoice();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `${serverError.response.data.data}`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                } else {
                    props.closeModalRevertInvoice();
                    Swal.fire({
                        title: "Terjadi Kesalahan!",
                        text: `Telah Terjadi Kesalahan`,
                        icon: "error",
                        confirmButtonColor: "#45A779",
                        customClass: {
                            container: "my-swal",
                        },
                    });
                }
            }
        },
        [props],
    );

    useEffect(() => {
        if (ukmIdUser) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    useEffect(() => {
        if (isPrintStruk) {
            inputPembayaran();
            resetPrint();
        }
    }, [inputPembayaran, isPrintStruk, resetPrint]);

    // if (status === "loading") {
    //     return (
    //         <Box position="relative">
    //             <Spinner />
    //         </Box>
    //     );
    // }

    return (
        <Box>
            <Dialog
                maxWidth="md"
                fullWidth
                fullScreen={fullScreen}
                open={
                    props.isOpenModalRevertInvoice &&
                    props.statusItem === "success"
                }
                onClose={props.closeModalRevertInvoice}
                scroll="paper"
                PaperProps={{
                    sx: {
                        borderRadius: 1,
                    },
                }}
            >
                <DialogTitle>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                    >
                        <Typography fontSize={32} fontWeight="bold">
                            Batalkan Pembayaran
                        </Typography>
                        <IconButton onClick={props.closeModalRevertInvoice}>
                            <Close fontSize="small" />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <Typography color={textPrimary.light}>
                                Tanggal
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {now}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography color={textPrimary.light}>
                                No. Invoice
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {props.noInvoice}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography color={textPrimary.light}>
                                Jenis Transaksi
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {props.isOpenBill === 1 ? "Open Bill" : props.namaMetode ?? "Tunai"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={6} md={4}>
                            <Typography color={textPrimary.light}>
                                Metode Pembayaran
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {props.namaMetode ?? "Tunai"}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <Typography color={textPrimary.light}>
                                Nama Kasir
                            </Typography>
                            <Typography color={textPrimary.body}>
                                {props.kasir}
                            </Typography>
                        </Grid>
                        {props.sales && (
                            <Grid item xs={6} md={4}>
                                <Typography color={textPrimary.light}>
                                    Nama Sales
                                </Typography>
                                <Typography color={textPrimary.body}>
                                    {props.sales}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>

                    <Box my={2}>
                        <Divider sx={{ borderStyle: "dashed" }} />
                    </Box>

                    <Stack spacing={2}>
                        {props.item?.map((items, index) => (
                            <Stack direction="row" key={index}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Tooltip title={items.namaItem}>
                                            <Typography
                                                color={textPrimary.body}
                                            >
                                                {truncate(items.namaItem, 20)}
                                            </Typography>
                                        </Tooltip>
                                        <Typography color={textPrimary.light}>
                                            @{toRupiah(items.hargaSatuan)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography
                                            color={textPrimary.body}
                                            fontWeight={500}
                                        >
                                            {items.jumlahItem}x
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={4}
                                        display="flex"
                                        justifyContent="flex-end"
                                    >
                                        <Typography
                                            color={textPrimary.body}
                                            fontWeight={500}
                                            textAlign="end"
                                        >
                                            {toRupiah(
                                                items.hargaSatuan *
                                                    items.jumlahItem,
                                            )}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Stack>
                        ))}
                    </Stack>
                    <Stack
                        spacing={1}
                        p={2}
                        mt={2}
                        border={`1px solid ${textPrimary.thin}`}
                        borderRadius="8px"
                    >
                        <Typography
                            color={textPrimary.title}
                            fontSize={20}
                            fontWeight={500}
                        >
                            Ringkasan Pembayaran
                        </Typography>
                        <Grid container justifyContent="space-between">
                            <Grid item xs={6} sm={3} md={4} lg={4}>
                                <Typography color={textPrimary.body}>
                                    Subtotal Harga
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingX={1}
                                >
                                    <Typography
                                        color={textPrimary.light}
                                        fontWeight={500}
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontWeight={500}
                                    >
                                        {toRibuan(props.totalHarga)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            marginTop={1}
                            justifyContent="space-between"
                        >
                            <Grid item xs={6} sm={4} md={5} lg={5}>
                                <Typography color={textPrimary.body}>
                                    {props.pajakPersen
                                        ? `Pajak (${props.pajakPersen}%)`
                                        : `Pajak`}
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    bgcolor="#e9f2f0"
                                    paddingX={1}
                                >
                                    <Typography
                                        color={textPrimary.light}
                                        fontWeight={500}
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        color={textPrimary.light}
                                        fontWeight={500}
                                    >
                                        {toRibuan(props.pajak)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            marginTop={1}
                            justifyContent="space-between"
                        >
                            <Grid item xs={6} sm={4} md={5} lg={5}>
                                <Typography color={textPrimary.body}>
                                    Diskon
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingX={1}
                                >
                                    <Typography
                                        color="#ed4521"
                                        fontWeight={500}
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        color="#ed4521"
                                        fontWeight={500}
                                    >
                                        {toRibuan(props.diskon ?? 0)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Divider
                            sx={{ borderStyle: "dashed", paddingTop: 1 }}
                        />
                        <Grid
                            container
                            alignItems="center"
                            marginTop={1}
                            justifyContent="space-between"
                        >
                            <Grid item xs={6} sm={3} md={4} lg={4}>
                                <Typography color={textPrimary.body}>
                                    Total Tagihan
                                </Typography>
                            </Grid>
                            <Grid item lg={3} xs={5}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    paddingX={1}
                                >
                                    <Typography
                                        color={textPrimary.title}
                                        fontSize={20}
                                        fontWeight={500}
                                    >
                                        Rp.
                                    </Typography>
                                    <Typography
                                        color={textPrimary.title}
                                        fontSize={20}
                                        fontWeight={500}
                                    >
                                        {toRibuan(props.totalTagihan)}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        {props.keterangan && (
                            <>
                                <Divider
                                    sx={{ borderStyle: "dashed", marginTop: 1 }}
                                />
                                <Box mt={1}>
                                    <Typography color={textPrimary.body}>
                                        Keterangan:
                                    </Typography>
                                    <Typography color={textPrimary.body}>
                                        {props.keterangan}
                                    </Typography>
                                </Box>
                            </>
                        )}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        gap={1}
                        mt={2}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                props.closeModalRevertInvoice();
                            }}
                        >
                            Kembali
                        </Button>
                        <LoadingButton
                            loading={isButtonLoading}
                            loadingPosition="start"
                            variant="contained"
                            size="large"
                            sx={{
                                backgroundColor: "#e4d318",
                                textTransform: "none",
                            }}
                            onClick={() => {
                                inputPembayaran();
                                handlingDonePembayaran();
                            }}
                        >
                            Batalkan Pembayaran
                        </LoadingButton>
                    </Stack>
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default ModalRevertInvoice;
