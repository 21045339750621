import { mdiArrowULeftTop } from "@mdi/js";
import Icon from "@mdi/react";
import { ArrowBackIosNew, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import defaultAxios from "axios";
import { addDays } from "date-fns";
import id from "date-fns/locale/id";
import { useState, useMemo, useEffect } from "react";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import { textInverted, textPrimary } from "../../constants/colors";
import { RiwayatPiutang, ParamsGetUkm } from "../../constants/types";
import { useAuth } from "../../context/authContext";
import { useDebt } from "../../context/DebtContext";
import { useTransaksi } from "../../context/transaksiContext";
import useDetailInvoice from "../../services/queries/useDetailInvoice";
import useRiwayatPiutang from "../../services/queries/useRiwayatPiutang";
import useVoidPiutang from "../../services/queries/useVoidPiutang";
import toRupiah from "../../utils/toRupiah";
import ButtonKalkulator from "../ButtonKalkulator/ButtonKalkulator";
import ButtonNumKalkulator from "../ButtonNumKalkulator/ButtonNumKalkulator";
import ConfirmDebtPayment from "./ConfirmDebtPayment";
import Print from "@mui/icons-material/Print";
import { BlobProvider } from "@react-pdf/renderer";
import StrukPiutangPdf from "../StrukPiutang/StrukPiutangPdf";
import moment from "moment";
import "moment/locale/id";

registerLocale("id", id);
setDefaultLocale("id");

const minDate = addDays(new Date(), 1);

export default function DebtPayment() {
    const { closeModalBayar, setChecked, selectedDebt } = useTransaksi();

    const {
        setTransaction,
        paymentAmount,
        setPaymentAmount,
        amountPaid,
        setAmountPaid,
        paymentDate,
        setPaymentDate,
        extendDueDate,
        setExtendDueDate,
        confirmDebtPayment,
        setConfirmDebtPayment,
        resetDebt,
    } = useDebt();

    const { ukmIdUser } = useAuth();
    const { data: user } = useUser();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };

    const [paramsUkm, setParamsUkm] = useState<ParamsGetUkm>(initialParamsUkm);
    const { data: ukm } = useUkm(paramsUkm);

    const ukmId = useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const {
        data: invoice,
        isLoading: invoiceLoading,
        error: invoiceError,
        refetch: refetchInvoice,
    } = useDetailInvoice(selectedDebt || 0);

    const { data: debtHistory } = useRiwayatPiutang({
        ukmId: ukmIdUser && ukmIdUser[0],
        transaksiId: selectedDebt || 0,
    });

    useEffect(() => {
        if (user && user?.ukmId?.length > 0) {
            setParamsUkm((prev) => ({
                ...prev,
                userId: user.ukmId[0],
            }));
        }
    }, [user]);

    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.down("md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const isDesktopScreen = useMediaQuery(theme.breakpoints.up("lg"));

    const [extendDueDateChecked, setExtendDueDateChecked] = useState(false);
    const [voidDialogOpen, setVoidDialogOpen] = useState(false);
    const [selectedDebtHistory, setSelectedDebtHistory] =
        useState<RiwayatPiutang | null>(null);

    const voidPiutang = useVoidPiutang();

    const onQuickActionClick = (digit: number) => {
        setAmountPaid((prev) => prev + digit);
    };

    const onClearButtonClick = () => {
        setAmountPaid(0);
    };

    const onDelButtonClick = () => {
        setAmountPaid((prev) => Math.floor(prev / 10));
    };

    const onDigitButtonClick = (digit: number) => {
        setAmountPaid((prev) => prev * 10 + digit);
    };

    const onEnterButtonClick = () => {
        // hitungBayar(Number(display));
        // setDisplay("");
    };

    const onZeroButtonClick = () => {
        setAmountPaid((prev) => prev * 10);
    };

    const onTripleZeroButtonClick = () => {
        setAmountPaid((prev) => prev * 1000);
    };

    return confirmDebtPayment ? (
        <ConfirmDebtPayment />
    ) : (
        <>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    overflowY: "scroll",
                    maxHeight: "100vh",
                    pb: 2,
                }}
            >
                <Stack direction="row" py={1} gap={1}>
                    <Button
                        size="small"
                        sx={{ padding: "0.25rem" }}
                        onClick={() => {
                            closeModalBayar();
                            resetDebt();
                        }}
                    >
                        <ArrowBackIosNew />
                    </Button>
                    <Typography
                        py={1}
                        color={textPrimary.body}
                        fontSize={24}
                        fontWeight={600}
                    >
                        Detail Transaksi
                    </Typography>
                </Stack>
                <Stack spacing={3} pr={2}>
                    <div>
                        <Typography
                            color={textPrimary.title}
                            fontSize={24}
                            fontWeight={600}
                            mb={1}
                        >
                            Nominal Pembayaran Piutang
                        </Typography>
                        <NumberFormat
                            value={paymentAmount}
                            inputMode="numeric"
                            customInput={TextField}
                            thousandSeparator="."
                            decimalSeparator=","
                            fullWidth
                            placeholder="0"
                            allowNegative={false}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setPaymentAmount(
                                    +event.target.value.replace(/\./g, ""),
                                );
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography>Rp.</Typography>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                inputMode: "numeric",
                                style: {
                                    textAlign: "right",
                                },
                            }}
                        />
                    </div>
                    <div>
                        <Typography
                            color={textPrimary.title}
                            fontSize={24}
                            fontWeight={600}
                            mb={1}
                        >
                            Tanggal Bayar
                        </Typography>
                        <DatePicker
                            className="date-picker"
                            dateFormat="dd/MM/yyyy"
                            minDate={minDate}
                            placeholderText="Pilih Tanggal Bayar"
                            onFocus={(e) => e.target.blur()}
                            selected={paymentDate}
                            onChange={setPaymentDate}
                        />
                    </div>
                    <div>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={2}
                        >
                            <div>
                                <Typography
                                    color={textPrimary.title}
                                    fontSize={24}
                                    fontWeight={600}
                                >
                                    Perpanjang Jatuh Tempo
                                </Typography>
                                <Typography color={textPrimary.body}>
                                    Perpanjang tanggal jatuh tempo piutang
                                </Typography>
                            </div>
                            <Switch
                                size="small"
                                checked={extendDueDateChecked}
                                onChange={(e) => {
                                    setExtendDueDateChecked(e.target.checked);
                                    if (e.target.checked) {
                                        setExtendDueDate(null);
                                    }
                                }}
                                inputProps={{
                                    "aria-label": "Perpanjang jatuh tempo",
                                }}
                                sx={{ alignSelf: "center" }}
                            />
                        </Stack>
                        {extendDueDateChecked && (
                            <DatePicker
                                className="date-picker"
                                dateFormat="dd/MM/yyyy"
                                minDate={minDate}
                                placeholderText="Pilih Tanggal Jatuh Tempo Baru"
                                onFocus={(e) => e.target.blur()}
                                selected={extendDueDate}
                                onChange={setExtendDueDate}
                            />
                        )}
                    </div>
                    {invoiceLoading ? (
                        <Stack alignItems="center">
                            <CircularProgress />
                        </Stack>
                    ) : invoiceError ? (
                        <Alert severity="error">
                            Terjadi kesalahan, silakan coba lagi.
                        </Alert>
                    ) : (
                        invoice && (
                            <Stack
                                p={3}
                                border={`1px solid ${textPrimary.thin}`}
                                borderRadius={2}
                                divider={
                                    <Divider
                                        sx={{
                                            my: 3.5,
                                            borderStyle: "dashed",
                                        }}
                                    />
                                }
                            >
                                <Box
                                    display="grid"
                                    gridTemplateColumns="repeat(auto-fit, minmax(200px, 1fr))"
                                    gap={2}
                                >
                                    <div>
                                        <Typography color={textPrimary.light}>
                                            Tanggal
                                        </Typography>
                                        <Typography color={textPrimary.body}>
                                            {moment(invoice.tanggalTransaksi).format("DD MMM YYYY HH:mm")}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography color={textPrimary.light}>
                                            No. Invoice
                                        </Typography>
                                        <Typography color={textPrimary.body}>
                                            {invoice.nomorInvoice}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography color={textPrimary.light}>
                                            Nama Pelanggan
                                        </Typography>
                                        <Typography color={textPrimary.body}>
                                            {invoice.pelangganNama}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography color={textPrimary.light}>
                                            Status Pelunasan
                                        </Typography>
                                        <Typography
                                            color={
                                                invoice.sisapiutang === 0
                                                    ? textPrimary.body
                                                    : "red"
                                            }
                                        >
                                            {invoice.sisapiutang === 0
                                                ? "Lunas"
                                                : "Belum Lunas"}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography color={textPrimary.light}>
                                            Jatuh Tempo
                                        </Typography>
                                        <Typography color={textPrimary.body}>
                                            {invoice.jatuhTempo}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography color={textPrimary.light}>
                                            Total Piutang
                                        </Typography>
                                        <Typography color={textPrimary.body}>
                                            {toRupiah(invoice.totalPiutang)}
                                        </Typography>
                                    </div>
                                </Box>
                                <Box>
                                    <Typography
                                        mb={2.5}
                                        color={textPrimary.title}
                                        fontSize={20}
                                        fontWeight={500}
                                    >
                                        Riwayat Pembayaran
                                    </Typography>
                                    <TableContainer>
                                        <Table
                                            size="small"
                                            sx={{
                                                "& th": { px: 0 },
                                                "& td": { px: 0 },
                                            }}
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>
                                                        Tanggal Pembayaran
                                                    </TableCell>
                                                    <TableCell>
                                                        Keterangan
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        Nominal
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        Aksi
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {debtHistory &&
                                                    debtHistory.length > 0 &&
                                                        debtHistory.map((history, index) => (
                                                            <TableRow
                                                                key={history.id}
                                                            >
                                                                <TableCell width="180px">
                                                                    {
                                                                        history.tanggal
                                                                    }
                                                                </TableCell>
                                                                <TableCell width="100px">
                                                                    {history.keterangan ??
                                                                        "-"}
                                                                </TableCell>
                                                                <TableCell align="right">
                                                                    {toRupiah(
                                                                        +history.nominal,
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <Stack 
                                                                        direction="row" 
                                                                        spacing={1}
                                                                        justifyContent="center"
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            color="buttonyellow"
                                                                            disableElevation
                                                                            onClick={() => {
                                                                                setSelectedDebtHistory(
                                                                                    history,
                                                                                );
                                                                                setVoidDialogOpen(
                                                                                    true,
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                minWidth:
                                                                                    "unset",
                                                                                padding:
                                                                                    "8px",
                                                                                width: "32px",
                                                                                height: "32px",
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                path={
                                                                                    mdiArrowULeftTop
                                                                                }
                                                                                size={
                                                                                    1
                                                                                }
                                                                            />
                                                                        </Button>
                                                                        <BlobProvider
                                                                            document={
                                                                                <StrukPiutangPdf
                                                                                    namaToko={String(
                                                                                        ukmData?.namaToko,
                                                                                    )}
                                                                                    alamatToko={String(
                                                                                        ukmData?.alamatToko,
                                                                                    )}
                                                                                    gambarToko={
                                                                                        ukmData?.gambarTokoBW ===
                                                                                        null
                                                                                            ? undefined
                                                                                            : ukmData?.gambarTokoBW
                                                                                    }
                                                                                    telpToko={String(
                                                                                        ukmData?.noTelp,
                                                                                    )}
                                                                                    tanggalTransaksi={history.tanggal}
                                                                                    kasir={
                                                                                        invoice?.kasir || ""
                                                                                    }
                                                                                    sales={
                                                                                        invoice?.sales ||
                                                                                        null
                                                                                    }
                                                                                    totalPiutang={
                                                                                        invoice?.totalPiutang ||
                                                                                        0
                                                                                    }
                                                                                    sisaPiutang={history.sisaPiutang}
                                                                                    // belumLunas={(history?.sisaPiutang || 0) - (Number(history?.dibayar)) !== 0}
                                                                                    belumLunas={history?.sisaPiutang > 0}
                                                                                    // sudahBayar={(invoice?.totalPiutang || 0) - (history?.sisaPiutang)}
                                                                                    sudahBayar={debtHistory.slice(0, index).reduce((total, prev) => total + Number(prev.nominal), 0)}
                                                                                    total={Number(history?.nominal)}
                                                                                    tunai={Number(history?.dibayar)}
                                                                                    kembalian={
                                                                                        history?.kembalian
                                                                                    }
                                                                                    isPro={Number(
                                                                                        ukmData?.isPro,
                                                                                    )}
                                                                                    namaMetode={
                                                                                        history?.metode.nama
                                                                                            ? history?.metode.nama
                                                                                            : "Tunai"
                                                                                    }
                                                                                    keterangan={history.keterangan}
                                                                                    noMetode={
                                                                                        history?.metodeArr.noTujuan ??
                                                                                        null
                                                                                    }
                                                                                />
                                                                            }
                                                                        >
                                                                            {({
                                                                                blob,
                                                                                url,
                                                                                loading,
                                                                                error,
                                                                            }) => {
                                                                                return (
                                                                                    <Button
                                                                                        variant="contained"
                                                                                        size="small"
                                                                                        color="buttonblue"
                                                                                        disableElevation
                                                                                        onClick={() => {
                                                                                            if (url) {
                                                                                                window.open(url, "_blank");
                                                                                            }
                                                                                        }}
                                                                                        sx={{
                                                                                            minWidth:
                                                                                                "unset",
                                                                                            padding:
                                                                                                "8px",
                                                                                            width: "32px",
                                                                                            height: "32px",
                                                                                        }}
                                                                                    >
                                                                                        <Print />
                                                                                    </Button>
                                                                                );
                                                                            }}
                                                                        </BlobProvider>
                                                                    </Stack>
                                                                </TableCell>
                                                            </TableRow>
                                                        ),
                                                    )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="space-between"
                                        gap={1}
                                        bgcolor={textInverted.body}
                                        p={1.5}
                                    >
                                        <Typography
                                            color={textPrimary.title}
                                            fontSize={14}
                                            fontWeight={500}
                                        >
                                            Sisa Piutang
                                        </Typography>
                                        <Typography
                                            color={textPrimary.title}
                                            fontSize={14}
                                            fontWeight={500}
                                        >
                                            {toRupiah(invoice.sisapiutang)}
                                        </Typography>
                                    </Stack>
                                </Box>
                                <Box>
                                    <Typography
                                        mb={2.5}
                                        color={textPrimary.title}
                                        fontSize={20}
                                        fontWeight={500}
                                    >
                                        Daftar Produk
                                    </Typography>
                                    <Stack gap={2}>
                                        {invoice.daftarItem.map((item) => (
                                            <Box
                                                key={item.idItem}
                                                display="grid"
                                                gridTemplateColumns="1fr 1fr 1fr"
                                                gap={2}
                                            >
                                                <div>
                                                    <Typography
                                                        color={textPrimary.body}
                                                    >
                                                        {item.namaItem}
                                                    </Typography>
                                                    <Typography
                                                        color={
                                                            textPrimary.light
                                                        }
                                                    >
                                                        @
                                                        {toRupiah(
                                                            item.hargaSatuan,
                                                        )}
                                                    </Typography>
                                                </div>
                                                <Typography
                                                    color={textPrimary.body}
                                                    fontWeight={500}
                                                    textAlign="center"
                                                >
                                                    {item.jumlahItem}x
                                                </Typography>
                                                <Typography
                                                    color={textPrimary.body}
                                                    fontWeight={500}
                                                    textAlign="right"
                                                >
                                                    {toRupiah(item.hargaTotal)}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Box>
                            </Stack>
                        )
                    )}
                </Stack>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    borderLeft: isPhoneScreen
                        ? undefined
                        : "1px solid rgba(0, 0, 0, 0.12)",
                    borderRight: isPhoneScreen
                        ? undefined
                        : "1px solid rgba(0, 0, 0, 0.12)",
                }}
            >
                <Stack gap={2} p={2}>
                    <Box
                        gridTemplateRows="1fr 1fr 4fr"
                        sx={{
                            display: { xs: "block", md: "grid" },
                            height: "calc(100vh - 6.5rem)",
                            rowGap: 1,
                        }}
                    >
                        <NumberFormat
                            value={amountPaid}
                            inputMode="numeric"
                            customInput={TextField}
                            thousandSeparator="."
                            decimalSeparator=","
                            autoFocus={isDesktopScreen}
                            fullWidth
                            placeholder="0"
                            allowNegative={false}
                            onChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                                setAmountPaid(
                                    +event.target.value.replace(/\./g, ""),
                                );
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Typography
                                            variant="h4"
                                            fontWeight="bold"
                                        >
                                            Rp.
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                            inputProps={{
                                inputMode: "numeric",
                                style: {
                                    fontSize: 40,
                                    fontWeight: "bold",
                                    textAlign: "right",
                                },
                            }}
                        />
                        <Box
                            display="grid"
                            gridTemplateColumns="1fr 1fr 1fr 1fr"
                            gap={1}
                        >
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    if (!invoice) return;
                                    setAmountPaid(paymentAmount);
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: {
                                            xs: 14,
                                            md: "subtitle1.fontSize",
                                        },
                                    }}
                                    fontSize={16}
                                >
                                    PAS
                                </Typography>
                            </Button>
                            <ButtonKalkulator
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(100000)}
                            >
                                100.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(50000)}
                            >
                                50.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(20000)}
                            >
                                20.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(10000)}
                            >
                                10.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(5000)}
                            >
                                5.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(2000)}
                            >
                                2.000
                            </ButtonKalkulator>
                            <ButtonKalkulator
                                buttonVariant="contained"
                                onClick={() => onQuickActionClick(1000)}
                            >
                                1.000
                            </ButtonKalkulator>
                        </Box>

                        {isLaptopScreen ? (
                            <Box
                                display="grid"
                                gridTemplateColumns="1fr 1fr 1fr 1fr"
                                gap={1}
                            >
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(1)}
                                >
                                    1
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(2)}
                                >
                                    2
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(3)}
                                >
                                    3
                                </ButtonNumKalkulator>
                                <ButtonKalkulator
                                    disabled={false}
                                    buttonVariant="contained"
                                    onClick={onDelButtonClick}
                                >
                                    DEL
                                </ButtonKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(4)}
                                >
                                    4
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(5)}
                                >
                                    5
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(6)}
                                >
                                    6
                                </ButtonNumKalkulator>
                                <ButtonKalkulator
                                    buttonVariant="contained"
                                    onClick={onClearButtonClick}
                                >
                                    C
                                </ButtonKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(7)}
                                >
                                    7
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(8)}
                                >
                                    8
                                </ButtonNumKalkulator>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={() => onDigitButtonClick(9)}
                                >
                                    9
                                </ButtonNumKalkulator>
                                <Button
                                    variant="contained"
                                    className="is-enter"
                                    onClick={onEnterButtonClick}
                                    sx={{
                                        backgroundColor: "primary",
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "subtitle1.fontSize",
                                                md: "h6.fontSize",
                                            },
                                        }}
                                    >
                                        ENTER
                                    </Typography>
                                </Button>
                                <ButtonNumKalkulator
                                    buttonVariant="outlined"
                                    onClick={onZeroButtonClick}
                                >
                                    0
                                </ButtonNumKalkulator>
                                <Button
                                    variant="outlined"
                                    className="is-000"
                                    onClick={onTripleZeroButtonClick}
                                >
                                    <Typography variant="h6">000</Typography>
                                </Button>
                            </Box>
                        ) : undefined}
                    </Box>
                    <Stack direction="row-reverse" gap={2} alignItems="center">
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (extendDueDateChecked && !extendDueDate) {
                                    Swal.fire({
                                        title: "Pilih tanggal jatuh tempo baru terlebih dahulu",
                                        position: "top-end",
                                        showConfirmButton: false,
                                        icon: "error",
                                        toast: true,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        showCloseButton: true,
                                        customClass: {
                                            container: "my-swal",
                                        },
                                    });

                                    return;
                                }

                                // if (paymentAmount < 1) {
                                //     Swal.fire({
                                //         title: "Nominal pembayaran tidak boleh kosong",
                                //         position: "top-end",
                                //         showConfirmButton: false,
                                //         icon: "error",
                                //         toast: true,
                                //         timer: 3000,
                                //         timerProgressBar: true,
                                //         showCloseButton: true,
                                //         customClass: {
                                //             container: "my-swal",
                                //         },
                                //     });

                                //     return;
                                // }

                                if (
                                    invoice &&
                                    paymentAmount > invoice.sisapiutang
                                ) {
                                    Swal.fire({
                                        title: "Nominal pembayaran melebihi total piutang",
                                        position: "top-end",
                                        showConfirmButton: false,
                                        icon: "error",
                                        toast: true,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        showCloseButton: true,
                                        customClass: {
                                            container: "my-swal",
                                        },
                                    });

                                    return;
                                }

                                // if (amountPaid < 1) {
                                //     Swal.fire({
                                //         title: "Uang yang dibayar tidak boleh kosong",
                                //         position: "top-end",
                                //         showConfirmButton: false,
                                //         icon: "error",
                                //         toast: true,
                                //         timer: 3000,
                                //         timerProgressBar: true,
                                //         showCloseButton: true,
                                //         customClass: {
                                //             container: "my-swal",
                                //         },
                                //     });

                                //     return;
                                // }

                                setTransaction(invoice || null);
                                setConfirmDebtPayment(true);
                            }}
                            size="large"
                            fullWidth
                        >
                            <Typography>Bayar</Typography>
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                closeModalBayar();
                                setChecked(false);
                            }}
                            fullWidth
                        >
                            <Typography>Kembali</Typography>
                        </Button>
                    </Stack>
                </Stack>
            </Grid>
            <Dialog
                open={voidDialogOpen}
                onClose={() => setSelectedDebtHistory(null)}
            >
                <DialogTitle
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 1,
                    }}
                >
                    <Typography
                        color={textPrimary.title}
                        fontSize={32}
                        fontWeight={700}
                    >
                        Batalkan Pembayaran
                    </Typography>
                    <IconButton onClick={() => setVoidDialogOpen(false)}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography>
                        Anda yakin ingin membatalkan pembayaran sejumlah{" "}
                        <strong>
                            {toRupiah(
                                selectedDebtHistory
                                    ? +selectedDebtHistory.nominal
                                    : 0,
                            )}
                        </strong>
                        ?
                    </Typography>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        gap={2}
                        mt={5.5}
                    >
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setVoidDialogOpen(false);
                            }}
                        >
                            Kembali
                        </Button>
                        <LoadingButton
                            variant="contained"
                            loading={voidPiutang.isLoading}
                            color="error"
                            onClick={() => {
                                if (!selectedDebtHistory) return;
                                voidPiutang.mutate(selectedDebtHistory.id, {
                                    onSuccess: () => {
                                        setVoidDialogOpen(false);
                                        Swal.fire({
                                            title: "Void piutang berhasil",
                                            position: "top-end",
                                            showConfirmButton: false,
                                            icon: "success",
                                            toast: true,
                                            timer: 3000,
                                            timerProgressBar: true,
                                            showCloseButton: true,
                                            customClass: {
                                                container: "my-swal",
                                            },
                                        });
                                        refetchInvoice();
                                    },
                                    onError: (error) => {
                                        if (
                                            defaultAxios.isAxiosError(error) &&
                                            (error.response?.status ?? 0) >= 400
                                        ) {
                                            Swal.fire({
                                                title:
                                                    error.response?.data
                                                        .message ?? "Error",
                                                position: "top-end",
                                                showConfirmButton: false,
                                                icon: "success",
                                                toast: true,
                                                timer: 3000,
                                                timerProgressBar: true,
                                                showCloseButton: true,
                                                customClass: {
                                                    container: "my-swal",
                                                },
                                            });
                                        } else {
                                            Swal.fire({
                                                title: "Terjadi kesalahan",
                                                position: "top-end",
                                                showConfirmButton: false,
                                                icon: "success",
                                                toast: true,
                                                timer: 3000,
                                                timerProgressBar: true,
                                                showCloseButton: true,
                                                customClass: {
                                                    container: "my-swal",
                                                },
                                            });
                                        }
                                    },
                                });
                            }}
                        >
                            Konfirmasi Batalkan
                        </LoadingButton>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
}
