import ReplayIcon from "@mui/icons-material/Replay";
import { Checkbox, FormControlLabel, Tab, Tabs, Grid, InputAdornment, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Swal from "sweetalert2";
import { ReactComponent as BelumAdaPesanan } from "../../assets/images/BelumAdaPesanan.svg";
import { IOptions, ParamsGetItem, PiutangParams, InputPembayaranResponse, DetailBundling, IFaktur, ParamsGetListInvoice } from "../../constants/types";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import useItem from "../../services/queries/useItem";
import useListInvoice from "../../services/queries/useListInvoice";
import useUkm from "../../services/queries/useUkm";
import useUkmIsDueExpired from "../../services/queries/useUkmIsDueExpired";
import useUser from "../../services/queries/useUser";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import toRupiah from "../../utils/toRupiah";
import CardInvoice from "../CardInvoice/CardInvoice";
import CardPesan from "../CardPesan/CardPesan";
import ModalBayar from "../ModalBayar/ModalBayar";
import ModalKonfirmasiBayar from "../ModalKonfirmasiBayar/ModalKonfirmasiBayar";
import DeleteIcon from "@mui/icons-material/Delete";
import PayIcon from "../PayIcon";
import DaftarPiutang from "../DaftarPiutang/DaftarPiutang";
import { textPrimary } from "../../constants/colors";
import usePiutang from "../../services/queries/usePiutang";
import axios from "../../services/axios";
import defaultAxios, { AxiosError } from "axios";
import {
    Card,
    Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import Chip from "@mui/material/Chip";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


interface IListPesanProps {
    handleIsError: () => void;
}

interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    index: number;
    value: number;
}

type DateFilter = [Date | null, Date | null];

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, style, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            style={{
                display: "grid",
                gridTemplateRows: "auto 1fr auto auto auto auto",
                height: "calc(100vh - 15rem)",
                ...style,
            }}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const ListPesan = ({ handleIsError }: IListPesanProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("lg"));
    const [isScanBarcode, setIsScanBarcode] = useState<boolean>(false);
    const { ukmIdUser } = useAuth();
    const {
        totalHarga,
        addToCart,
        cart,
        openModalBayar,
        handleReset,
        isKeterangan,
        handleIsKeterangan,
        keteranganBeli,
        setKeteranganBeli,
        handleChangeKeteranganBeli,
        setPaymentMode,
        openBill,
        setOpenBill
    } = useTransaksi();

    var today = new Date();

    const [diskon, setDiskon] = useState<number>();
    const [persentaseDiskon, setPersentaseDiskon] = useState<number | null>();
    const [pajak, setPajak] = useState<number>();
    const [persentasePajak, setPersentasePajak] = useState<number | null>();
    const [tagihan, setTagihan] = useState<number>();
    const [uangDibayar, setUangDibayar] = useState<number>();
    const [kembalian, setKembalian] = useState<number>();
    const [sales, setSales] = useState<IOptions | null>(null);

    const [sku, setSku] = useState<string>("");
    const [inputSku, setInputSku] = useState<string>("");
    const [sectionNumber, setSectionNumber] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setSectionNumber(newValue);
    };

    const initialParamsItem = {
        ukmId: undefined,
        size: 1000,
        isShow: 0,
        page: 1,
        search: "",
        kategori: "",
    };
    const [paramsItem, setParamsItem] =
        useState<ParamsGetItem>(initialParamsItem);
        const {
            data: item,
            refetch: refetchItem,
            isError: isErrorItem,
        } = useItem(paramsItem);

    const moment = require("moment");
    let now = moment().format("YYYY/MM/DD");

    const [dateRange, setDateRange] = useState<DateFilter>([
        new Date(),
        new Date(),
    ]);
    const [startDate, endDate] = dateRange;

    const initialParamsListInvoice = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 100,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
        kasir: "",
        sales: "",
        metodeId: "",
        voidInvoice: "",
    };

    const [paramsListInvoice, setParamsListInvoice] =
        useState<ParamsGetListInvoice>(initialParamsListInvoice);

    const {
        data: listInvoice,
        refetch: refetchListInvoice,
        isLoading: isLoadingListInvoice,
        isError: isErrorListInvoice,
    } = useListInvoice(paramsListInvoice);

    const initialParamsListOpenBill = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 100,
        page: 1,
        tanggalAwal: now,
        tanggalAkhir: now,
        kasir: "",
        sales: "",
        metodeId: "",
        voidInvoice: "",
        openBill: 1,
    };

    const [paramsListOpenBill, setParamsListOpenBill] =
        useState<ParamsGetListInvoice>(initialParamsListOpenBill);

    const {
        data: listOpenBill,
        refetch: refetchListOpenBill,
        isLoading: isLoadingListOpenBill,
        isError: isErrorListOpenBill,
    } = useListInvoice(paramsListOpenBill);

    const handleChangePeriode = (event: SelectChangeEvent) => {
        setDateRange([new Date(event.target.value), new Date()]);
        setParamsListInvoice((prev) => ({
            ...prev,
            tanggalAwal: String(event.target.value),
            tanggalAkhir: now,
            page: 1,
        }));

        refetchListInvoice();
    };

    const handleChangePeriodeOpenBill = (event: SelectChangeEvent) => {
        setDateRange([new Date(event.target.value), new Date()]);

        setParamsListOpenBill((prev) => ({
            ...prev,
            tanggalAwal: String(event.target.value),
            tanggalAkhir: now,
            page: 1,
        }));
        
        refetchListOpenBill();
    };

    const handleChangeJangkaWaktu = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {
        setParamsListInvoice((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        
        refetchListInvoice();
    };

    const handleChangeJangkaWaktuOpenBill = (
        tanggalAwal: string,
        tanggalAkhir: string,
    ) => {

        setParamsListOpenBill((prev) => ({
            ...prev,
            tanggalAwal: tanggalAwal,
            tanggalAkhir: tanggalAkhir,
            page: 1,
        }));
        
        refetchListOpenBill();
    };

    function subtractMonths(numOfMonths: number, date = new Date()) {
        date.setMonth(date.getMonth() - numOfMonths);

        return date;
    }

    function subtractYears(numOfYears: number, date = new Date()) {
        date.setFullYear(date.getFullYear() - numOfYears);

        return date;
    }

    useEffect(() => {
        refetchListInvoice();
    }, [paramsListInvoice, refetchListInvoice]);

    const initialParamsUkmIsDueExpired = React.useMemo(
        () => ({
            search: "",
            size: 50,
            page: 1,
            isDueExpired: 1,
        }),
        [],
    );
    const { data: ukmIsDueExpired } = useUkmIsDueExpired(
        initialParamsUkmIsDueExpired,
    );

    const { data: user } = useUser();

    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);

    const initialPiutangParams = {
        ukmId: ukmIdUser && ukmIdUser[0],
        search: "",
        size: 100,
        page: 1,
        piutang: 1,
        urutan: 2,
    };
    const [piutangParams, setPiutangParams] =
        useState<PiutangParams>(initialPiutangParams);
    const { data: debt } = usePiutang(piutangParams);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const {
        isFetchingItems,
        setFetchingItems,
        cancelFetchingItems,
        selectedDebt,
        setSelectedDebt,
    } = useTransaksi();

    const [transaksiKe, setTransaksiKe] = useState("");

    const resetKeteranganBeli = useCallback(() => {
        setKeteranganBeli("");
    }, []);

    const handlingRincianPembayaran = (
        persentaseDiskon: number | null,
        diskon: number | null,
        persentasePajak: number | null,
        pajak: number | null,
        tagihan: number,
        uangYangDibayar: number,
        kembalian: number,
        sales: IOptions | null,
        // openBill: number | null,
    ) => {
        if (diskon && diskon > 0) {
            setDiskon(diskon);
        } else {
            setDiskon(0);
        }
        if (persentaseDiskon !== null) {
            setPersentaseDiskon(persentaseDiskon);
        }
        if (pajak && pajak > 0) {
            setPajak(pajak);
        } else {
            setPajak(0);
        }
        if (persentasePajak !== null) {
            setPersentasePajak(persentasePajak);
        }
        setTagihan(tagihan);
        setUangDibayar(uangYangDibayar);
        setKembalian(kembalian);
        setSales(sales);
        // if (openBill !== null) {
        //     setOpenBill(openBill);
        // }
    };

    function handleDebtPayment() {
        if (!selectedDebt) return;

        setPaymentMode("debt");
    }

    useEffect(() => {
        if (isFetchingItems) {
            refetchListInvoice();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchListInvoice]);

    useEffect(() => {
        if (sectionNumber === 1 || sectionNumber === 2) {
            setCartOpenBill([]);
            setCartItems([]);
            refetchListInvoice();
            refetchListOpenBill();
        }
    }, [sectionNumber, refetchListInvoice, refetchListOpenBill]);

    useEffect(() => {
        if (isErrorItem || isErrorListInvoice) {
            handleIsError();
        }
    }, [handleIsError, isErrorItem, isErrorListInvoice]);

    type CartItem = {
        id: number;
        namaBarang: string;
        namaKategori: string;
        sku: string | number;
        harga: number;
        jumlahStok?: number;
        url: string;
        qty: number;
        detailBundling?: any;
        hpp?: number;
        multipleHarga?: any;
    };
    
    type InvoiceItem = {
        id: number;
        namaBarang: string;
        namaKategori: string;
        sku: string | number;
        harga: number;
        stok: number;
        url: string;
        jumlahItem: number;
        detailBundling: DetailBundling[] | [];
        hpp: {
            id: number;
            faktur: string;
            hargaBeli: number;
            jumlah: number;
        }[];
        hargaMultiple: {
            id: number;
            harga: number;
            keterangan: string | null;
        }[];
        keteranganItem: string | null;
    };

    type ICart = {
        id: number;
        namaBarang: string;
        namaKategori: string;
        sku: string;
        harga: number;
        url: string;
        qty: number;
        jumlahStok: number | undefined;
        detailBundling: DetailBundling[] | [];
        hpp: {
            id: number;
            faktur: string;
            hargaBeli: number;
            jumlah: number;
        }[];
        faktur: IFaktur[];
        multipleHarga: {
            id: number;
            harga: number;
            keterangan: string | null;
        }[];
        keteranganItem: string | null;
    }

    const [cartOpenBill, setCartOpenBill] = useState<any[]>([]);
    const [invoiceId, setInvoiceId] = useState<string | null>(null);
    const [cartItems, setCartItems] = useState<InvoiceItem[]>([]);

    const handleAddToCart = (invoice: any) => {
        if (!invoice?.daftarItem || invoice.daftarItem.length === 0) return;
    
        invoice.daftarItem.forEach((item: InvoiceItem) => {
            const existingItem = cart.find((cartItem) => cartItem.id === item.id);
    
            if (!existingItem) {
                addToCart({
                    id: item.id,
                    namaBarang: item.namaBarang,
                    namaKategori: item.namaKategori,
                    sku: String(item.sku),
                    harga: item.harga,
                    url: item.url,
                    jumlahStok: item.stok,
                    qty: item.jumlahItem,
                    detailBundling: item.detailBundling,
                    hpp: item.hpp,
                    multipleHarga: item.hargaMultiple,
                    faktur: [],
                    keteranganItem: item.keteranganItem,
                });
            }
        });
    
        setCartOpenBill((prevCart) => [...prevCart, invoice]);
        setInvoiceId(invoice.id);
        setSectionNumber(0);
        setCartItems((prevItems) => [...prevItems, ...invoice.daftarItem]);
    };

    const handleAddToCartEndOpenBill = (invoice: any) => {

        if (!invoice?.daftarItem || invoice.daftarItem.length === 0) return;
    
        invoice.daftarItem.forEach((item: InvoiceItem) => {
            const existingItem = cart.find((cartItem) => cartItem.id === item.id);

            if (!existingItem) {
                addToCart({
                    id: item.id,
                    namaBarang: item.namaBarang,
                    namaKategori: item.namaKategori,
                    sku: String(item.sku),
                    harga: item.harga,
                    url: item.url,
                    jumlahStok: item.stok,
                    qty: item.jumlahItem,
                    detailBundling: item.detailBundling,
                    hpp: item.hpp,
                    multipleHarga: item.hargaMultiple,
                    faktur: [],
                    keteranganItem: item.keteranganItem,
                });
            }
        });

        localStorage.removeItem(`openBill_${ukmId}`);
    };

    const handleRemoveFromCartItems = (id: string | number) => {
        setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
    };    

    // const [openBill, setOpenBill] = useState(0);

    // const handleSwitchChange = (event: { target: { checked: any; }; }) => {
    //     const isChecked = event.target.checked;
    //     setOpenBill(isChecked ? 1 : 0);
        
    //     console.log({ openBill: isChecked ? 1 : 0 });
    // };

    const handleSwitchChange = (event: { target: { checked: boolean } }) => {
        const isChecked = event.target.checked;
        setOpenBill(isChecked ? 1 : 0);
    
        if (isChecked) {
            localStorage.setItem(
                `openBill_${ukmId}`,
                JSON.stringify({ openBill: 1, cart, ukmId })
            );
        } else {
            localStorage.removeItem(`openBill_${ukmId}`);
        }
    
        console.log({ openBill: isChecked ? 1 : 0 });
    };
    
    const firstLoad = useRef(true);
    
    useEffect(() => {
        if (!firstLoad.current) return;
        firstLoad.current = false;
    
        const storedData = localStorage.getItem(`openBill_${ukmId}`);
    
        if (storedData) {
            try {
                const parsedData = JSON.parse(storedData);
    
                if (parsedData && typeof parsedData === "object") {
                    const { openBill, cart } = parsedData;
    
                    if (openBill !== undefined) {
                        setOpenBill(openBill);
                    }
    
                    if (Array.isArray(cart) && cart.length > 0) {
                        cart.forEach((item: ICart) => {
                            addToCart({
                                id: item.id,
                                namaBarang: item.namaBarang,
                                namaKategori: item.namaKategori,
                                sku: String(item.sku),
                                harga: item.harga,
                                url: item.url,
                                jumlahStok: item.jumlahStok,
                                qty: item.qty,
                                detailBundling: item.detailBundling || [],
                                hpp: item.hpp || [],
                                multipleHarga: item.multipleHarga || [],
                                faktur: [],
                                keteranganItem: item.keteranganItem,
                            });
                        });
                    }
                }
            } catch (error) {
                console.error("Error parsing localStorage data:", error);
            }
        }
    }, [ukmId]);
    
    useEffect(() => {
        if (openBill === 1 && cart.length > 0) {
            console.log("Saving to localStorage...");
            localStorage.setItem(
                `openBill_${ukmId}`,
                JSON.stringify({ openBill: 1, cart, ukmId })
            );
        }
    }, [cart, openBill, ukmId]);

    const [search, setSearch] = useState("");
    const [filteredInvoices, setFilteredInvoices] = useState(listInvoice?.content || []);
    const [filteredInvoicesOpenBill, setFilteredInvoicesOpenBill] = useState(listOpenBill?.content || []);

    useEffect(() => {
        if (listInvoice?.content) {
            setFilteredInvoices(
                listInvoice.content.filter((li) =>
                    li.nomorInvoice.toLowerCase().includes(search.toLowerCase()) ||
                    li.keterangan?.toLowerCase().includes(search.toLowerCase())
                )
            );
        }
    }, [search, listInvoice]);

    useEffect(() => {
        if (listOpenBill?.content) {
            setFilteredInvoicesOpenBill(
                listOpenBill.content.filter((li) =>
                    li.nomorInvoice.toLowerCase().includes(search.toLowerCase()) ||
                    li.keterangan?.toLowerCase().includes(search.toLowerCase())
                )
            );
        }
    }, [search, listOpenBill]);
    
    useEffect(() => {
        if (isScanBarcode) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [isScanBarcode, ukmIdUser]);

    useEffect(() => {
        if (inputSku !== "") {
            setSku("");
            setInputSku("");
            const selectedProduct = item?.content.filter(
                (list) => list.sku === sku,
            )[0];
            const index = cart.findIndex((c) => {
                return c.id === selectedProduct?.id;
            });

            if (
                (selectedProduct && selectedProduct?.stok > 0) ||
                selectedProduct?.stok === null
            ) {
                addToCart({
                    id: Number(selectedProduct?.id),
                    namaBarang: String(selectedProduct?.namaBarang),
                    namaKategori: String(selectedProduct?.kategori.nama),
                    sku: String(selectedProduct?.sku),
                    harga: Number(selectedProduct?.harga),
                    url: String(selectedProduct?.gambar),
                    jumlahStok: selectedProduct?.stok ?? null,
                    qty: 1,
                    detailBundling: selectedProduct.detailBundling,
                    hpp: selectedProduct.hpp,
                    faktur: [],
                    multipleHarga: selectedProduct.hargaMultiple.map((li) => ({
                        id: li.id,
                        harga: Number(li.harga),
                        keterangan: li.keterangan,
                    })),
                    keteranganItem: selectedProduct.keteranganItem,
                });
                // if (cart.length <= 0) {
                //     addToCart({
                //         id: Number(selectedProduct?.id),
                //         namaBarang: String(selectedProduct?.namaBarang),
                //         sku: String(selectedProduct?.sku),
                //         harga: Number(selectedProduct?.harga),
                //         url: String(selectedProduct?.gambar),
                //         jumlahStok: selectedProduct?.stok ?? null,
                //         qty: 1,
                //         detailBundling: selectedProduct.detailBundling,
                //     });
                // } else if (cart.length > 0) {
                //     if (
                //         (selectedProduct &&
                //             selectedProduct?.stok > cart[index]?.qty) ||
                //         selectedProduct?.stok === null
                //     ) {
                //         addToCart({
                //             id: Number(selectedProduct?.id),
                //             namaBarang: String(selectedProduct?.namaBarang),
                //             sku: String(selectedProduct?.sku),
                //             harga: Number(selectedProduct?.harga),
                //             url: String(selectedProduct?.gambar),
                //             jumlahStok: selectedProduct?.stok ?? null,
                //             qty: 1,
                //             detailBundling: selectedProduct.detailBundling,
                //         });
                //     } else {
                //         Swal.fire({
                //             title: "Stok Barang tidak mencukupi",
                //             position: "top-end",
                //             showConfirmButton: false,
                //             icon: "error",
                //             toast: true,
                //             timer: 3000,
                //             timerProgressBar: true,
                //             showCloseButton: true,
                //         });
                //     }
                // }
            } else if (selectedProduct && selectedProduct?.stok <= 0) {
                Swal.fire({
                    title: "Barang telah habis",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            } else {
                Swal.fire({
                    title: "Barang tidak ditemukan",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "error",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
            }
        }
    }, [addToCart, cart, inputSku, item?.content, sku, ukmIdUser]);

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const handlingOpenBill = async () => {
        setIsButtonLoading(true);
        const body = {
            ukmId: ukmIdUser && ukmIdUser[0],
            jenisItem: cart.map((li) => ({
                itemId: li.id,
                namaItem: li.namaBarang,
                namaKategori: li.namaKategori,
                skuItem: li.sku,
                jumlahItem: li.qty,
                hargaSatuan: li.harga,
                hargaTotal: li.harga * li.qty,
                kodeFaktur: null,
                keteranganItem: li.keteranganItem || null,
            })),
            totalHarga: totalHarga,
            totalPembayaran: totalHarga,
            diskon: 0,
            uangYangDibayar: 0,
            kembalian: 0,
            metodeId: 0,
            userInput: `${user?.userId}`,
            openBill: openBill,
            keterangan: !!keteranganBeli ? keteranganBeli : null,
        };

        try {
            const { data } = await axios.post<InputPembayaranResponse>(
                "/api/pembayaran",
                body,
            );
            if (data.code === 200) {
                setTransaksiKe(String(data.data.transaksiKe));
                // console.log("Data transaksiKe:", String(data.data.transaksiKe));
                Swal.fire({
                    title: "Transaksi berhasil!",
                    text: "Open Bill Anda telah berhasil",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: {
                        container: "my-swal",
                    },
                });

                localStorage.removeItem(`openBill_${ukmId}`);

                setOpenBill(0);
                setFetchingItems();
                handleReset();
                setIsButtonLoading(false);
                handleIsKeterangan(false);
            }
        } catch (error) {
            setIsButtonLoading(false);
        }
    };

    const handlingUpdateOpenBill = async () => {
        setIsButtonLoading(true);
        
        const body = {
            jenisItem: cart.map((li) => ({
                itemId: li.id,
                namaItem: li.namaBarang,
                namaKategori: li.namaKategori,
                skuItem: li.sku,
                jumlahItem: li.qty,
                hargaSatuan: li.harga,
                hargaTotal: li.harga * li.qty,
                kodeFaktur: null,
                keteranganItem: li.keteranganItem || null,
            })),
            totalHarga: totalHarga,
            totalPembayaran: totalHarga,
            diskon: 0,
            keterangan: !!keteranganBeli ? keteranganBeli : null,
        };
    
        try {
            const { data } = await axios.put<InputPembayaranResponse>(
                `/api/pembayaran/${invoiceId}`,
                body,
            );

            if (data.code === 200) {    
                Swal.fire({
                    title: "Update Open Bill berhasil!",
                    text: "Update Open Bill Anda berhasil",
                    icon: "success",
                    confirmButtonColor: "#45A779",
                    customClass: { container: "my-swal" },
                });

                localStorage.removeItem(`openBill_${ukmId}`);
                
                setFetchingItems();
                refetchListInvoice();
                setCartOpenBill([]);
                setOpenBill(0);
                handleReset();
                handleIsKeterangan(false);
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
        } finally {
            setIsButtonLoading(false);
        }
    };
    

    return (
        <React.Fragment>
            <Box
            // display="grid"
            // gridTemplateRows="auto auto 1fr auto auto"
            // sx={{
            //     height: isPhoneScreen
            //         ? ukmData &&
            //           ukmIsDueExpired &&
            //           ukmIsDueExpired.totalElements > 0 &&
            //           ukmIsDueExpired.content.filter(
            //               (list) => list.id === ukmData.id,
            //           ).length > 0
            //             ? "calc(100vh - 98px)"
            //             : "calc(100vh - 56px)"
            //         : isLaptopScreen
            //         ? ukmData &&
            //           ukmIsDueExpired &&
            //           ukmIsDueExpired.totalElements > 0 &&
            //           ukmIsDueExpired.content.filter(
            //               (list) => list.id === ukmData.id,
            //           ).length > 0
            //             ? "calc(100vh - 48.75px)"
            //             : "100vh"
            //         : "calc(100vh - 64px)",
            // }}
            >
                <Box
                    sx={{
                        paddingLeft: 2,
                        borderColor: "divider",
                    }}
                >
                    <Tabs
                        value={sectionNumber}
                        onChange={handleChange}
                        aria-label="Keranjang dan Daftar Transaksi"
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                    >
                        <Tab label="Keranjang" {...a11yProps(0)} />
                        <Tab label="Daftar Open Bill" {...a11yProps(1)} />
                        <Tab label="Daftar Transaksi" {...a11yProps(2)} />
                        <Tab label="Daftar Piutang" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                {sectionNumber === 0 && (
                    <CustomTabPanel value={sectionNumber} index={0}>
                        <Box>
                            <Box
                                display="grid"
                                gridTemplateColumns="1fr auto"
                                marginX={2}
                                marginY={1}
                                gap={2}
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography fontWeight="500">
                                    Barang yang Dipesan
                                </Typography>
                                <Stack direction="row">
                                    <Stack direction="row"  alignItems="center">
                                        <Typography fontSize="1rem">
                                            Open Bill
                                        </Typography>
                                        <Switch
                                            checked={openBill === 1}
                                            size="small"
                                            onChange={handleSwitchChange}
                                            disabled={cartOpenBill.length > 0}
                                            inputProps={{ "aria-label": "Scan Barcode" }}
                                        />
                                    </Stack>
                                    <Stack direction="row" alignItems="center">
                                        <Typography fontSize="1rem">
                                            Mode Barcode
                                        </Typography>
                                        <Switch
                                            checked={isScanBarcode}
                                            size="small"
                                            onChange={() =>
                                                setIsScanBarcode((prev) => !prev)
                                            }
                                            inputProps={{
                                                "aria-label": "Scan Barcode",
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                            </Box>
                            {isScanBarcode && (
                                <Stack
                                    marginLeft={2}
                                    marginBottom={3}
                                    spacing={2}
                                    direction="row"
                                    alignItems="center"
                                >
                                    <Typography
                                        variant={
                                            isTabletScreen || isPhoneScreen
                                                ? "caption"
                                                : "subtitle2"
                                        }
                                    >
                                        Input SKU (Scan)
                                    </Typography>
                                    <TextField
                                        value={sku}
                                        size="small"
                                        autoFocus={isScanBarcode === true}
                                        onChange={(e) => setSku(e.target.value)}
                                        onKeyPress={(e) => {
                                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                            e.key === "Enter"
                                                ? setInputSku(sku)
                                                : undefined;
                                        }}
                                        sx={{
                                            width: isPhoneScreen
                                                ? undefined
                                                : 200,
                                        }}
                                        inputProps={{
                                            style: {
                                                height: 20,
                                            },
                                        }}
                                    />
                                </Stack>
                            )}
                        </Box>
                        <Box 
                            className="hide-scroll" 
                            marginX={2} 
                            sx={{ overflowY: "auto" }}
                        >
                            {cartOpenBill.length > 0 ? (
                                // cartOpenBill.map((invoice) => (
                                //     <Card 
                                //         key={invoice.nomorInvoice} 
                                //         variant="outlined" 
                                //         sx={{ 
                                //             marginBottom: 1, 
                                //             padding: isTabletScreen ? 1 : 2,
                                //             height: "95%",
                                //         }}
                                //     >
                                //         <Box display="grid" className="card-openBill" mb={2}>
                                //             <Typography fontWeight={700} fontSize={20}>Open Bill</Typography>
                                //             <Box flexDirection="column" width="100%" mt={1}>
                                //                 <Tooltip title={invoice.nomorInvoice}>
                                //                     <Typography>No. Invoice: {invoice.nomorInvoice}</Typography>
                                //                 </Tooltip>
                                //             </Box>
                                //         </Box>

                                //         {cart.length > 0 ? (
                                //             cart.map((rows) => (
                                //                 <CardPesan
                                //                     key={rows.id}
                                //                     id={rows.id}
                                //                     namaBarang={rows.namaBarang}
                                //                     namaKategori={rows.namaKategori}
                                //                     sku={String(rows.sku)}
                                //                     harga={rows.harga}
                                //                     jumlahStok={rows.jumlahStok}
                                //                     url={rows.url}
                                //                     qty={rows.qty}
                                //                     detailBundling={rows.detailBundling}
                                //                     hpp={rows.hpp}
                                //                     multipleHarga={rows.multipleHarga}
                                //                 />
                                //             ))
                                //         ) : (
                                //             <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                                //                 <BelumAdaPesanan width={isTabletScreen ? "20%" : "50%"} style={{ height: "fit-content" }} />
                                //                 <Typography
                                //                     marginTop={isTabletScreen ? 2 : isPhoneScreen ? 1 : 5}
                                //                     variant={isTabletScreen || isPhoneScreen ? "body2" : "h6"}
                                //                     fontWeight="bold"
                                //                 >
                                //                     Tambahkan Pesanan Anda
                                //                 </Typography>
                                //             </Box>
                                //         )}
                                //     </Card>
                                // ))
                                cartOpenBill.map((invoice) => (
                                    <Card 
                                        key={invoice.nomorInvoice} 
                                        variant="outlined" 
                                        sx={{ 
                                            marginBottom: 1, 
                                            padding: isTabletScreen ? 1 : 2,
                                            // height: "95%",
                                        }}
                                    >
                                        <Box display="grid" className="card-openBill" mb={2}>
                                            <Typography fontWeight={700} fontSize={20}>Open Bill</Typography>
                                            <Box flexDirection="column" width="100%" mt={1}>
                                                <Tooltip title={invoice.nomorInvoice}>
                                                    <Typography>No. Invoice: {invoice.nomorInvoice}</Typography>
                                                </Tooltip>
                                            </Box>
                                        </Box>
                                
                                        {invoice.daftarItem && invoice.daftarItem.length > 0 ? (   
                                        <>
                                            <Box
                                                // className="hide-scroll"
                                                // marginX={2}
                                                // mt={2}
                                                // mb={1}
                                                sx={{
                                                    overflowY: "scroll",
                                                }}
                                            >
                                                {cartItems.map((item) => (
                                                    <CardPesan
                                                        key={item.id}
                                                        id={item.id}
                                                        namaBarang={item.namaBarang}
                                                        namaKategori={item.namaKategori}
                                                        sku={String(item.sku)}
                                                        harga={item.harga}
                                                        jumlahStok={item.stok}
                                                        url={item.url}
                                                        qty={item.jumlahItem}
                                                        detailBundling={item.detailBundling}
                                                        hpp={item.hpp}
                                                        multipleHarga={item.hargaMultiple}
                                                        onRemoveItem={handleRemoveFromCartItems}
                                                        keteranganItem={item.keteranganItem}
                                                    />
                                                ))}

                                                {cart
                                                    .filter((rows) => !cartItems.some((item) => item.id === rows.id))
                                                    .map((rows) => (
                                                        <CardPesan
                                                            key={rows.id}
                                                            id={rows.id}
                                                            namaBarang={rows.namaBarang}
                                                            namaKategori={rows.namaKategori}
                                                            sku={String(rows.sku)}
                                                            harga={rows.harga}
                                                            jumlahStok={rows.jumlahStok}
                                                            url={rows.url}
                                                            qty={rows.qty}
                                                            detailBundling={rows.detailBundling}
                                                            hpp={rows.hpp}
                                                            multipleHarga={rows.multipleHarga}
                                                            keteranganItem={rows.keteranganItem}
                                                        />
                                                ))}
                                            </Box>
                                        </>
                                        ) : (
                                            <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                                                <BelumAdaPesanan width={isTabletScreen ? "20%" : "50%"} style={{ height: "fit-content" }} />
                                                <Typography
                                                    marginTop={isTabletScreen ? 2 : isPhoneScreen ? 1 : 5}
                                                    variant={isTabletScreen || isPhoneScreen ? "body2" : "h6"}
                                                    fontWeight="bold"
                                                >
                                                    Tambahkan Pesanan Anda
                                                </Typography>
                                            </Box>
                                        )}
                                    </Card>
                                ))
                                // cartOpenBill.map((invoice) => (
                                //     <Card 
                                //         key={invoice.nomorInvoice} 
                                //         variant="outlined" 
                                //         sx={{ 
                                //             marginBottom: 1, 
                                //             padding: isTabletScreen ? 1 : 2,
                                //             height: "95%",
                                //         }}
                                //     >
                                //         <Box display="grid" className="card-openBill" mb={2}>
                                //             <Typography fontWeight={700} fontSize={20}>Open Bill</Typography>
                                //             <Box flexDirection="column" width="100%" mt={1}>
                                //                 <Tooltip title={invoice.nomorInvoice}>
                                //                     <Typography>No. Invoice: {invoice.nomorInvoice}</Typography>
                                //                 </Tooltip>
                                //             </Box>
                                //         </Box>
                                
                                //         {cartItems.length > 0 ? (   
                                //             <>
                                //                 {cartItems.map((item) => (
                                //                     <CardPesan
                                //                         key={item.id}
                                //                         id={item.id}
                                //                         namaBarang={item.namaBarang}
                                //                         namaKategori={item.namaKategori}
                                //                         sku={String(item.sku)}
                                //                         harga={item.harga}
                                //                         jumlahStok={item.stok}
                                //                         url={item.url}
                                //                         qty={item.jumlahItem}
                                //                         detailBundling={item.detailBundling}
                                //                         hpp={item.hpp}
                                //                         multipleHarga={item.hargaMultiple}
                                //                     />
                                //                 ))}
                                //             </>
                                //         ) : (
                                //             <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                                //                 <BelumAdaPesanan width={isTabletScreen ? "20%" : "50%"} style={{ height: "fit-content" }} />
                                //                 <Typography
                                //                     marginTop={isTabletScreen ? 2 : isPhoneScreen ? 1 : 5}
                                //                     variant={isTabletScreen || isPhoneScreen ? "body2" : "h6"}
                                //                     fontWeight="bold"
                                //                 >
                                //                     Tambahkan Pesanan Anda
                                //                 </Typography>
                                //             </Box>
                                //         )}
                                //     </Card>
                                // ))
                            ) : (
                                cart.length > 0 ? (
                                    cart.map((rows) => (
                                        <CardPesan
                                            key={rows.id}
                                            id={rows.id}
                                            namaBarang={rows.namaBarang}
                                            namaKategori={rows.namaKategori}
                                            sku={String(rows.sku)}
                                            harga={rows.harga}
                                            jumlahStok={rows.jumlahStok}
                                            url={rows.url}
                                            qty={rows.qty}
                                            detailBundling={rows.detailBundling}
                                            hpp={rows.hpp}
                                            multipleHarga={rows.multipleHarga}
                                            keteranganItem={rows.keteranganItem}
                                        />
                                    ))
                                ) : (
                                    <Box display="flex" flex={1} flexDirection="column" alignItems="center" justifyContent="center" height="100%">
                                        <BelumAdaPesanan width={isTabletScreen ? "20%" : "50%"} style={{ height: "fit-content" }} />
                                        <Typography
                                            marginTop={isTabletScreen ? 2 : isPhoneScreen ? 1 : 5}
                                            variant={isTabletScreen || isPhoneScreen ? "body2" : "h6"}
                                            fontWeight="bold"
                                        >
                                            Belum ada pesanan
                                        </Typography>
                                    </Box>
                                )
                            )}
                        </Box>

                    </CustomTabPanel>
                )}
                {sectionNumber === 1 && (
                    <CustomTabPanel value={sectionNumber} index={1}>
                        <Grid container spacing={1} mt={1} ml={1}>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    placeholder="Cari transaksi Open Bill..."
                                    size="small"
                                    fullWidth
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyPress={(e) => {
                                        const element = e.target as HTMLInputElement;
                                        if (e.key === "Enter") {
                                            setParamsItem((prev) => ({
                                                ...prev,
                                                search: element.value,
                                                page: 1,
                                            }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton 
                                                    // onClick={handleSearch}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Box
                            className="hide-scroll"
                            marginX={2}
                            mt={2}
                            mb={1}
                            sx={{
                                overflowY: "scroll",
                            }}
                        >
                            <Grid container alignItems="center" gap={3}>
                                <Grid item xs={"auto"}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={"auto"}>
                                            <Select
                                                value={
                                                    paramsListOpenBill.tanggalAwal ===
                                                        now ||
                                                    paramsListOpenBill.tanggalAwal ===
                                                        moment()
                                                            .subtract(7, "days")
                                                            .format("YYYY-MM-DD") ||
                                                    paramsListOpenBill.tanggalAwal ===
                                                        moment()
                                                            .subtract(1, "month")
                                                            .format("YYYY-MM-DD") ||
                                                    paramsListOpenBill.tanggalAwal ===
                                                        moment()
                                                            .subtract(3, "months")
                                                            .format("YYYY-MM-DD") ||
                                                    paramsListOpenBill.tanggalAwal ===
                                                        moment()
                                                            .subtract(1, "years")
                                                            .format("YYYY-MM-DD")
                                                        ? paramsListOpenBill.tanggalAwal
                                                        : "Custom"
                                                }
                                                size="medium"
                                                sx={{ borderRadius: 2, width: 264 }}
                                                fullWidth
                                                onChange={handleChangePeriodeOpenBill}
                                            >
                                                <MenuItem value={now}>
                                                    Hari ini
                                                </MenuItem>
                                                <MenuItem
                                                    value={moment()
                                                        .subtract(7, "days")
                                                        .format("YYYY-MM-DD")}
                                                >
                                                    1 Minggu Terakhir
                                                </MenuItem>
                                                <MenuItem
                                                    value={moment()
                                                        .subtract(1, "month")
                                                        .format("YYYY-MM-DD")}
                                                >
                                                    1 Bulan Terakhir
                                                </MenuItem>
                                        
                                                <MenuItem
                                                    value={"Custom"}
                                                    sx={{ display: "none" }}
                                                >
                                                    Custom
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            <DatePicker
                                                className="range-filter"
                                                dateFormat="dd/MM/yyyy"
                                                disabledKeyboardNavigation
                                                onFocus={(e) => e.target.blur()}
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                    if (update[1]) {
                                                        handleChangeJangkaWaktuOpenBill(
                                                            moment(
                                                                update[0],
                                                            ).format("YYYY/MM/DD"),
                                                            moment(
                                                                update[1],
                                                            ).format("YYYY/MM/DD"),
                                                        );
                                                    }
                                                }}
                                                maxDate={today}
                                                minDate={subtractYears(1)}
                                                monthsShown={isPhoneScreen ? 1 : 2}
                                                customInput={
                                                    <Stack
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        direction="row"
                                                        width="100%"
                                                        bgcolor="#FFFF"
                                                        height="40px"
                                                        sx={{
                                                            border: "1px solid #cbcbcb",
                                                            borderRadius: "8px",
                                                            alignItems: "center",
                                                            paddingY: 3.4,
                                                            paddingX: 2,
                                                            width: 264,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Typography>
                                                            {moment(
                                                                paramsListOpenBill.tanggalAwal,
                                                            ).format(
                                                                "DD/MM/YYYY",
                                                            )}{" "}
                                                            -{" "}
                                                            {paramsListOpenBill.tanggalAkhir &&
                                                                moment(
                                                                    paramsListOpenBill.tanggalAkhir,
                                                                ).format(
                                                                    "DD/MM/YYYY",
                                                                )}
                                                        </Typography>
                                                        <ExpandMoreIcon
                                                            sx={{
                                                                marginRight: "-9px",
                                                                color: "#757575",
                                                            }}
                                                        />
                                                    </Stack>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            

                            {filteredInvoicesOpenBill.length > 0 ? (
                                filteredInvoicesOpenBill.map((li) => (
                                    <CardInvoice
                                        key={li.id}
                                        detailInvoiceId={li.id}
                                        noInvoice={li.nomorInvoice}
                                        tanggalInvoice={li.tanggal}
                                        hargaPenjualan={li.uangYangDibayar}
                                        isReverted={li.isReverted}
                                        refetchListInvoice={refetchListInvoice}
                                        metode={li.metode ?? "Tunai"}
                                        daftarItem={li.daftarItem}
                                        handleAddToCart={handleAddToCart}
                                        handleAddToCartEndOpenBill={handleAddToCartEndOpenBill}
                                        ketBeli={li.keterangan}
                                    />
                                ))
                            ) : (
                                <Box
                                    display="flex"
                                    flex={1}
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                >
                                    <BelumAdaPesanan
                                        width={isTabletScreen ? "20%" : "50%"}
                                        style={{ height: "fit-content" }}
                                    />
                                    <Typography
                                        marginTop={
                                            isTabletScreen
                                                ? 2
                                                : isPhoneScreen
                                                ? 1
                                                : 5
                                        }
                                        variant={
                                            isTabletScreen || isPhoneScreen
                                                ? "body2"
                                                : "h6"
                                        }
                                        fontWeight="bold"
                                    >
                                        Belum ada transaksi
                                    </Typography>
                                </Box>
                            )}
                            
                        </Box>
                    </CustomTabPanel>
                )}
                {sectionNumber === 2 && (
                    <CustomTabPanel value={sectionNumber} index={2}>
                        <Grid container spacing={1} mt={1} ml={1}>
                            <Grid item xs={12} md={5}>
                                <TextField
                                    placeholder="Cari transaksi..."
                                    size="small"
                                    fullWidth
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                    onKeyPress={(e) => {
                                        const element = e.target as HTMLInputElement;
                                        if (e.key === "Enter") {
                                            setParamsItem((prev) => ({
                                                ...prev,
                                                search: element.value,
                                                page: 1,
                                            }));
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton 
                                                    // onClick={handleSearch}
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Box
                            className="hide-scroll"
                            marginX={2}
                            mt={2}
                            mb={1}
                            sx={{
                                overflowY: "scroll",
                            }}
                        >
                            <Grid container alignItems="center" gap={3}>
                                <Grid item xs={"auto"}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={"auto"}>
                                            <Select
                                                value={
                                                    paramsListInvoice.tanggalAwal ===
                                                        now ||
                                                    paramsListInvoice.tanggalAwal ===
                                                        moment()
                                                            .subtract(7, "days")
                                                            .format("YYYY-MM-DD") ||
                                                    paramsListInvoice.tanggalAwal ===
                                                        moment()
                                                            .subtract(1, "month")
                                                            .format("YYYY-MM-DD") ||
                                                    paramsListInvoice.tanggalAwal ===
                                                        moment()
                                                            .subtract(3, "months")
                                                            .format("YYYY-MM-DD") ||
                                                    paramsListInvoice.tanggalAwal ===
                                                        moment()
                                                            .subtract(1, "years")
                                                            .format("YYYY-MM-DD")
                                                        ? paramsListInvoice.tanggalAwal
                                                        : "Custom"
                                                }
                                                size="medium"
                                                sx={{ borderRadius: 2, width: 264 }}
                                                fullWidth
                                                onChange={handleChangePeriode}
                                            >
                                                <MenuItem value={now}>
                                                    Hari ini
                                                </MenuItem>
                                                <MenuItem
                                                    value={moment()
                                                        .subtract(7, "days")
                                                        .format("YYYY-MM-DD")}
                                                >
                                                    1 Minggu Terakhir
                                                </MenuItem>
                                                <MenuItem
                                                    value={moment()
                                                        .subtract(1, "month")
                                                        .format("YYYY-MM-DD")}
                                                >
                                                    1 Bulan Terakhir
                                                </MenuItem>
                                                
                                                <MenuItem
                                                    value={"Custom"}
                                                    sx={{ display: "none" }}
                                                >
                                                    Custom
                                                </MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={"auto"}>
                                            <DatePicker
                                                className="range-filter"
                                                dateFormat="dd/MM/yyyy"
                                                disabledKeyboardNavigation
                                                onFocus={(e) => e.target.blur()}
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update) => {
                                                    setDateRange(update);
                                                    if (update[1]) {
                                                        handleChangeJangkaWaktu(
                                                            moment(
                                                                update[0],
                                                            ).format("YYYY/MM/DD"),
                                                            moment(
                                                                update[1],
                                                            ).format("YYYY/MM/DD"),
                                                        );
                                                    }
                                                }}
                                                maxDate={today}
                                                minDate={subtractYears(1)}
                                                monthsShown={isPhoneScreen ? 1 : 2}
                                                customInput={
                                                    <Stack
                                                        display="flex"
                                                        justifyContent="space-between"
                                                        direction="row"
                                                        width="100%"
                                                        bgcolor="#FFFF"
                                                        height="40px"
                                                        sx={{
                                                            border: "1px solid #cbcbcb",
                                                            borderRadius: "8px",
                                                            alignItems: "center",
                                                            paddingY: 3.4,
                                                            paddingX: 2,
                                                            width: 264,
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <Typography>
                                                            {moment(
                                                                paramsListInvoice.tanggalAwal,
                                                            ).format(
                                                                "DD/MM/YYYY",
                                                            )}{" "}
                                                            -{" "}
                                                            {paramsListInvoice.tanggalAkhir &&
                                                                moment(
                                                                    paramsListInvoice.tanggalAkhir,
                                                                ).format(
                                                                    "DD/MM/YYYY",
                                                                )}
                                                        </Typography>
                                                        <ExpandMoreIcon
                                                            sx={{
                                                                marginRight: "-9px",
                                                                color: "#757575",
                                                            }}
                                                        />
                                                    </Stack>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            {/* {listInvoice && listInvoice.totalElements > 0 ? (
                                listInvoice.content.map((li) => (
                                    <CardInvoice
                                        key={li.id}
                                        detailInvoiceId={li.id}
                                        noInvoice={li.nomorInvoice}
                                        tanggalInvoice={li.tanggal}
                                        hargaPenjualan={li.uangYangDibayar}
                                        isReverted={li.isReverted}
                                        refetchListInvoice={refetchListInvoice}
                                        metode={li.metode ?? "Tunai"}
                                        daftarItem={li.daftarItem}
                                        handleAddToCart={handleAddToCart}
                                        handleAddToCartEndOpenBill={handleAddToCartEndOpenBill}
                                    />
                                ))
                            ) : (
                                <Box
                                    display="flex"
                                    flex={1}
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                >
                                    <BelumAdaPesanan
                                        width={isTabletScreen ? "20%" : "50%"}
                                        style={{ height: "fit-content" }}
                                    />
                                    <Typography
                                        marginTop={
                                            isTabletScreen
                                                ? 2
                                                : isPhoneScreen
                                                ? 1
                                                : 5
                                        }
                                        variant={
                                            isTabletScreen || isPhoneScreen
                                                ? "body2"
                                                : "h6"
                                        }
                                        fontWeight="bold"
                                    >
                                        Belum ada transaksi
                                    </Typography>
                                </Box>
                            )} */}

                            {filteredInvoices.length > 0 ? (
                                filteredInvoices.map((li) => (
                                    <CardInvoice
                                        key={li.id}
                                        detailInvoiceId={li.id}
                                        noInvoice={li.nomorInvoice}
                                        tanggalInvoice={li.tanggal}
                                        hargaPenjualan={li.uangYangDibayar}
                                        isReverted={li.isReverted}
                                        refetchListInvoice={refetchListInvoice}
                                        metode={li.metode ?? "Tunai"}
                                        daftarItem={li.daftarItem}
                                        handleAddToCart={handleAddToCart}
                                        handleAddToCartEndOpenBill={handleAddToCartEndOpenBill}
                                        ketBeli={li.keterangan}
                                    />
                                ))
                            ) : (
                                <Box
                                    display="flex"
                                    flex={1}
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    height="100%"
                                >
                                    <BelumAdaPesanan
                                        width={isTabletScreen ? "20%" : "50%"}
                                        style={{ height: "fit-content" }}
                                    />
                                    <Typography
                                        marginTop={
                                            isTabletScreen
                                                ? 2
                                                : isPhoneScreen
                                                ? 1
                                                : 5
                                        }
                                        variant={
                                            isTabletScreen || isPhoneScreen
                                                ? "body2"
                                                : "h6"
                                        }
                                        fontWeight="bold"
                                    >
                                        Belum ada transaksi
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </CustomTabPanel>
                )}
                {sectionNumber === 3 && (
                    <CustomTabPanel value={sectionNumber} index={3}>
                        <DaftarPiutang
                            params={piutangParams}
                            setParams={setPiutangParams}
                        />
                    </CustomTabPanel>
                )}
                <Box
                    sx={{
                        borderTopWidth: "1px",
                        borderTopStyle: "solid",
                        borderTopColor: "divider",
                    }}
                >
                    <Box paddingX={2} paddingY={isTabletScreen ? 1 : 2}>
                        <Box display="flex" justifyContent="space-between">
                            <Typography
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                {selectedDebt
                                    ? "Piutang Terpilih"
                                    : "Subtotal Harga"}
                            </Typography>
                            <Typography
                                color={textPrimary.title}
                                fontSize={20}
                                fontWeight={500}
                            >
                                {selectedDebt
                                    ? debt?.content.find(
                                          (d) => d.id === selectedDebt,
                                      )?.nomorInvoice
                                    : toRupiah(totalHarga)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        width="100%"
                        paddingX={2}
                        paddingBottom={isTabletScreen ? 1 : 2}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isKeterangan}
                                    size={isTabletScreen ? "small" : undefined}
                                    onChange={(_, checked) => {
                                        handleIsKeterangan(checked);
                                    }}
                                />
                            }
                            label="Tambah Keterangan"
                            componentsProps={{
                                typography: {
                                    fontSize: isTabletScreen
                                        ? "12px"
                                        : undefined,
                                },
                            }}
                            sx={{
                                height: "fit-content",
                            }}
                        />
                        {isKeterangan && (
                            <TextField
                                label="Keterangan"
                                multiline
                                rows={2}
                                fullWidth
                                size={isTabletScreen ? "small" : undefined}
                                value={keteranganBeli}
                                onChange={handleChangeKeteranganBeli}
                                helperText={`${keteranganBeli?.length ?? 0}/50`}
                                inputProps={{ maxLength: 50 }}
                            />
                        )}
                    </Box>
                    <Box marginX={2} marginBottom={isTabletScreen ? 1 : 2}>
                        <Stack direction="row" spacing={2} alignItems="center">
                            {selectedDebt ? (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={() => setSelectedDebt(null)}
                                        startIcon={<ReplayIcon />}
                                        size={
                                            isTabletScreen ? "small" : "large"
                                        }
                                        fullWidth
                                    >
                                        Batalkan
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={handleDebtPayment}
                                        startIcon={<PayIcon />}
                                        size={
                                            isTabletScreen ? "small" : "large"
                                        }
                                        fullWidth
                                        disabled={!selectedDebt}
                                    >
                                        Pembayaran
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        variant="outlined"
                                        onClick={() => handleReset()}
                                        startIcon={<DeleteIcon />}
                                        size={
                                            isTabletScreen ? "small" : "large"
                                        }
                                        fullWidth
                                    >
                                        Kosongkan
                                    </Button>

                                    <LoadingButton
                                        variant="contained"
                                        // onClick={() => openModalBayar()}
                                        loading={isButtonLoading}
                                        onClick={() => cartOpenBill.length > 0 ? handlingUpdateOpenBill() : (openBill === 1 ? handlingOpenBill() : openModalBayar())}
                                        startIcon={<PayIcon />}
                                        size={isTabletScreen ? "small" : "large"}
                                        fullWidth
                                        disabled={cart.length < 1}
                                    >
                                        {cartOpenBill.length > 0 ? "Update" : (openBill === 1 ? "Pesan" : "Pembayaran")}
                                    </LoadingButton>
                                    
                                    {/* <Button
                                        variant="contained"
                                        // onClick={() => openModalBayar()}
                                        onClick={() => {
                                            if (openBill === 1) {
                                                setOpenBill(1);
                                            }
                                            openModalBayar();
                                        }}
                                        startIcon={<PayIcon />}
                                        size={isTabletScreen ? "small" : "large"}
                                        fullWidth
                                        disabled={cart.length < 1}
                                    >
                                        {openBill === 1 ? "Pesan" : "Pembayaran"}
                                    </Button> */}
                                </>
                            )}
                        </Stack>
                    </Box>
                </Box>
            </Box>
            <ModalBayar 
                handlingRincianPembayaran={handlingRincianPembayaran} 
            />
            <ModalKonfirmasiBayar
                kasir={String(user?.namaLengkap)}
                sales={sales}
                diskon={diskon}
                persentaseDiskon={persentaseDiskon}
                pajak={pajak}
                persentasePajak={persentasePajak}
                totalTagihan={Number(tagihan)}
                uangDibayar={Number(uangDibayar)}
                kembalian={Number(kembalian)}
                refetch={refetchItem}
                keteranganBeli={keteranganBeli ?? ""}
                resetKeteranganBeli={resetKeteranganBeli}
            />
        </React.Fragment>
    );
};

export default ListPesan;
