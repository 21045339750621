import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTransaksi } from "../../context/transaksiContext";
import {
    Card,
    Button,
    Box,
    Stack,
    Typography,
    TextField,
    Grid,
    useMediaQuery,
    Tooltip,
    SelectChangeEvent,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import toRupiah from "../../utils/toRupiah";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";
import { DetailBundling, IFaktur } from "../../constants/types";
import "./CardPesan.css";
import truncate from "../../utils/truncate";
import { textPrimary } from "../../constants/colors";
interface ICardPesanProps {
    id: number;
    namaBarang: string;
    namaKategori: string;
    sku: string;
    harga: number;
    url: string;
    qty: number;
    jumlahStok: number | undefined;
    detailBundling: DetailBundling[] | [];
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
    onRemoveItem?: (id: string | number) => void;
    keteranganItem: string | null;
}

interface ICart {
    id: number;
    namaBarang: string;
    namaKategori: string;
    sku: string;
    harga: number;
    url: string;
    qty: number;
    jumlahStok: number | undefined;
    detailBundling: DetailBundling[] | [];
    hpp: {
        id: number;
        faktur: string;
        hargaBeli: number;
        jumlah: number;
    }[];
    faktur: IFaktur[];
    multipleHarga: {
        id: number;
        harga: number;
        keterangan: string | null;
    }[];
    keteranganItem: string | null;
}

const CardPesan = ({
    id,
    namaBarang,
    namaKategori,
    sku,
    harga,
    url,
    qty,
    jumlahStok,
    detailBundling,
    hpp,
    multipleHarga,
    onRemoveItem,
    keteranganItem,
}: ICardPesanProps) => {
    const theme = useTheme();
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("md", "lg"));
    // const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const { 
        handleUpdate, 
        handleRemove, 
        isKeterangan, 
        handleIsKeterangan,
        keteranganBeli,
        setKeteranganBeli,
        handleChangeKeteranganBeli,
    } = useTransaksi();
    const [selectedHarga, setSelectedHarga] = useState(
        multipleHarga.length > 0 ? multipleHarga[0].harga : harga,
    );

    // const [selectedHarga, setSelectedHarga] = useState(
    //     Array.isArray(multipleHarga) && multipleHarga.length > 0 ? multipleHarga[0].harga : harga
    // );
    

    const [currentQty, setCurrentQty] = useState(qty);

    useEffect(() => {
        setCurrentQty(qty);
    }, [qty]);

    const addToCart = (data: ICart) => {
        if (data.qty >= 0) {
            handleUpdate({ ...data });
        } else {
            handleRemove({ ...data });
        }
    };

    const handleQtyChange = (newQty: number) => {
        if (newQty >= 0) {
            setCurrentQty(newQty);
            addToCart({
                id,
                namaBarang,
                namaKategori,
                sku,
                harga,
                url,
                jumlahStok,
                qty: newQty,
                detailBundling,
                hpp,
                faktur: [],
                multipleHarga,
                keteranganItem,
            });
        }
    };

    const incNum = () => {
        if (jumlahStok === undefined || currentQty < jumlahStok || jumlahStok === null) {
            handleQtyChange(currentQty + 1);
        }
    };

    const decNum = () => {
        if (currentQty > 0) {
            handleQtyChange(currentQty - 1);
        } else {
            handleRemove({
                id,
                namaBarang: "",
                namaKategori: "",
                qty: 0,
                harga: 0,
                url: "",
                detailBundling: [],
                hpp: [],
                faktur: [],
                multipleHarga: [],
                keteranganItem: "",
            });

            if (onRemoveItem) {
                onRemoveItem(id);
            }
        }
    };

    let valueQty = "0";
    let strQty = String(qty).replace(/^0+/, "");
    if (strQty[0] === "") {
        valueQty = "0";
    } else {
        valueQty = String(qty);
    }

    const handleChange = (event: SelectChangeEvent) => {
        setSelectedHarga(Number(event.target.value));
        addToCart({
            id,
            namaBarang,
            namaKategori,
            sku,
            harga: Number(event.target.value),
            url,
            jumlahStok,
            qty,
            detailBundling,
            hpp,
            faktur: [],
            multipleHarga,
            keteranganItem,
        });
    };

    const [keterangan, setKeterangan] = useState<string>(keteranganItem || ""); 


    // const incNum = () => {
    //     if (Number(jumlahStok) > qty || jumlahStok === null) {
    //         addToCart({
    //             id,
    //             namaBarang,
    //             namaKategori,
    //             sku,
    //             harga,
    //             url,
    //             jumlahStok,
    //             qty: qty + 1,
    //             detailBundling,
    //             hpp,
    //             faktur: [],
    //             multipleHarga,
    //         });
    //     }
    // };

    // const decNum = () => {
    //     if (qty >= 0) {
    //         addToCart({
    //             id,
    //             namaBarang,
    //             namaKategori,
    //             sku,
    //             harga,
    //             jumlahStok,
    //             url,
    //             qty: qty - 1,
    //             detailBundling,
    //             hpp,
    //             faktur: [],
    //             multipleHarga,
    //         });
    //     } else {
    //         addToCart({
    //             id,
    //             namaBarang,
    //             namaKategori,
    //             sku,
    //             harga,
    //             jumlahStok,
    //             url,
    //             qty: -1,
    //             detailBundling,
    //             hpp,
    //             faktur: [],
    //             multipleHarga,
    //         });
    //     }
    // };

    const detailItemSatuan = detailBundling
        .map((li, idx, arr) => `${li.namaBarang} (${li.jumlahYangDiambil})`)
        .join(", ");

    // const detailItemSatuan = (Array.isArray(detailBundling) ? detailBundling : [])
    //     .map((li) => `${li.namaBarang} (${li.jumlahYangDiambil})`)
    //     .join(", ");

    // const handlerMaxItem = () => {
    //     if (qty > Number(jumlahStok) && jumlahStok !== null) {
    //         setErrorMaxItem(true);
    //         handlerErrorMaxItem();
    //     } else {
    //         setErrorMaxItem(false);
    //         resetErrorMaxItem();
    //     }
    // };

    // useEffect(() => {
    //     handlerMaxItem();
    // }, [qty]);

    return (
        <Card
            variant="outlined"
            sx={{
                marginBottom: 1,
                padding: isTabletScreen ? 1 : 2,
            }}
        >
            <Box display="grid" className="card-pesan">
                <Box flexDirection="column" width="100%">
                    <Tooltip title={namaBarang}>
                        <Typography>{truncate(namaBarang, 20)}</Typography>
                    </Tooltip>
                    <Typography fontSize={12} color="#76747C">
                        {detailItemSatuan}
                    </Typography>
                    <Grid container my={isTabletScreen ? 0.5 : 1}>
                        <Grid item>
                            {multipleHarga.length > 1 ? (
                                <Select
                                    placeholder="Pilih Stok"
                                    margin="dense"
                                    size="small"
                                    onChange={handleChange}
                                    value={String(selectedHarga)}
                                    sx={{
                                        minHeight: "unset",
                                        color: textPrimary.light,
                                    }}
                                    fullWidth
                                >
                                    {multipleHarga.map((li) => (
                                        <MenuItem value={li.harga}>
                                            {`@${toRupiah(Number(li.harga))} ${
                                                li.keterangan === "-''-" ||
                                                li.keterangan === null
                                                    ? ""
                                                    : `(${li.keterangan})`
                                            }`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            ) : multipleHarga.length === 1 ? (
                                <Typography color="#76747C">
                                    {`@${toRupiah(
                                        Number(multipleHarga[0].harga),
                                    )}`}
                                </Typography>
                            ) : (
                                <Typography color="#76747C">
                                    {toRupiah(harga)}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Typography fontWeight="500">
                        {multipleHarga.length > 0
                            ? toRupiah(Number(selectedHarga) * currentQty)
                            : toRupiah(harga * currentQty)}
                    </Typography>
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="center"
                >
                    <Stack
                        direction="row"
                        width="100%"
                        gridColumn={isPhoneScreen ? "1/3" : "unset"}
                        display="grid"
                        gridTemplateColumns="auto 1fr auto"
                    >
                        <Button
                            onClick={decNum}
                            variant="outlined"
                            sx={{
                                minWidth: "unset",
                                padding: "6px 10px",
                                width: "fit-content",
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                        >
                            {/* {qty === 0 ? (
                                <DeleteIcon color="primary" />
                            ) : (
                                <RemoveIcon color="primary" />
                            )} */}
                            {currentQty === 0 ? 
                                <DeleteIcon color="primary" /> 
                                : <RemoveIcon color="primary" />
                            }

                        </Button>
                        <TextField
                            value={currentQty}
                            type="number"
                            size="small"
                            onWheel={(e) => {
                                const target =
                                    e.target as HTMLButtonElement;
                                if (target) {
                                    target.blur();
                                    e.stopPropagation();
                                    setTimeout(
                                        () => {
                                            target.focus();
                                        },
                                        0,
                                    );
                                }
                            }}
                            onKeyDown={(evt) =>
                                [
                                    "e",
                                    "E",
                                    "+",
                                    "-",
                                ].includes(
                                    evt.key,
                                ) &&
                                evt.preventDefault()
                            }
                            // onChange={(
                            //     event: React.ChangeEvent<HTMLInputElement>,
                            // ) => {
                            //     addToCart({
                            //         id,
                            //         namaBarang,
                            //         namaKategori,
                            //         sku,
                            //         harga,
                            //         url,
                            //         jumlahStok,
                            //         qty: Number(event.target.value),
                            //         detailBundling,
                            //         hpp,
                            //         faktur: [],
                            //         multipleHarga,
                            //     });
                            // }}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                const inputValue = event.target.value;
                                const numericValue = inputValue.replace(/\D/g, "");
                                const newQty = numericValue === "" ? 0 : Number(numericValue);
                                
                                setCurrentQty(newQty);
                        
                                addToCart({
                                    id,
                                    namaBarang,
                                    namaKategori,
                                    sku,
                                    harga,
                                    url,
                                    jumlahStok,
                                    qty: newQty,
                                    detailBundling,
                                    hpp,
                                    faktur: [],
                                    multipleHarga,
                                    keteranganItem,
                                });
                            }}
                            inputProps={{
                                style: {
                                    textAlign: "center",
                                    backgroundColor: "white",
                                    fontWeight: "bold",
                                    minWidth: "40px",
                                },
                            }}
                            InputProps={{ style: { borderRadius: 0 } }}
                        />
                        <Button
                            disabled={
                                currentQty >= Number(jumlahStok) && jumlahStok !== null
                            }
                            // disabled={jumlahStok !== undefined && currentQty >= jumlahStok}
                            onClick={incNum}
                            variant="contained"
                            sx={{
                                minWidth: "unset",
                                padding: "6px 10px",
                                width: "fit-content",
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            }}
                        >
                            <AddIcon color="inherit" />
                        </Button>
                    </Stack>
                    <Grid mt={1}>
                        <Typography
                            fontSize={12}
                            fontWeight={300}
                            color="#76747c"
                        >
                            {jumlahStok !== null
                                ? "Max. " + jumlahStok
                                : "Tanpa Stok"}
                        </Typography>
                    </Grid>
                </Box>
            </Box>
            
            <Box
                width="100%"
                // paddingX={2}
                paddingBottom={isTabletScreen ? 1 : 2}
                mt={2}
            >
                {/* <TextField
                    label="Keterangan"
                    multiline
                    rows={2}
                    fullWidth
                    size={isTabletScreen ? "small" : undefined}
                    value={keteranganItem}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newKeterangan = event.target.value;

                        // Pastikan perubahan langsung masuk ke `addToCart`
                        addToCart({
                            id,
                            namaBarang,
                            namaKategori,
                            sku,
                            harga,
                            url,
                            jumlahStok,
                            qty: currentQty,
                            detailBundling,
                            hpp,
                            faktur: [],
                            multipleHarga,
                            keteranganItem: newKeterangan, // Update keteranganItem
                        });
                    }}
                    inputProps={{ maxLength: 50 }}
                /> */}

                <TextField
                    label="Keterangan"
                    multiline
                    rows={2}
                    fullWidth
                    size={isTabletScreen ? "small" : undefined}
                    value={keterangan} // Menggunakan state yang diperbaiki
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        const newKeterangan = event.target.value;
                        setKeterangan(newKeterangan); // Perbarui state lokal

                        addToCart({
                            id,
                            namaBarang,
                            namaKategori,
                            sku,
                            harga,
                            url,
                            jumlahStok,
                            qty: currentQty,
                            detailBundling,
                            hpp,
                            faktur: [],
                            multipleHarga,
                            keteranganItem: newKeterangan, // Update keteranganItem ke addToCart
                        });
                    }}
                    inputProps={{ maxLength: 50 }}
                />

            </Box>

            

            {/* <Stack
                display="flex"
                direction={{ xs: "column", sm: "row" }}
                width="100%"
                spacing={2}
                sx={{
                    alignItems: { xs: "flex-start", sm: "center" },
                    justifyContent: {
                        xs: "space-between",
                        sm: "space-between",
                    },
                }}
            >
                <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                        sx={{
                            backgroundColor: "primary.main",
                            width: 65,
                            height: 70,
                        }}
                        variant="rounded"
                        alt={namaBarang}
                        src={url}
                    />
                    <Box width="100%">
                        <Typography variant="h6" fontWeight="bold">
                            {namaBarang}
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item md={5}>
                                <Typography
                                    variant="subtitle2"
                                    color="#76747C"
                                    fontWeight="bold"
                                >
                                    {toRupiah(harga)}
                                </Typography>
                            </Grid>
                            <Grid item md={4}>
                                <Typography
                                    variant="subtitle2"
                                    color="#76747C"
                                    fontWeight="bold"
                                >
                                    x{count}
                                </Typography>
                            </Grid>
                            <Grid item md={4}></Grid>
                        </Grid>
                        <Typography
                            variant="subtitle1"
                            color="primary"
                            fontWeight="bold"
                        >
                            {toRupiah(harga * count)}
                        </Typography>
                    </Box>
                </Stack>
                <Stack
                    direction="row"
                    width="100%"
                    spacing={1}
                    alignItems="center"
                >
                    <Button
                        onClick={decNum}
                        variant="outlined"
                        sx={{ minWidth: 30, minHeight: 30 }}
                    >
                        {qty === 1 ? (
                            <DeleteForeverIcon color="primary" />
                        ) : (
                            <RemoveIcon color="primary" />
                        )}
                    </Button>
                    <TextField
                        value={qty}
                        type="number"
                        size="small"
                        onWheel={(e) => {
                            const target =
                                e.target as HTMLButtonElement;
                            if (target) {
                                target.blur();
                                e.stopPropagation();
                                setTimeout(
                                    () => {
                                        target.focus();
                                    },
                                    0,
                                );
                            }
                        }}
                        onKeyDown={(evt) =>
                            [
                                "e",
                                "E",
                                "+",
                                "-",
                            ].includes(
                                evt.key,
                            ) &&
                            evt.preventDefault()
                        }
                        sx={{ minWidth: 60, minHeight: 30 }}
                        onChange={(
                            event: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                            setCount(Number(event.target.value));
                            addToCart({
                                id,
                                namaBarang,
                                sku,
                                harga,
                                url,
                                jumlahStok,
                                qty: Number(event.target.value),
                            });
                        }}
                        inputProps={{
                            style: {
                                textAlign: "center",
                                backgroundColor: "white",
                                fontWeight: "bold",
                            },
                        }}
                    />
                    <Button
                        disabled={
                            count >= Number(jumlahStok) && jumlahStok !== null
                        }
                        onClick={incNum}
                        variant="contained"
                        sx={{ minWidth: 30, minHeight: 30 }}
                    >
                        <AddIcon color="inherit" />
                    </Button>
                </Stack>
            </Stack> */}
            {qty > Number(jumlahStok) &&
                jumlahStok !== null &&
                qty !== null && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <Typography
                            color="red"
                            variant="subtitle2"
                            fontWeight="bold"
                        >
                            Jumlah pesanan melebihi stok
                        </Typography>
                    </Box>
                )}
        </Card>
    );
};

export default CardPesan;
