import React, { useEffect, useState, useMemo } from "react";
import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Stack,
    FormHelperText,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
    Switch,
} from "@mui/material";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
    SelectedProductBundling,
    ProductsBundling,
    BundlingValues,
    AddBundlingResponse,
    ErrorFieldResponse,
    ModalBundlingField,
    ParamsGetKategori,
} from "../../../constants/types";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import NumberFormat from "react-number-format";
import { useStokModal } from "../../../context/stokModalContext";
import useKategori from "../../../services/queries/useKategori";
import Spinner from "../../Spinner/Spinner";
import useUser from "../../../services/queries/useUser";
import toRupiah from "../../../utils/toRupiah";
import "./DetailItemStep.css";
import axios from "../../../services/axios";
import defaultAxios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import { useTransaksi } from "../../../context/transaksiContext";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../../context/authContext";
import formatHarga from "../../../utils/formatHarga";
import Delete from "@mui/icons-material/Delete";
import Add from "@mui/icons-material/Add";
import { textPrimary } from "../../../constants/colors";
import InputLabel from "@mui/material/InputLabel";

interface IDetailItemStepProps {
    products: SelectedProductBundling[];
    onBack: () => void;
    dataStokBundle: ModalBundlingField;
}

const FormSchema = Yup.object({
    item: Yup.array().of(
        Yup.object().shape({
            itemId: Yup.number(),
            jumlahItem: Yup.string()
                .required("Kolom wajib diisi")
                .test({
                    name: "min-1",
                    test: (value) => Number(value) > 0,
                    message: "Jumlah barang tidak boleh kurang dari 1",
                }),
        }),
    ),
    isActiveStok: Yup.boolean(),
    isKategoriBaru: Yup.boolean(),
    namaBundling: Yup.string()
        .required("Kolom wajib diisi")
        .max(50, "Nama bundling terlalu panjang"),
    deskripsiBundling: Yup.string()
        .nullable()
        .max(500, "Deskripsi terlalu panjang"),
    sku: Yup.string()
        .required("Kolom wajib diisi")
        .max(50, "SKU terlalu panjang"),
    multipleHarga: Yup.array().of(
        Yup.object().shape({
            harga: Yup.string().required("Kolom wajib diisi"),
            keterangan: Yup.string().nullable(),
        }),
    ),
    jumlahStok: Yup.string()
        .nullable()
        .when("isActiveStok", {
            is: true,
            then: Yup.string().required("Kolom wajib diisi"),
        }),
    kategoriBundling: Yup.string().when("isKategoriBaru", {
        is: false,
        then: Yup.string().required("Kolom wajib diisi"),
    }),
    kategoriBaru: Yup.string().when("isKategoriBaru", {
        is: true,
        then: Yup.string()
            .required("Kolom wajib diisi")
            .max(50, "Kategori baru terlalu panjang"),
    }),
    gambar: Yup.mixed(),
    favorite: Yup.boolean().required("Kolom wajib diisi"),
});

const DetailItemStep = ({
    products,
    onBack,
    dataStokBundle,
}: IDetailItemStepProps) => {
    const { ukmIdUser } = useAuth();
    const { setFetchingItems } = useTransaksi();
    const { dataStok, closeModal } = useStokModal();
    const { data: user, status: statusUser } = useUser();
    const initialValues: BundlingValues = useMemo(
        () => ({
            id: undefined,
            item: products.map((li) => ({
                itemId: li.id,
                jumlahItem: 1,
            })) ?? [
                {
                    itemId: 1,
                    jumlahItem: 1,
                },
            ],
            namaBundling: "",
            deskripsiBundling: "",
            kategoriBundling: "",
            subKategori: "",
            sku: "",
            hargaBundling: "",
            isActiveStok: true,
            jumlahStok: 1,
            isKategoriBaru: false,
            kategoriBaru: "",
            gambar: null,
            favorite: false,
            multipleHarga: [{ harga: undefined, keterangan: null }],
        }),
        [products],
    );

    const initialParamsKategori: ParamsGetKategori = {
        ukmId: undefined,
        search: "",
        size: 500,
        page: 1,
    };

    const [paramsKategori, setParamsKategori] = useState(initialParamsKategori);

    const { data: kategori, status: statusKategori } =
        useKategori(paramsKategori);

    const {
        handleSubmit,
        control,
        formState: { errors },
        register,
        reset,
        setValue,
        setError,
        clearErrors,
        watch,
    } = useForm<BundlingValues>({
        resolver: yupResolver(FormSchema),
        defaultValues: initialValues,
    });
    const { fields, append, remove } = useFieldArray({
        control,
        name: `multipleHarga`,
    });

    const selectedKategoriId = watch("subKategori");

    const addHarga = () => {
        const newHarga = {
            harga: "",
            keterangan: null,
        };
        append(newHarga);
    };
    useEffect(() => {
        if (user) {
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser, user]);

    const generateSKU = () =>
        watch("namaBundling") === ""
            ? ""
            : watch("namaBundling")
                  .toUpperCase()
                  .replace(" ", "")
                  .substring(0, 4) +
              "-" +
              String(Math.floor(Math.random() * 10000) + 1);
    const isKategoriBaru = watch("isKategoriBaru");
    const itemField = watch("item");

    const [isButtonLoading, setIsButtonLoading] = useState(false);

    const onSubmit = async (values: BundlingValues) => {
        const formattedHarga = String(values.hargaBundling).replace(/\./g, "");
        setIsButtonLoading(true);
        if (dataStok) {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(ukmIdUser && ukmIdUser[0]));
                formData.append("namaBundling", values.namaBundling);
                formData.append("deskripsiBundling", values.deskripsiBundling);
                formData.append(
                    "kategoriBundling",
                    values.isKategoriBaru === true
                        ? ""
                        : String(values.kategoriBundling),
                );
                formData.append("subKategori", String(values.subKategori));
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "kategoriBaru",
                    values.isKategoriBaru === true ? values.kategoriBaru : "",
                );
                for (var i = 0; i < values.item.length; i += 1) {
                    formData.append(
                        `item[${i}][itemId]`,
                        String(values.item[i].itemId),
                    );
                    formData.append(
                        `item[${i}][jumlahItem]`,
                        String(values.item[i].jumlahItem),
                    );
                    console.log(formData.get(`item[${i}][itemId]`));
                    console.log(formData.get(`item[${i}][jumlahStok]`));
                }
                formData.append("hargaBundling", formattedHarga);
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true ? "1" : "0",
                );
                formData.append(
                    "favorite",
                    values.favorite === true ? "1" : "0",
                );
                formData.append("isShow", values.isShow === true ? "1" : "0");
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `hargaBundling[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `hargaBundling[${i}][keterangan]`,
                        String(values.multipleHarga[i].keterangan),
                    );
                }
                const { data } = await axios.post<AddBundlingResponse>(
                    `api/item/bundlingupdate/${values.id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );

                if (data.code === 200) {
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    closeModal();
                    setIsButtonLoading(false);
                }
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${
                                    fieldError?.message ?? "Terjadi Kesalahan!"
                                }`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                setIsButtonLoading(false);
            }
        } else {
            try {
                const formData = new FormData();
                formData.append("ukmId", String(ukmIdUser && ukmIdUser[0]));
                formData.append("namaBundling", values.namaBundling);
                formData.append("deskripsiBundling", values.deskripsiBundling);
                formData.append(
                    "kategoriBundling",
                    values.isKategoriBaru === true
                        ? ""
                        : String(values.kategoriBundling),
                );
                formData.append("subKategori", String(values.subKategori));
                formData.append("sku", values.sku);
                if (values.gambar) {
                    formData.append(
                        "gambar",
                        values.gambar ? (values.gambar as any) : "",
                    );
                }
                formData.append(
                    "kategoriBaru",
                    values.isKategoriBaru === true ? values.kategoriBaru : "",
                );
                for (var j = 0; j < values.item.length; j += 1) {
                    formData.append(
                        `item[${j}][itemId]`,
                        String(values.item[j].itemId),
                    );
                    formData.append(
                        `item[${j}][jumlahItem]`,
                        String(values.item[j].jumlahItem),
                    );
                    console.log(formData.get(`item[${j}][itemId]`));
                    console.log(formData.get(`item[${j}][jumlahStok]`));
                }
                formData.append("hargaBundling", formattedHarga);
                formData.append(
                    "isActiveStok",
                    values.isActiveStok === true ? "1" : "0",
                );
                formData.append(
                    "jumlahStok",
                    values.isActiveStok === true
                        ? String(values.jumlahStok)
                        : values.isActiveStok === false
                        ? ""
                        : "",
                );
                formData.append(
                    "favorite",
                    values.favorite === true ? "1" : "0",
                );
                for (let i = 0; i < values.multipleHarga.length; i += 1) {
                    formData.append(
                        `hargaBundling[${i}][harga]`,
                        formatHarga(String(values.multipleHarga[i].harga)),
                    );
                    formData.append(
                        `hargaBundling[${i}][keterangan]`,
                        String(values.multipleHarga[i].keterangan),
                    );
                }
                const { data } = await axios.post<AddBundlingResponse>(
                    "api/item/bundling",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                );

                if (data.code === 200) {
                    Swal.fire({
                        title: `${data.message}`,
                        position: "top-end",
                        showConfirmButton: false,
                        icon: "success",
                        toast: true,
                        timer: 3000,
                        timerProgressBar: true,
                        showCloseButton: true,
                        customClass: {
                            container: "my-swal",
                        },
                    });
                    setFetchingItems();
                    closeModal();
                    setIsButtonLoading(false);
                }
            } catch (error) {
                if (defaultAxios.isAxiosError(error)) {
                    const serverError = error as AxiosError<
                        ErrorFieldResponse | undefined
                    >;
                    if (serverError && serverError?.response) {
                        const fieldError = serverError?.response?.data;
                        if (fieldError?.errors) {
                            Object.keys(fieldError.errors).forEach((key) => {
                                const errorMessage = fieldError.errors[key];
                                setError(key as keyof typeof initialValues, {
                                    type: "manual",
                                    message: errorMessage[0],
                                });
                            });
                        } else {
                            Swal.fire({
                                title: `${
                                    fieldError?.message ?? "Terjadi Kesalahan!"
                                }`,
                                position: "top-end",
                                showConfirmButton: false,
                                icon: "error",
                                toast: true,
                                timer: 3000,
                                timerProgressBar: true,
                                showCloseButton: true,
                                customClass: {
                                    container: "my-swal",
                                },
                            });
                        }
                    }
                }
                setIsButtonLoading(false);
            }
        }
    };

    const countHargaTotal = (
        products: SelectedProductBundling[],
        item: ProductsBundling[],
    ) => {
        let total = 0;
        if (item.length === products.length) {
            products.forEach((li, idx) => {
                const harga = li.harga;
                const jumlah = item[idx].jumlahItem;
                const subtotal = Number(harga) * Number(jumlah);
                total += subtotal;
            });
        }
        return total;
    };

    useEffect(() => {
        if (products) {
            const total = countHargaTotal(products, itemField);
            if (total > 0) {
                setValue("hargaBundling", String(total));
            }
        }
    }, [itemField, products, setValue]);

    useEffect(() => {
        const subscription = watch((_, { name, type }) => {
            if (name?.match(/item/i) && type === "change") {
                if (products) {
                    const total = countHargaTotal(products, itemField);
                    if (total > 0) {
                        setValue("hargaBundling", String(total));
                    }
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, itemField, products, setValue]);

    useEffect(() => {
        if (dataStok) {
            reset({
                ...dataStokBundle,
                multipleHarga: dataStokBundle.hargaMultiple,
            });
            console.log("Data Bundle", dataStokBundle);
        }
    }, [reset, initialValues, dataStok, dataStokBundle]);

    const isLoading = statusKategori === "loading" || statusUser === "loading";

    if (isLoading)
        return (
            <Stack
                display="grid"
                gridTemplateColumns="1fr"
                alignItems="center"
                height={400}
                position="relative"
            >
                <Spinner />
            </Stack>
        );

    return (
        <Box className="detail-bundling">
            <form>
                <Stack
                    mb={5}
                    direction="column"
                    spacing={2}
                    p={2}
                    border={`1px solid ${textPrimary.thin}`}
                    borderRadius="8px"
                >
                    <Typography
                        color={textPrimary.title}
                        fontSize={20}
                        fontWeight={500}
                    >
                        Isi Bundling/Paket
                    </Typography>
                    {products &&
                        products?.map((li, idx) => (
                            <Stack
                                key={String(li.id)}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Box>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        {li.nama} ({toRupiah(li.harga ?? 0)})
                                    </Typography>
                                    {li.stok ? (
                                        <Typography>
                                            Jumlah stok saat ini:{" "}
                                            <b>{li.stok}</b>
                                        </Typography>
                                    ) : (
                                        <Typography>Tidak ada stok</Typography>
                                    )}
                                </Box>
                                <Controller
                                    name={`item.${idx}.jumlahItem`}
                                    control={control}
                                    render={({ field, fieldState }) => (
                                        <div>
                                            <Typography
                                                color={textPrimary.body}
                                                fontWeight={500}
                                            >
                                                Jumlah pada Bundling/Paket
                                            </Typography>
                                            <TextField
                                                type="number"
                                                id={`item.${idx}.jumlahItem`}
                                                placeholder="Jumlah Barang"
                                                fullWidth
                                                variant="outlined"
                                                onWheel={(e) => {
                                                    const target =
                                                        e.target as HTMLButtonElement;
                                                    if (target) {
                                                        target.blur();
                                                        e.stopPropagation();
                                                        setTimeout(
                                                            () => {
                                                                target.focus();
                                                            },
                                                            0,
                                                        );
                                                    }
                                                }}
                                                onKeyDown={(evt) =>
                                                    [
                                                        "e",
                                                        "E",
                                                        "+",
                                                        "-",
                                                    ].includes(
                                                        evt.key,
                                                    ) &&
                                                    evt.preventDefault()
                                                }
                                                error={!!fieldState?.error}
                                                helperText={
                                                    fieldState?.error &&
                                                    fieldState?.error.message
                                                }
                                                {...field}
                                            />
                                        </div>
                                    )}
                                />
                                <input
                                    type="hidden"
                                    {...register(`item.${idx}.itemId`)}
                                    value={li.id}
                                />
                            </Stack>
                        ))}
                </Stack>
                <Grid
                    container
                    p={2}
                    rowSpacing={2}
                    border={`1px solid ${textPrimary.thin}`}
                    borderRadius="8px"
                >
                    <Typography
                        color={textPrimary.title}
                        fontSize={20}
                        fontWeight={500}
                    >
                        Deskripsi Bundling/Paket
                    </Typography>
                    <Grid item xs={12}>
                        <Controller
                            name="namaBundling"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        Nama Bundling/Paket
                                    </Typography>
                                    <TextField
                                        id="namaBundling"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.namaBundling)}
                                        helperText={
                                            errors.namaBundling
                                                ? errors.namaBundling.message
                                                : ""
                                        }
                                        {...field}
                                        onBlur={() => {
                                            if (dataStok) {
                                                field.onBlur();
                                            } else {
                                                setValue("sku", generateSKU());
                                                clearErrors("sku");
                                                field.onBlur();
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            name="sku"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        SKU
                                    </Typography>
                                    <TextField
                                        id="sku"
                                        fullWidth
                                        variant="outlined"
                                        error={Boolean(errors.sku)}
                                        helperText={
                                            errors.sku ? errors.sku.message : ""
                                        }
                                        {...field}
                                    />
                                </div>
                            )}
                            defaultValue={generateSKU()}
                        />
                    </Grid>
                    <Grid item xs={12} marginTop={2}>
                        <Typography color={textPrimary.body} fontWeight={500}>
                            Multiple Harga
                        </Typography>
                        <Typography
                            color={textPrimary.light}
                            fontSize={12}
                            fontWeight={300}
                        >
                            Anda dapat memberi beberapa harga berbeda untuk
                            barang yang sama. Multiple harga hanya digunakan
                            pada halaman penjualan.
                        </Typography>
                    </Grid>
                    <Grid container spacing={2}>
                        {fields.map((field, idx) => (
                            <React.Fragment key={field.id}>
                                <Grid item xs={5} sm={4}>
                                    <Controller
                                        name={`multipleHarga.${idx}.harga`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div>
                                                <Typography
                                                    color={textPrimary.body}
                                                    fontWeight={500}
                                                >
                                                    Harga {idx + 1}
                                                </Typography>
                                                <NumberFormat
                                                    customInput={TextField}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    allowNegative={false}
                                                    fullWidth
                                                    variant="outlined"
                                                    onValueChange={(values) => {
                                                        const { value } =
                                                            values;
                                                        const valueAkhir =
                                                            value.replace(
                                                                ".",
                                                                "",
                                                            );
                                                        console.log(valueAkhir);
                                                        setValue(
                                                            `multipleHarga.${idx}.harga`,
                                                            Number(valueAkhir),
                                                        );
                                                    }}
                                                    error={!!fieldState?.error}
                                                    helperText={
                                                        fieldState?.error &&
                                                        fieldState?.error
                                                            .message
                                                    }
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Typography>
                                                                    Rp.
                                                                </Typography>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    {...field}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={5} sm={7}>
                                    <Controller
                                        name={`multipleHarga.${idx}.keterangan`}
                                        control={control}
                                        render={({ field, fieldState }) => (
                                            <div>
                                                <Typography
                                                    color={textPrimary.body}
                                                    fontWeight={500}
                                                >
                                                    Keterangan
                                                </Typography>
                                                <TextField
                                                    id={`multipleHarga.${idx}.keterangan`}
                                                    placeholder="Masukkan Keterangan Harga"
                                                    fullWidth
                                                    variant="outlined"
                                                    error={!!fieldState?.error}
                                                    helperText={
                                                        fieldState?.error &&
                                                        fieldState?.error
                                                            .message
                                                    }
                                                    {...field}
                                                />
                                            </div>
                                        )}
                                    />
                                </Grid>
                                {idx === 0 ? null : (
                                    <Grid item xs={1}>
                                        <Button
                                            variant="contained"
                                            onClick={() => remove(idx)}
                                            size="large"
                                            color="buttonred"
                                            sx={{
                                                width: "16px",
                                                height: "54px",
                                                marginTop: 3,
                                            }}
                                        >
                                            <Delete />
                                        </Button>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                    <Grid item xs={3} justifyContent="center">
                        <Button
                            variant="contained"
                            onClick={() => addHarga()}
                            startIcon={<Add />}
                            color="buttongreen"
                            fullWidth
                        >
                            Tambah Harga
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Controller
                            name="deskripsiBundling"
                            control={control}
                            render={({ field }) => (
                                <div>
                                    <Typography
                                        color={textPrimary.body}
                                        fontWeight={500}
                                    >
                                        Deskripsi{" "}
                                        <span
                                            style={{ color: textPrimary.light }}
                                        >
                                            (opsional)
                                        </span>
                                    </Typography>
                                    <TextField
                                        id="deskripsiBundling"
                                        fullWidth
                                        variant="outlined"
                                        multiline
                                        rows={4}
                                        error={Boolean(
                                            errors.deskripsiBundling,
                                        )}
                                        helperText={
                                            errors.deskripsiBundling
                                                ? errors.deskripsiBundling
                                                      .message
                                                : ""
                                        }
                                        {...field}
                                    />
                                </div>
                            )}
                        />
                    </Grid>
                </Grid>
                <Typography color={textPrimary.body} fontWeight={500} mt={2}>
                    Gambar{" "}
                    <span style={{ color: textPrimary.light }}>(opsional)</span>
                </Typography>
                <Stack>
                    <Controller
                        name="gambar"
                        control={control}
                        render={({ field }) => (
                            <TextField
                                id="gambar"
                                fullWidth
                                type="file"
                                variant="outlined"
                                inputProps={{
                                    accept: "image/*",
                                }}
                                error={Boolean(errors.gambar)}
                                helperText={
                                    errors.gambar ? errors.gambar.message : ""
                                }
                                {...field}
                                onChange={(event: any) => {
                                    if (event.target.files[0].size < 2097152) {
                                        console.log(event.target.files[0]);
                                        setValue(
                                            "gambar",
                                            event.target.files[0],
                                        );
                                        clearErrors("gambar");
                                    } else {
                                        event.target.value = "";
                                        setError("gambar", {
                                            type: "manual",
                                            message:
                                                "Ukuran gambar harus kurang dari 2MB",
                                        });
                                    }
                                }}
                                value={undefined}
                            />
                        )}
                    />
                    <Typography variant="caption" color="#6D6D6D">
                        *Resolusi optimal: 300 x 300
                    </Typography>
                    <Typography variant="caption" color="#6D6D6D">
                        *Ukuran maksimal: 2MB
                    </Typography>
                </Stack>

                {/* <Grid container marginTop={2}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        width="100%"
                    >
                        <div>
                            <Typography
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Kategori Barang
                            </Typography>
                            <Typography
                                color={textPrimary.light}
                                fontSize={12}
                                fontWeight={300}
                            >
                                Aktifkan opsi jika ingin menambahkan sub
                                kategori baru
                            </Typography>
                        </div>
                        <Controller
                            name="isKategoriBaru"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    control={<Checkbox {...field} />}
                                    label="Tambah Sub Kategori Baru"
                                    componentsProps={{
                                        typography: { color: textPrimary.body },
                                    }}
                                />
                            )}
                        />
                    </Stack>
                </Grid>

                <Grid container columnSpacing={2} marginTop={1}>
                    <Grid item xs={12} sm={6}>
                        {isKategoriBaru ? (
                            <Controller
                                name="kategoriBaru"
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <Typography
                                            color={textPrimary.body}
                                            fontSize={14}
                                            fontWeight={500}
                                        >
                                            Kategori
                                        </Typography>
                                        <TextField
                                            id="kategoriBaru"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.kategoriBaru)}
                                            helperText={
                                                errors.kategoriBaru
                                                    ? errors.kategoriBaru
                                                          .message
                                                    : " "
                                            }
                                            {...field}
                                        />
                                    </div>
                                )}
                            />
                        ) : (
                            <FormControl fullWidth disabled={isKategoriBaru}>
                                <Controller
                                    control={control}
                                    name="kategoriBundling"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormControl fullWidth>
                                            <Typography
                                                color={textPrimary.body}
                                                fontSize={14}
                                                fontWeight={500}
                                            >
                                                Kategori
                                            </Typography>
                                            <Select
                                                margin="dense"
                                                onChange={onChange}
                                                value={value}
                                                error={Boolean(
                                                    errors.kategoriBundling,
                                                )}
                                            >
                                                <MenuItem value="">
                                                    -- Pilih Kategori --
                                                </MenuItem>
                                                {kategori &&
                                                    kategori.totalElements >
                                                        0 &&
                                                    kategori.content.map(
                                                        (k) => (
                                                            <MenuItem
                                                                key={k.id}
                                                                value={k.id}
                                                            >
                                                                {k.nama}
                                                            </MenuItem>
                                                        ),
                                                    )}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                                <FormHelperText
                                    error={Boolean(errors.kategoriBundling)}
                                >
                                    {errors.kategoriBundling
                                        ? errors.kategoriBundling.message
                                        : " "}
                                </FormHelperText>
                            </FormControl>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {isKategoriBaru ? (
                            <Controller
                                name="kategoriBaru"
                                control={control}
                                render={({ field }) => (
                                    <div>
                                        <Typography
                                            color={textPrimary.body}
                                            fontSize={14}
                                            fontWeight={500}
                                        >
                                            Sub Kategori
                                        </Typography>
                                        <TextField
                                            id="kategoriBaru"
                                            fullWidth
                                            variant="outlined"
                                            error={Boolean(errors.kategoriBaru)}
                                            helperText={
                                                errors.kategoriBaru
                                                    ? errors.kategoriBaru
                                                          .message
                                                    : " "
                                            }
                                            {...field}
                                        />
                                    </div>
                                )}
                            />
                        ) : (
                            <FormControl fullWidth disabled={isKategoriBaru}>
                                <Controller
                                    control={control}
                                    name="kategoriBundling"
                                    render={({
                                        field: { onChange, value },
                                    }) => (
                                        <FormControl fullWidth>
                                            <Typography
                                                color={textPrimary.body}
                                                fontSize={14}
                                                fontWeight={500}
                                            >
                                                Sub Kategori
                                            </Typography>
                                            <Select
                                                margin="dense"
                                                onChange={onChange}
                                                value={value}
                                                error={Boolean(
                                                    errors.kategoriBundling,
                                                )}
                                            >
                                                <MenuItem value="">
                                                    -- Pilih Kategori --
                                                </MenuItem>
                                                {kategori &&
                                                    kategori.totalElements >
                                                        0 &&
                                                    kategori.content.map(
                                                        (k) => (
                                                            <MenuItem
                                                                key={k.id}
                                                                value={k.id}
                                                            >
                                                                {k.nama}
                                                            </MenuItem>
                                                        ),
                                                    )}
                                            </Select>
                                        </FormControl>
                                    )}
                                />
                                <FormHelperText
                                    error={Boolean(errors.kategoriBundling)}
                                >
                                    {errors.kategoriBundling
                                        ? errors.kategoriBundling.message
                                        : " "}
                                </FormHelperText>
                            </FormControl>
                        )}
                    </Grid>
                </Grid> */}

<Grid
                    container
                    alignItems="center"
                    justifyContent={"space-between"}
                    marginTop={2}
                >
                    <Grid item xs={5}>
                        <Typography fontWeight={500} color={"#464E4B"}>
                            Kategori Barang
                        </Typography>
                    </Grid>                            
                </Grid>
                
                <Grid container marginTop={1} columnSpacing={1}>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth
                            disabled={isKategoriBaru}
                        >
                            <Controller
                                control={control}
                                name="subKategori"
                                render={({
                                    field: { onChange, value },
                                }) => (
                                    <FormControl fullWidth>
                                        <InputLabel
                                            shrink={false}
                                            sx={{ color: "#A8B4AF" }}
                                        >
                                            {value === ""
                                                ? "Pilih Kategori"
                                                : ""}
                                        </InputLabel>
                                        <Select
                                            placeholder="Kategori"
                                            margin="dense"
                                            onChange={onChange}
                                            value={value || ""}
                                            error={Boolean(
                                                errors.subKategori,
                                            )}
                                            sx={{ borderRadius: 2 }}
                                        >
                                            {kategori &&
                                            kategori.totalElements >
                                                0 ? (
                                                kategori.content.map(
                                                    (k) => (
                                                        <MenuItem
                                                            key={k.id}
                                                            value={k.id}
                                                        >
                                                            {k.nama}
                                                        </MenuItem>
                                                    ),
                                                )
                                            ) : (
                                                <MenuItem value="">
                                                    Tidak ada kategori
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                )}
                            />
                            <FormHelperText
                                error={Boolean(errors.subKategori)}
                            >
                                {errors.subKategori
                                    ? errors.subKategori.message
                                    : " "}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="kategoriBundling"
                                render={({
                                    field: { onChange, value },
                                }) => {
                                    // Cari kategori yang dipilih
                                    const selectedKategori =
                                        kategori?.content.find(
                                            (k) =>
                                                k.id ===
                                                selectedKategoriId,
                                        );

                                    const kategoriTerhubung =
                                        selectedKategori?.kategoriTerhubung ||
                                        [];

                                    return (
                                        <FormControl fullWidth>
                                            <InputLabel
                                                shrink={false}
                                                sx={{
                                                    color: "#A8B4AF",
                                                }}
                                            >
                                                {value === ""
                                                    ? "Pilih Sub Kategori"
                                                    : ""}
                                            </InputLabel>
                                            <Select
                                                placeholder="Sub Kategori"
                                                margin="dense"
                                                onChange={onChange}
                                                value={value || ""}
                                                error={Boolean(
                                                    errors.kategoriBundling,
                                                )}
                                                sx={{ borderRadius: 2 }}
                                            >
                                                {kategoriTerhubung.length >
                                                0 ? (
                                                    kategoriTerhubung.map(
                                                        (kt) => (
                                                            <MenuItem
                                                                key={
                                                                    kt.id
                                                                }
                                                                value={
                                                                    kt.id
                                                                }
                                                            >
                                                                {
                                                                    kt.nama
                                                                }
                                                            </MenuItem>
                                                        ),
                                                    )
                                                ) : (
                                                    <MenuItem value="">
                                                        Tidak ada
                                                        sub-kategori
                                                        tersedia
                                                    </MenuItem>
                                                )}
                                            </Select>
                                        </FormControl>
                                    );
                                }}
                            />
                            <FormHelperText
                                error={Boolean(errors.kategoriBundling)}
                            >
                                {errors.kategoriBundling
                                    ? errors.kategoriBundling.message
                                    : " "}
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container mb={3}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        width="100%"
                    >
                        <div>
                            <Typography
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Favorit
                            </Typography>
                            <Typography
                                color={textPrimary.light}
                                fontSize={12}
                                fontWeight={300}
                            >
                                Aktifkan opsi jika ingin menampilkan barang di
                                urutan paling awal
                            </Typography>
                        </div>
                        <Controller
                            name="favorite"
                            control={control}
                            render={({ field }) => (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={field.value}
                                                onChange={field.onChange}
                                                sx={{
                                                    "& .MuiSwitch-thumb": {
                                                        color: "#eaeaea",
                                                    },
                                                }}
                                            />
                                        }
                                        label={
                                            field.value
                                                ? "Favorit Aktif"
                                                : "Favorit Nonaktif"
                                        }
                                        labelPlacement="start"
                                        componentsProps={{
                                            typography: {
                                                variant: "body2",
                                            },
                                        }}
                                    />
                                </FormGroup>
                            )}
                        />
                    </Stack>
                </Grid>
                {dataStok && (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        gap={1}
                        width="100%"
                    >
                        <div>
                            <Typography
                                color={textPrimary.body}
                                fontWeight={500}
                            >
                                Tampilkan
                            </Typography>
                            <Typography
                                color={textPrimary.light}
                                fontSize={12}
                                fontWeight={300}
                            >
                                Nonaktifkan jika tidak ingin menampilkan barang
                                pada transaksi.
                            </Typography>
                        </div>
                        <Grid item>
                            <Controller
                                name="isShow"
                                control={control}
                                render={({ field }) => (
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={field.value}
                                                    onChange={field.onChange}
                                                    sx={{
                                                        "& .MuiSwitch-thumb": {
                                                            color: "#eaeaea",
                                                        },
                                                    }}
                                                />
                                            }
                                            label={
                                                field.value
                                                    ? "Tampilkan"
                                                    : "Tidak Tampilkan"
                                            }
                                            labelPlacement="start"
                                            componentsProps={{
                                                typography: {
                                                    variant: "body2",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                )}
                            />
                        </Grid>
                    </Stack>
                )}
                <Box
                    mt={4}
                    display="grid"
                    gridTemplateColumns="1fr 1fr 1fr"
                    justifyContent="flex-end"
                    columnGap="16px"
                >
                    <div />
                    <Button onClick={onBack} variant="outlined">
                        Kembali
                    </Button>
                    <LoadingButton
                        loading={isButtonLoading}
                        loadingPosition="center"
                        variant="contained"
                        onClick={handleSubmit(onSubmit)}
                    >
                        Simpan
                    </LoadingButton>
                </Box>
            </form>
        </Box>
    );
};

export default DetailItemStep;
