import * as yup from "yup";

export const newCustomerSchema = yup.object({
    nama: yup
        .string()
        .required("Nama wajib diisi")
        .max(50, "Nama terlalu panjang"),
    noTelp: yup
        .string()
        .required("Nomor telepon wajib diisi")
        // .matches(
        //     /^08[1-9][0-9]+$/,
        //     "Nomor telepon tidak valid, contoh: 0831xxxx",
        // )
        // .min(9, "Nomor telepon terlalu pendek")
        // .max(13, "Nomor telepon terlalu panjang"),
        .matches(
            /^08[1-9][0-9]+$/,
            "Nomor telepon tidak valid, contoh: 0831xxxx",
        )
        .min(9, "Nomor telepon terlalu pendek")
        .max(13, "Nomor telepon terlalu panjang"),
    alamat: yup
        .string()
        .required("Alamat wajib diisi")
        .max(255, "Alamat terlalu panjang"),
});

export type NewCustomerSchema = yup.InferType<typeof newCustomerSchema>;
