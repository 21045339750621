import React, { useState } from "react";
import Box from "@mui/material/Box";
import TabelBarang from "../../components/TabelBarang/TabelBarang";
import TokoTidakAktif from "../../components/TokoTidakAktif/TokoTidakAktif";
import ModalEditKategori from "../../components/ModalEditKategori/ModalEditKategori";
import ModalDeleteKategori from "../../components/ModalDeleteKategori/ModalDeleteKategori";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import useUkm from "../../services/queries/useUkm";
import useUser from "../../services/queries/useUser";
import useTimeAlt from "../../services/queries/useTimeAlt";
import moment from "moment";
import "moment/locale/id";
import { Tab, Tabs } from "@mui/material";
import MutasiStok from "../../components/MutasiStok/MutasiStok";
import UploadStok from "../../components/UploadStok/UploadStok";
import AturKategori from "../../components/AturKategori/AturKategori";
import AturSubKategori from "../../components/AturSubKategori/AturSubKategori";
import BuatKatalog from "../../components/BuatKatalog/BuatKatalog";

moment.locales();
moment.locale("id");

interface IStokBarangProps {}

const StokBarang = (props: IStokBarangProps) => {
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: time } = useTimeAlt();
    const { data: user } = useUser();

    const [tab, setTab] = useState(0);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const isSudahJatuhTempo = moment(time?.dateTime).isAfter(
        ukmData?.pembayaranBerikutnya,
    );

    if (user?.aksesStok === 0 || user?.aksesStok === null) {
        return <TokoTidakAktif label="Anda tidak memiliki akses halaman ini" />;
    }

    if (
        ukmData &&
        (ukmData?.isActive === 0 ||
            ukmData?.isActive === null ||
            ukmData?.lewatTempoPro === 1 ||
            isSudahJatuhTempo)
    ) {
        return <TokoTidakAktif namaToko={ukmData.namaToko} />;
    }

    return (
        <Box>
            <Box>
                <Tabs
                    value={tab}
                    onChange={(_, newValue) => setTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab label="Daftar Stok" />
                    <Tab label="Mutasi Stok" />
                    <Tab label="Unggah Stok" />
                    <Tab label="Atur Kategori" />
                    <Tab label="Atur Sub Kategori" />
                    <Tab label="Buat Katalog" />
                </Tabs>
            </Box>
            <CustomTabPanel value={tab} index={0}>
                <TabelBarang />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
                <MutasiStok />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={2}>
                <UploadStok />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={3}>
                <AturKategori />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={4}>
                <AturSubKategori />
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={5}>
                <BuatKatalog />
            </CustomTabPanel>
            <ModalDeleteKategori />
            <ModalEditKategori />
        </Box>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel({ children, value, index, ...other }: TabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

export default StokBarang;
