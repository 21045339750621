import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Pagination from "@mui/material/Pagination";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { DataGrid, GridColDef, GridSortModel } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import StarIcon from "@mui/icons-material/Star";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import useUkm from "../../services/queries/useUkm";
import useItem from "../../services/queries/useItem";
import useUser from "../../services/queries/useUser";
import useKategori from "../../services/queries/useKategori";
import ModalPrintBarcode from "../../components/ModalPrintBarcode/ModalPrintBarcode";
import ModalStok from "../../components/ModalStok/ModalStok";
import ModalMutasiStok from "../../components/ModalMutasiStok/ModalMutasiStok";
import ModalDetailBarang from "../../components/ModalDetailBarang/ModalDetailBarang";
import toRupiah from "../../utils/toRupiah";
import avatarAlt from "../../utils/avatarAlt";
import { getLocalUkmId } from "../../utils/handleChangeUkmId";
import { useAuth } from "../../context/authContext";
import { useTransaksi } from "../../context/transaksiContext";
import { useStokModal } from "../../context/stokModalContext";
import { useImportModal } from "../../context/importModalContext";
import { useKatalogModal } from "../../context/katalogModalContext";
import { useKategoriModal } from "../../context/kategoriModalContext";
import { useHapusStokModal } from "../../context/hapusStokModalContext";
import { useHapusMultipleStokModal } from "../../context/hapusMultipleStokModalContext";
import { useMutasiStokModal } from "../../context/mutasiStokModalContext";
import { usePrintBarcodeModal } from "../../context/printBarcodeModal";
import {
    ContentGetItemResponse,
    ContentGetKategoriResponse,
    ParamsGetItem,
    ParamsGetKategori,
    SelectedProductBundling,
    SubCategoriesParams,
    SubCategory,
} from "../../constants/types";
import { ReactComponent as BarangTidakDitemukan } from "../../assets/images/BarangTidakDitemukan.svg";
import Spinner from "../Spinner/Spinner";
import NoRowsImage from "../NoRowsImage/NoRowsImage";
import LoadingTabel from "../LoadingTabel/LoadingTabel";
import CardStokBarang from "../CardStokBarang/CardStokBarang";
import ModalDeleteStok from "../ModalDeleteStok/ModalDeleteStok";
import ModalDeleteMultipleStok from "../ModalDeleteMultipleStok/ModalDeleteMultipleStok";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { ExpandMore, Search } from "@mui/icons-material";
import truncate from "../../utils/truncate";
import toRibuan from "../../utils/toRibuan";
import moment from "moment";
import "moment/locale/id";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete, Menu, Tooltip } from "@mui/material";
import { mapStockFieldSorting } from "../../utils/mapStockFieldSorting";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";

import axios from "../../services/axios";
import Swal from "sweetalert2";
import Collapse from "@mui/material/Collapse";
import ErrorFetchView from "../ErrorFetchView/ErrorFetchView";
import useSubKategori from "../../services/queries/useSubKategori";
import { textPrimary } from "../../constants/colors";

moment.locales();
moment.locale("id");

interface ITabelBarangProps {}

const TabelBarang = (props: ITabelBarangProps) => {
    const theme = useTheme();
    const { ukmIdUser } = useAuth();
    // const { openModalPrintBarcode } = usePrintBarcodeModal();

    const [isOpenModalPrintBarcode, setIsOpenModalPrintBarcode] =
        React.useState(false);

    const closeModalPrintBarcode = () => setIsOpenModalPrintBarcode(false);
    const openModalPrintBarcode = () => setIsOpenModalPrintBarcode(true);

    const [namaBarang, setNamaBarang] = React.useState("");
    const [idBarang, setIdBarang] = React.useState("");
    const [sku, setSku] = React.useState("");
    const [hargaBarang, setHargaBarang] = React.useState("");
    
    const isPhoneScreen = useMediaQuery(theme.breakpoints.between("xs", "sm"));
    const isTabletScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isLaptopScreen = useMediaQuery(theme.breakpoints.up("md"));
    const [addItemAnchor, setAddItemAnchor] = useState<HTMLElement | null>(
        null,
    );

    const openAddItem = Boolean(addItemAnchor);

    const initialParamsItem = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 10,
        page: 1,
        isShow: 0,
        search: "",
        kategori: "",
    };
    const [paramsItem, setParamsItem] =
        useState<ParamsGetItem>(initialParamsItem);
    const {
        data: dataItem,
        refetch: refetchItem,
        isLoading: isLoadingItem,
        isError: isErrorItem,
    } = useItem(paramsItem);

    const initialParamsSubKategori = {
        ukmId: ukmIdUser && ukmIdUser[0],
        size: 500,
        page: 1,
        search: "",
    };

    const initialParamsKategori = {
        ukmId: Number(ukmIdUser && ukmIdUser[0]),
        size: 500,
        page: 1,
        search: "",
    };
    const [paramsKategori, setParamsKategori] = useState<ParamsGetKategori>(
        initialParamsKategori,
    );
    const {
        status: statusKategori,
        isLoading: isLoadingKategori,
        data: dataKategori,
        refetch: refetchKategori,
        isError: isErrorKategori,
    } = useKategori(paramsKategori);
    const initialParamsUkm = {
        size: 500,
        page: 1,
        search: "",
    };
    const { data: ukm } = useUkm(initialParamsUkm);
    const { data: user } = useUser();
    const [kategori, setKategori] = useState<ContentGetKategoriResponse | null>(
        null,
    );

    const [paramsSubKategori, setParamsSubKategori] =
        useState<SubCategoriesParams>(initialParamsSubKategori);
    const { data: dataSubKategori, isLoading: isLoadingSubKategori } =
        useSubKategori(paramsSubKategori);
    const [subKategori, setSubKategori] = useState<SubCategory | null>(null);

    const ukmId = React.useMemo(() => {
        if (user?.userId) {
            return getLocalUkmId(user?.userId);
        }
        return null;
    }, [user?.userId]);

    const ukmData = React.useMemo(() => {
        if (ukmId) {
            return ukm?.content.find((li) => li.id === ukmId);
        }
        return ukm?.content[0];
    }, [ukm?.content, ukmId]);

    const { isFetchingItems, cancelFetchingItems } = useTransaksi();

    const { openModal, setSection } = useStokModal();
    const { openModalImport } = useImportModal();
    const { openModalKatalog } = useKatalogModal();
    const { openModalKategori } = useKategoriModal();
    const { openModalHapusStok } = useHapusStokModal();
    const { openModalHapusMultipleStok } = useHapusMultipleStokModal();
    const { openModalMutasiStok } = useMutasiStokModal();

    const [search, setSearch] = useState("");
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const handleSortModelChange = (
        column: ParamsGetItem["column"],
        sortBy: ParamsGetItem["sortby"],
    ) => {
        setParamsItem((prev) => ({
            ...prev,
            column,
            sortby: sortBy,
        }));
    };

    const [selectedProducts, setSelectedProducts] = useState<
        SelectedProductBundling[]
    >([]);

    const isSelected = (id: number) => {
        const filteredProduct = selectedProducts.filter(
            (li) => li.id === id,
        ).length;
        if (filteredProduct > 0) {
            return true;
        } else {
            return false;
        }
    };

    const handleSelectedProducts = (
        id: number,
        nama: string,
        harga: number,
        stok: number,
    ) => {
        if (isSelected(id)) {
            const newList = selectedProducts.filter((li) => li.id !== id);
            setSelectedProducts(newList);
        } else {
            setSelectedProducts((prev) => [
                ...prev,
                {
                    id,
                    nama,
                    harga,
                    stok,
                },
            ]);
        }
    };

    const handleClearSelectedProducts = () => {
        setSelectedProducts([]);
    };

    const handleDeleteMultipleProducts = async () => {
        try {
            const response = await axios.post("/api/item-delete", {
                itemId: selectedProducts.map((product) => product.id),
            });
            if (response.status === 200) {
                setSelectedProducts([]);
                Swal.fire({
                    title: "Item berhasil dihapus!",
                    position: "top-end",
                    showConfirmButton: false,
                    icon: "success",
                    toast: true,
                    timer: 3000,
                    timerProgressBar: true,
                    showCloseButton: true,
                    customClass: {
                        container: "my-swal",
                    },
                });
                refetchItem();
            } else {
                console.error("Failed to delete products");
            }
        } catch (error) {
            console.error("Error deleting products:", error);
        }
    };

    const columns: GridColDef[] = [
        {
            field: "",
            headerName: "",
            headerAlign: "center",
            align: "center",
            minWidth: 100,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <IconButton
                        // disabled={(params.row.stok <= 0 && params.row.stok !== null) || params.row.isBundling > 0}
                        onClick={() => {
                            handleSelectedProducts(
                                params.row.id,
                                params.row.namaBarang,
                                params.row.harga,
                                params.row.stok,
                            );
                        }}
                        sx={{
                            minWidth: "unset",
                            padding: "6px",
                            width: "fit-content",
                        }}
                        size="small"
                        color="primary"
                    >
                        {isSelected(params.row.id) ? (
                            <CheckBoxIcon />
                        ) : (
                            <CheckBoxOutlineBlankIcon />
                        )}
                    </IconButton>
                );
            },
        },
        {
            field: "namaBarang",
            headerName: "Nama Barang",
            flex: isLaptopScreen ? 1.5 : undefined,
            minWidth: 235,
            valueGetter: (params) => params.value,
            renderCell: (params) => {
                return (
                    <Stack
                        direction="row"
                        paddingLeft={1}
                        spacing={1}
                        marginTop={3}
                        sx={{ marginBottom: params.row.favorite > 0 ? -1 : 3 }}
                    >
                        <Box>
                            <Avatar
                                sx={{
                                    backgroundColor: "primary.main",
                                    width: 50,
                                    height: 50,
                                }}
                                alt={params.value}
                                src={params.row.gambar}
                            >
                                {avatarAlt(params.value)}
                            </Avatar>
                            {params.row.favorite > 0 && (
                                <StarIcon
                                    sx={{
                                        position: "relative",
                                        zIndex: 2,
                                        bottom: 60,
                                        left: "70%",
                                        border: 1,
                                        borderColor: "#e7e5e4",
                                        borderRadius: "50%",
                                        padding: 0.2,
                                        color: "#fbbf24",
                                        bgcolor: "#FFFFFF",
                                    }}
                                    fontSize="medium"
                                />
                            )}
                        </Box>
                        <Stack direction="column" spacing={0.3}>
                            <Box>
                                <Tooltip title={params.value}>
                                    <Typography
                                        component="div"
                                        color={textPrimary.body}
                                    >
                                        {truncate(params.value, 20)}
                                    </Typography>
                                </Tooltip>
                            </Box>
                            <Box>
                                <Tooltip title={params.row.deskripsi}>
                                    <Typography
                                        color={textPrimary.body}
                                        fontSize={12}
                                        fontWeight={300}
                                    >
                                        {params.row.deskripsi === null
                                            ? ""
                                            : truncate(
                                                  params.row.deskripsi,
                                                  50,
                                              )}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        </Stack>
                    </Stack>
                );
            },
        },
        {
            field: "sku",
            headerName: "SKU",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {params.value}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "subKategori",
            headerName: "Kategori",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            valueGetter: (params) => params.row.subKategori.nama,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.subKategori.nama}>
                        <span className="table-cell-trucate">
                            {params.row.subKategori.nama ?? "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "kategori",
            headerName: "Sub Kategori",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 150,
            valueGetter: (params) => params.row.kategori.nama,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.kategori.nama}>
                        <span className="table-cell-trucate">
                            {params.row.kategori.nama}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "terjual",
            headerName: "Terjual",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.value}>
                        <span className="table-cell-trucate">
                            {params.value ?? 0}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "stok",
            headerName: "Stok",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.row.isBundling > 0
                                ? "Otomatis"
                                : params.value === null
                                ? "Nonaktif"
                                : params.value
                        }
                    >
                        <Typography
                            variant="subtitle2"
                            color={
                                params.value < 5 && params.value !== null
                                    ? "#ef4444"
                                    : "#76747C"
                            }
                            fontWeight={
                                params.value < 5 && params.value !== null
                                    ? 700
                                    : "normal"
                            }
                        >
                            {params.row.isBundling > 0
                                ? "Otomatis"
                                : params.value === null
                                ? "Nonaktif"
                                : params.value}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "expAt",
            headerName: "Tanggal Kadaluarsa",
            minWidth: 180,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value
                                ? moment(params.value).format("LL")
                                : "-"
                        }
                    >
                        <Typography variant="body2">
                            {params.value
                                ? moment(params.value).format("LL")
                                : "-"}
                        </Typography>
                    </Tooltip>
                );
            },
        },
        {
            field: "hargaMultiple",
            headerName: "Harga",
            align: "right",
            flex: isLaptopScreen ? 1 : undefined,
            minWidth: 100,
            valueGetter: (params) =>
                params.value.length > 0
                    ? Number(params.value[0].harga)
                    : params.row.harga
                    ? Number(params.row.harga)
                    : 0,
            renderCell: (params) => {
                return (
                    <Tooltip
                        title={
                            params.value.length > 0
                                ? toRupiah(Number(params.value[0].harga))
                                : params.row.harga
                                ? toRupiah(params.row.harga)
                                : "-"
                        }
                    >
                        <span className="table-cell-trucate">
                            {params.value.length > 0
                                ? toRupiah(Number(params.value[0].harga))
                                : params.row.harga
                                ? toRupiah(params.row.harga)
                                : "-"}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "aksi",
            headerName: "Aksi",
            headerAlign: "center",
            align: "center",
            minWidth: 160,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const row: ContentGetItemResponse = params.row;

                return (
                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setSku(params.row.sku);
                                setNamaBarang(
                                    truncate(params.row.namaBarang, 24, true),
                                );
                                setHargaBarang(
                                    `Rp${toRibuan(params.row.harga)}`,
                                );
                                openModalPrintBarcode();
                            }}
                            size="small"
                            color="buttonblue"
                            disableElevation
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <PrintIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            disabled={user && !user.akses.stok.includes(25)}
                            variant="contained"
                            onClick={() =>
                                openModal({
                                    id: row.id,
                                    namaBarang: row.namaBarang,
                                    deskripsiBarang: row.deskripsi,
                                    subKategori: row.subKategori,
                                    kategoriId: row.kategori,
                                    harga: row.harga,
                                    sku: row.sku,
                                    jumlahStok: row.stok,
                                    isActiveStok: row.isActiveStok,
                                    itemSatuan: null,
                                    isBundling: row.isBundling,
                                    detailBundling:
                                        dataItem?.content?.find(
                                            (li) => li.id === row.id,
                                        )?.detailBundling ?? [],
                                    favorite: row.favorite === 1 ? true : false,
                                    isShow: row.isShow === 1 ? true : false,
                                    hargaMultiple: row.hargaMultiple.map(
                                        (li) => ({
                                            id: li.id,
                                            harga: Number(li.harga),
                                            keterangan: li.keterangan,
                                        }),
                                    ),
                                })
                            }
                            size="small"
                            color="buttonyellow"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <EditIcon sx={{ width: "16px", height: "16px" }} />
                        </Button>
                        <Button
                            disabled={user && !user.akses.stok.includes(26)}
                            variant="contained"
                            onClick={() =>
                                openModalHapusStok(
                                    params.id,
                                    params.row.namaBarang,
                                )
                            }
                            size="small"
                            color="buttonred"
                            sx={{
                                minWidth: "unset",
                                padding: "8px",
                                width: "32px",
                                height: "32px",
                            }}
                        >
                            <DeleteIcon
                                sx={{ width: "16px", height: "16px" }}
                            />
                        </Button>
                    </Stack>
                );
            },
        },
    ];

    const handleOpenModalKategori = () => {
        openModalKategori();
        refetchKategori();
    };

    const handleOnPagination = (page: number) => {
        setParamsItem((prev) => ({
            ...prev,
            page: page + 1,
        }));
    };

    const handleOnSizeChange = (size: number) => {
        setParamsItem((prev) => ({ ...prev, size }));
    };

    const handleSearch = () => {
        setParamsItem((prev) => ({
            ...prev,
            search: search,
        }));
    };

    useEffect(() => {
        const timeoutId = setTimeout(
            () =>
                setParamsItem((prev) => ({
                    ...prev,
                    search: search,
                })),
            1000,
        );
        return () => clearTimeout(timeoutId);
    }, [search]);

    useEffect(() => {
        refetchItem();
    }, [dataItem, refetchItem]);

    useEffect(() => {
        refetchKategori();
    }, [dataKategori, refetchKategori]);

    useEffect(() => {
        if (isFetchingItems === true) {
            refetchItem();
            refetchKategori();
            cancelFetchingItems();
        }
    }, [cancelFetchingItems, isFetchingItems, refetchItem, refetchKategori]);

    useEffect(() => {
        if (ukmIdUser) {
            setParamsItem((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
            setParamsKategori((prev) => ({
                ...prev,
                ukmId: ukmIdUser && ukmIdUser[0],
            }));
        }
    }, [ukmIdUser]);

    // const isLoading = statusKategori === "loading" || statusItem === "loading";
    const isError = isErrorItem || isErrorKategori;

    if (statusKategori === "loading") {
        return (
            <Box
                position="relative"
                height={isLaptopScreen ? "100vh" : "calc(100vh - 56px)"}
            >
                <Spinner />
            </Box>
        );
    }

    if (isError) {
        // return <ErrorFetchView />;

        Swal.fire({
            title: "Maaf, kami tidak dapat memproses permintaan anda",
            text: `Silahkan coba lagi dengan memuat ulang halaman`,
            position: "top-end",
            icon: "error",
            timer: 3000,
            timerProgressBar: true,
            confirmButtonColor: "#45A779",
            customClass: {
                container: "my-swal",
            },
        });
    }

    return (
        <React.Fragment>
            <Stack
                display="flex"
                justifyContent="space-between"
                direction={{ xs: "column", sm: "row", md: "row" }}
                marginTop={2}
            >
                <Typography fontSize={32} fontWeight={700}>
                    Daftar Stok
                </Typography>
                <Box
                    display={{ xs: "none", sm: "flex" }}
                    alignItems="center"
                    gap={2}
                    flexDirection="row"
                >
                    <Box width="max-content">
                        {ukmData ? (
                            dataItem ? (
                                !!ukmData.limitItem ? (
                                    dataItem.totalItem >= ukmData.limitItem ? (
                                        <Typography
                                            color="error"
                                            fontWeight="bold"
                                        >
                                            Stok Max
                                        </Typography>
                                    ) : dataItem.totalItem >=
                                      ukmData.limitItem - 10 ? (
                                        <Typography
                                            color="error"
                                            fontWeight="bold"
                                        >
                                            {`Jumlah barang: ${dataItem.totalItem}/${ukmData.limitItem}`}
                                        </Typography>
                                    ) : (
                                        <Typography color="GrayText">
                                            {`Jumlah barang: ${dataItem.totalItem}/${ukmData.limitItem}`}
                                        </Typography>
                                    )
                                ) : dataItem.totalItem >= 500 ? (
                                    <Typography color="error" fontWeight="bold">
                                        Stok Max
                                    </Typography>
                                ) : dataItem.totalItem >= 500 - 10 ? (
                                    <Typography color="error" fontWeight="bold">
                                        {`Jumlah barang: ${
                                            dataItem.totalItem
                                        }/${500}`}
                                    </Typography>
                                ) : (
                                    <Typography color="GrayText">
                                        {`Jumlah barang: ${
                                            dataItem.totalItem
                                        }/${500}`}
                                    </Typography>
                                )
                            ) : null
                        ) : null}
                    </Box>
                    <Button
                        id="add-item-button"
                        disabled={
                            ukmData &&
                            ((user && !user.akses.stok.includes(21)) ||
                                (ukmData.limitItem === null
                                    ? dataItem && dataItem?.totalItem >= 500
                                        ? true
                                        : false
                                    : dataItem &&
                                      dataItem?.totalItem >= ukmData.limitItem
                                    ? true
                                    : false))
                        }
                        variant="contained"
                        onClick={(e) => setAddItemAnchor(e.currentTarget)}
                        endIcon={<ExpandMore />}
                        size="medium"
                        sx={{
                            lineHeight: 2,
                        }}
                        aria-controls={
                            openAddItem ? "add-item-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openAddItem ? "true" : undefined}
                    >
                        Tambah Barang
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={addItemAnchor}
                        open={openAddItem}
                        onClose={() => setAddItemAnchor(null)}
                        MenuListProps={{
                            "aria-labelledby": "add-item-button",
                            sx: { width: "180px" },
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                setSection("single");
                                openModal();
                                setAddItemAnchor(null);
                            }}
                        >
                            Satuan
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setSection("bundle");
                                openModal();
                                setAddItemAnchor(null);
                            }}
                        >
                            Bundling/Paket
                        </MenuItem>
                    </Menu>
                </Box>
                {/* <Stack direction="row" spacing={{ xs: 0, sm: 1 }}>
                    <Button
                        disabled={user && !user.akses.stok.includes(22)}
                        variant="outlined"
                        onClick={() => openModalMutasiStok()}
                        startIcon={<MoveToInboxIcon />}
                        size="medium"
                        sx={{ backgroundColor: "#ffffff" }}
                    >
                        Mutasi Stok
                    </Button>
                    <Button
                        disabled={user && !user.akses.stok.includes(23)}
                        variant="outlined"
                        onClick={() => openModalKatalog()}
                        startIcon={<AssignmentReturnedIcon />}
                        size="medium"
                        sx={{ backgroundColor: "#ffffff" }}
                    >
                        Buat Katalog
                    </Button>
                    <Button
                        variant="outlined"
                        onClick={() => openModalImport()}
                        startIcon={<FileUploadIcon />}
                        size="medium"
                        sx={{ backgroundColor: "#ffffff" }}
                    >
                        Unggah Stok
                    </Button>
                    <Button
                        disabled={user && !user.akses.stok.includes(24)}
                        variant="outlined"
                        onClick={handleOpenModalKategori}
                        startIcon={<SettingsIcon />}
                        size="medium"
                        sx={{ backgroundColor: "#ffffff" }}
                    >
                        Atur Kategori
                    </Button>
                </Stack> */}
            </Stack>
            <Box alignItems="center" marginY={2}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={2}
                    alignItems="center"
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={4} md={3}>
                            <TextField
                                placeholder="Cari barang..."
                                size="small"
                                value={search}
                                onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    setSearch(event.target.value);
                                }}
                                onKeyPress={(e) => {
                                    const element =
                                        e.target as HTMLInputElement;
                                    if (e.key === "Enter") {
                                        setParamsItem((prev) => ({
                                            ...prev,
                                            search: element.value,
                                            page: 1,
                                        }));
                                    }
                                }}
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleSearch}>
                                                <Search />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        backgroundColor: "white",
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={9} sm={4} md={4} lg={4} xl={2}>
                            <FormControl size="small" fullWidth>
                                <Autocomplete
                                    size="small"
                                    options={dataKategori?.content ?? []}
                                    loading={isLoadingKategori}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Kategori"
                                            variant="outlined"
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        String(option.id) === String(value.id)
                                    }
                                    getOptionLabel={(option) => option.nama}
                                    fullWidth
                                    value={kategori}
                                    onChange={(_event, newKategori) => {
                                        setKategori(newKategori);
                                        setSubKategori(null);
                                        setParamsSubKategori((prev) => ({
                                            ...prev,
                                            subKategori: newKategori
                                                ? +newKategori.id
                                                : undefined,
                                        }));
                                        setParamsItem((prev) => ({
                                            ...prev,
                                            kategori: "",
                                        }));
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={9} sm={4} md={4} lg={4} xl={2}>
                            <FormControl size="small" fullWidth>
                                <Autocomplete
                                    disabled={!kategori}
                                    size="small"
                                    options={dataSubKategori?.content ?? []}
                                    loading={isLoadingSubKategori}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sub Kategori"
                                            variant="outlined"
                                        />
                                    )}
                                    isOptionEqualToValue={(option, value) =>
                                        String(option.id) === String(value.id)
                                    }
                                    getOptionLabel={(option) => option.nama}
                                    fullWidth
                                    value={subKategori}
                                    onChange={(_event, value) => {
                                        setSubKategori(value);
                                        setParamsItem((prev) => ({
                                            ...prev,
                                            kategori: value
                                                ? String(value.id)
                                                : "",
                                        }));
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Collapse in={selectedProducts.length > 0}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: 2,
                        backgroundColor: "#f0f0f0",
                        borderRadius: "4px",
                        marginTop: 2,
                        marginBottom: 1,
                        justifyContent: "space-between",
                    }}
                >
                    <Stack direction="row" alignItems="center">
                        <IconButton
                            onClick={handleClearSelectedProducts}
                            size="small"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ marginLeft: 1 }}>
                            {selectedProducts.length} terpilih
                        </Typography>
                    </Stack>

                    <Stack>
                        <Button
                            variant="contained"
                            sx={{
                                marginLeft: 2,
                                backgroundColor: "#FF7373",
                                "&:hover": {
                                    backgroundColor: "#FF7373",
                                },
                            }}
                            onClick={() =>
                                openModalHapusMultipleStok(selectedProducts)
                            }
                        >
                            Hapus Terpilih
                        </Button>
                    </Stack>
                </Box>
            </Collapse>

            {isPhoneScreen || isTabletScreen ? (
                dataItem && dataItem.totalElements > 0 ? (
                    <React.Fragment>
                        {dataItem.content.map((items) => (
                            <CardStokBarang
                                key={items.id}
                                id={items.id}
                                sku={items?.sku}
                                namaBarang={items.namaBarang}
                                jumlahStok={items.stok}
                                deskripsi={items.deskripsi}
                                harga={items.harga}
                                url={items.gambar}
                                kategori={items.kategori.nama}
                                kategoriId={items.kategori}
                                isActiveStok={items.isActiveStok}
                                itemSatuan={items.itemSatuan}
                                isBundling={items.isBundling}
                                detailBundling={items.detailBundling}
                                favorite={items.favorite === 1 ? true : false}
                                hargaMultiple={items.hargaMultiple.map(
                                    (li) => ({
                                        id: li.id,
                                        harga: Number(li.harga),
                                        keterangan: li.keterangan,
                                    }),
                                )}
                                handleSelectedProducts={handleSelectedProducts}
                                isSelected={isSelected}
                            />
                        ))}
                        <Box
                            display="flex"
                            justifyContent="center"
                            paddingY={2}
                        >
                            <Pagination
                                color="primary"
                                count={Math.ceil(
                                    Number(
                                        dataItem &&
                                            dataItem?.totalElements /
                                                initialParamsItem.size,
                                    ),
                                )}
                                page={paramsItem.page}
                                onChange={(event, value) => {
                                    setParamsItem((prev) => ({
                                        ...prev,
                                        page: value,
                                    }));
                                }}
                                siblingCount={0}
                            />
                        </Box>
                    </React.Fragment>
                ) : (
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        paddingTop={2}
                    >
                        <BarangTidakDitemukan width="150px" height="150px" />
                        <Typography fontWeight="bold">
                            Barang tidak ditemukan
                        </Typography>
                    </Stack>
                )
            ) : (
                <Box
                    sx={{
                        height: 820,
                        width: "100%",
                        marginBottom: 3,
                        backgroundColor: "#ffffff",
                        borderRadius: 1,
                        "& .MuiDataGrid-columnHeaderTitle": {
                            fontWeight: "bold",
                        },
                        "& .MuiDataGrid-cell:focus-within": {
                            outline: "none !important",
                        },
                        "& .MuiDataGrid-iconButtonContainer": {
                            visibility: "visible",
                            width: "0 !important",
                        },
                    }}
                >
                    <DataGrid
                        pagination
                        components={{
                            ColumnUnsortedIcon: () => {
                                return <ImportExportIcon />;
                            },
                            NoRowsOverlay: NoRowsImage,
                            LoadingOverlay: LoadingTabel,
                        }}
                        rows={
                            dataItem?.content.map((item, index) => ({
                                ...item,
                                idx: index + 1,
                            })) ?? []
                        }
                        columns={columns}
                        disableColumnMenu
                        disableSelectionOnClick
                        paginationMode="server"
                        rowsPerPageOptions={[10]}
                        onPageSizeChange={handleOnSizeChange}
                        onPageChange={handleOnPagination}
                        rowCount={dataItem?.totalElements ?? 0}
                        page={paramsItem.page - 1}
                        pageSize={paramsItem.size}
                        loading={isLoadingItem}
                        density="comfortable"
                        sortModel={sortModel}
                        onSortModelChange={(newModel) => {
                            setSortModel(newModel);
                            handleSortModelChange(
                                mapStockFieldSorting(newModel[0]?.field ?? ""),
                                newModel[0]?.sort,
                            );
                        }}
                    />
                </Box>
            )}
            <ModalStok
                refetchItem={refetchItem}
                refetchKategori={refetchKategori}
            />
            <ModalDeleteStok refetch={refetchItem} />
            <ModalDeleteMultipleStok
                refetch={refetchItem}
                handleCollapseClose={handleClearSelectedProducts}
            />
            <ModalDetailBarang />
            <ModalMutasiStok />
            <ModalPrintBarcode
                isOpenModalPrintBarcode={isOpenModalPrintBarcode}
                sku={sku}
                closeModalPrintBarcode={closeModalPrintBarcode}
                namaBarang={namaBarang}
                hargaBarang={hargaBarang}
            />
        </React.Fragment>
    );
};

export default TabelBarang;
